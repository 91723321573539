let token = JSON.parse(localStorage.getItem('token'));
let user = JSON.parse(localStorage.getItem('user'));
const initialState = token && user ? { loggedIn: true, token, user, errors:'' } : {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        loggedIn: true,
        token: action.token,
        user: action.user,
      };  
    case 'UPDATE_USER':
      return {
        loggedIn: true,
        token: action.token,
        user: action.user,
      };         
    case 'REGISTER':
      return { 
        loggedIn: true,
        token: action.token,
        user: action.user,
      };      
    case 'ERROR':
      return {
        loggedIn: false,
        errors: action.errors,
        token: {},
        user: {}
      };   
    default: 
      return state;
  };
};