import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiPlus } from "react-icons/fi";
import Layout from 'components/Layout';
import MultiGroupList  from 'components/Groups/MultiGroupList';
import GroupEdit from "components/Groups/GroupEditModal";
import Loading from "components/Loading";
import Modal  from 'components/Modal';
import { showModal, hideModal } from "actions/modalActions";
import * as groupsActions from 'actions/groupsActions';
import "components/Groups/Groups.scss";

class MultiGroups extends Component {
  constructor(props) {
    super(props);
    //console.log(props);

   this.closeModal = this.closeModal.bind(this);
   this.openNewGroupModal = this.openNewGroupModal.bind(this);
  }

  componentDidMount() {
  	this.getMultiGroups();

}    

   async getMultiGroups() {
  		// await this.props.getAllGroups(this.state.searchGroup);
  		await this.props.getAllGroups({variant:'detailed'});
  		//console.log(this.props);
  	}

	closeModal() {
		this.props.hideModal()
	}

	openNewGroupModal() {
	 this.props.showModal({
	   open: true,
	   title: 'Nuevo grupo',
	   style: {width: '400px'},
	   content: <GroupEdit handleClose={this.closeModal}  />,
	   closeModal: this.closeModal
	 }, 'edit')
	}

  getContent = () => {
    // console.log(this.state);
    if (this.props.loading){
      return <Loading />;
    }

    if (this.props.error){
      return "No se han podido cargar los grupos";
    }
    return(<MultiGroupList />);
  }  

  render() {

    return(

      <>
        <Layout className="page-groups" context="groups">
            <div className="heading">
              <h1 className="title">Multigrupos</h1>
              <button type="button" title="Crear grupo" onClick={this.openNewGroupModal} className="btn btn-new"><FiPlus /></button>
            </div>
    		{ this.getContent() }
        </Layout>

	    <Modal hideModal={this.props.hideModal} />
      </>
    )
  }
}

const mapStateToProps = (reducers) => {
  return {
    ...reducers.groupsReducer,
    loading: reducers.groupsReducer.loading
  }
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAllGroups: (options) => dispatch(groupsActions.getAllGroups(options)),
})


export default connect(mapStateToProps, mapDispatchToProps)(MultiGroups)