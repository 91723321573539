import { handleResponse } from "utils/utils";
import { authService } from "services";

export const accountsService = {
    getAll,
    getSelectable,
    getAccountTypes,
    getById,
    add,
    update,
    remove,
    getAccountAssignments,
    getWorkedHours,
    exportList,
};

// get accounts
function getAll(q) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name';

    if (q !== undefined)
        query += "&q=" + q;

    return fetch(`${global.BASE_API}accounts${query}`, requestOptions).then(handleResponse);
}

// get selectable accounts
function getSelectable() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name&variant=select&enabled=true';


    return fetch(`${global.BASE_API}accounts${query}`, requestOptions).then(handleResponse);
}

// // get account types
function getAccountTypes() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}accounts/types`, requestOptions).then(handleResponse);
}


// get account by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}accounts/${id}`, requestOptions).then(handleResponse);
}

// add account 
function add(account) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(account)
    };

    return fetch(`${global.BASE_API}accounts/`, requestOptions).then(handleResponse);
}

// update account
function update(account) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(account)
    };

    return fetch(`${global.BASE_API}accounts/${account.id}`, requestOptions).then(handleResponse);
}

// remove account
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}accounts/${id}`, requestOptions).then(handleResponse);
}


// gets account assignments
function getAccountAssignments(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    const { group_id, employee_id, start, end, plan_view_id, function_id, q, plan_view_system, my_calendar } = options;

    let query = "?start=" + start + "&end=" + end;

    if (group_id !== undefined && group_id > 0)
        query += "&group_id=" + group_id;

    if (employee_id !== undefined && employee_id > 0)
        query += "&employee_id=" + employee_id;

    if (plan_view_id !== undefined && plan_view_id > 0)
        query += "&plan_view_id=" + plan_view_id;

    if (function_id !== undefined && function_id > 0)
        query += "&function_id=" + function_id;

    if (q !== undefined && q !== "")
        query += "&q=" + q.replace(" ", "%20");

    if (plan_view_system !== undefined)
        query += "&plan_view_system=" + plan_view_system;

    if (my_calendar !== undefined)
        query += "&my_calendar=" + my_calendar;


    return fetch(`${global.BASE_API}accounts/assignments${query}`, requestOptions).then(handleResponse);
}

// gets account worked hours by shift
function getWorkedHours(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    const { employee_id, start, end } = options;

    let query = "?start=" + start + "&end=" + end;


    if (employee_id !== undefined && employee_id > 0)
        query += "&employee_id=" + employee_id;



    return fetch(`${global.BASE_API}accounts/worked-hours${query}`, requestOptions).then(handleResponse);
}


function exportList(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
        },
    };

    const { start, end, group_id, employee_id, plan_view_id, function_id } = options;

    let query = "?start=" + start + "&end=" + end;

    if (group_id !== undefined && group_id > 0)
        query += "&group_id=" + group_id;

    if (employee_id !== undefined && employee_id > 0)
        query += "&employee_id=" + employee_id;

    if (plan_view_id !== undefined && plan_view_id > 0)
        query += "&plan_view_id=" + plan_view_id;

    if (function_id !== undefined && function_id > 0)
        query += "&function_id=" + function_id;

    return fetch(`${global.BASE_API}accounts/assignments/export${query}`, requestOptions).then(handleResponse);
}