import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import { groupsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import "./Groups.scss";
import * as groupsActions from 'actions/groupsActions';

import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

class GroupStaffingLevelsEdit extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props);

    let group_staffing_level = {
      staffing_level_id: 0,
      start_day: '',
    }

    let isEdit = false;

    if (props.group_staffing_level) {
      group_staffing_level = {
        ...props.group_staffing_level,
        staffing_level_id: props.group_staffing_level.staffing_level.id
      };
      isEdit = true;
    }

    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      group_staffing_level
    };
  }

  handleChange = event => {
    const { group_staffing_level } = this.state;

    this.setState({
      group_staffing_level: {
        ...group_staffing_level,
        [event.target.id]: event.target.value
      },
    });
  }

  handleDateChange = (id, date) => {
    const { group_staffing_level } = this.state;

    let key = id;
    let value = moment(date).format("YYYY-MM-DD");

    this.setState({
      group_staffing_level: {
        ...group_staffing_level,
        [key]: value,
      },
    });
  };

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let response = '';

    const props = this.props;
    const { group_staffing_level } = this.state;

    // if edit => update; else => new    
    if (this.state.isEdit) {
      response = await groupsService.updateStaffingLevel(props.group.id, group_staffing_level);
    }
    else {
      response = await groupsService.addStaffingLevel(props.group.id, group_staffing_level);
    }

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        this.props.getGroupStaffingLevels(props.group.id);
        props.handleClose();

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  render() {
    const { select_staffing_levels } = this.props;
    const { group_staffing_level } = this.state;

    const optionList = select_staffing_levels.length > 0 && select_staffing_levels.map((item, i) => {
      return (
        <option key={i} value={item.id}>{item.name}</option>
      )
    }, this);

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <FormGroup controlId="staffing_level_id">
            <FormLabel>Necesidades de personal</FormLabel>
            <FormControl
              onChange={this.handleChange}
              value={group_staffing_level.staffing_level_id}
              isInvalid={this.checkValidField('staffing_level_id')}
              as="select">
              <option value="-1">Seleccionar necesidades</option>
              {optionList}
            </FormControl>

            <FormControl.Feedback type="invalid">
              {this.state.errors.staffing_level_id}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup controlId="start_day" className={`form-date ${group_staffing_level.start_day === '' && 'empty'}  ${this.checkValidField('start_day') && "is-invalid"}`}>
            <FormLabel>Fecha inicio</FormLabel>

            <DatePicker
              selected={group_staffing_level.start_day === "" ? group_staffing_level.start_day : moment(group_staffing_level.start_day).toDate()}
              onChange={(date) => this.handleDateChange("start_day", date)}
              isInvalid={this.checkValidField("start_day")}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              locale='es'
              placeholderText="dd/mm/aaaa"
            />

            <FiCalendar />
            <FormControl.Feedback type="invalid">
              {this.state.errors.start_day}
            </FormControl.Feedback>
          </FormGroup>

        </div>

        <div className="modal-footer">
          <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
          <SubmitButton
            type="submit"
            isLoading={this.state.isLoading}
            text="Guardar"
            loadingText="Guardando..."
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = (reducers) => {
  return {
    ...reducers.groupsReducer,
  }
};

export default connect(mapStateToProps, groupsActions)(GroupStaffingLevelsEdit);