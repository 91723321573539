import { employeesService, dashboardsService, workflowsService, groupsService } from "services";

export const getAllEmployees = (options) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const list = await employeesService.getAll(options);

		dispatch({
			type: "GET_EMPLOYEES",
			payload: list.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getSelectableEmployees = (options) => async (dispatch) => {
	try {
		const list = await employeesService.getSelectable(options);

		dispatch({
			type: "GET_SELECT_EMPLOYEES",
			payload: list.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getEmployee = (id) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const user = await employeesService.getById(id);
		// console.log(user);
		dispatch({
			type: "GET_EMPLOYEE",
			payload: user,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getEmployeeGroups = (employeeId) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const list = await employeesService.getGroups(employeeId);

		dispatch({
			type: "GET_EMPLOYEE_GROUPS",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getEmployeeFunctions = (employeeId) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const list = await employeesService.getFunctions(employeeId);

		dispatch({
			type: "GET_EMPLOYEE_FUNCTIONS",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getEmployeeUbications = (employeeId) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const list = await employeesService.getUbications(employeeId);

		dispatch({
			type: "GET_EMPLOYEE_UBICATIONS",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getEmployeeShiftPatterns = (employeeId) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const list = await employeesService.getShiftPatterns(employeeId);

		dispatch({
			type: "GET_EMPLOYEE_SHIFT_PATTERNS",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getEmployeeContracts = (employeeId) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const list = await employeesService.getContracts(employeeId);

		dispatch({
			type: "GET_EMPLOYEE_CONTRACTS",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getEmployeeDocuments = (employeeId) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const employee = await employeesService.getById(employeeId);

		dispatch({
			type: "GET_EMPLOYEE_DOCUMENTS",
			payload: employee.attachments || [],
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};

export const getEmployeeAbsences = (employeeId) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		// const list_ = await employeesService.getAbsences(employeeId);
		// console.log(list_);
		const list = await workflowsService.getAllAbsences({ employee_id: employeeId });

		dispatch({
			type: "GET_EMPLOYEE_ABSENCES",
			payload: list.data,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}
};


export const getEmployeeAbsenceCalendar = (employeeId, year) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const list = await dashboardsService.getEmployeeAbsenceCalendar(employeeId, year);
		// console.log(list);

		dispatch({
			type: "GET_EMPLOYEE_ABSENCE_CALENDAR",
			payload: list.days,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}

};

export const getEmployeeSeparatedRestEmployees = (employeeId) => async (dispatch) => {
	dispatch({ type: "LOADING" });

	try {
		const list = await employeesService.getSeparatedRestsEmployees(employeeId);
		// console.log(list);

		dispatch({
			type: "GET_EMPLOYEE_SEPARATED_REST",
			payload: list,
		});
	} catch (error) {
		console.log("Error: " + error.message);
		dispatch({
			type: "ERROR",
			payload: error.message,
		});
	}

};

