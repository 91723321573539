import React from "react";
import { connect } from "react-redux";
import { FiArrowLeft } from "react-icons/fi";
import { Nav, Tab } from "react-bootstrap";
import Layout from "components/Layout";
import Loading from "components/Loading";
import GroupInfo from "components/Groups/GroupInfo";
import GroupEmployees from 'components/Groups/GroupEmployees';
import GroupSupervisors from "components/Groups/GroupSupervisors";
import GroupStaffingLevels from "components/Groups/GroupStaffingLevels";
import { groupsService, employeesService } from "services";
import { showModal, hideModal } from "actions/modalActions";
import * as groupsActions from "actions/groupsActions";
import * as calendarsActions from "actions/calendarsActions";
import * as employeesActions from "actions/employeesActions";
import Modal from "components/Modal";
import "components/Groups/Groups.scss";

class Group extends React.Component {
	constructor(props) {
		super(props);

		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);

		this.state = {
			loading: true,
			group: {},
			errors: {},
			error_footer: "",
		};
	}

	closeModal() {
		this.props.hideModal();
	}

	openAlertModal(message, title) {
		this.props.showModal(
			{
				open: true,
				title: title || "Error",
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	async componentDidMount() {
		const groupId = this.props.match.params.id;

		const group_employees_response = await employeesService.getAll({ group_id: groupId, variant: "select" });
		const group_employees = group_employees_response.ok ? group_employees_response.data : [];
		// console.log(group_employees_response);

		const group_from_list = await groupsService.getAll({
			id: groupId,
			variant: "detailed",
		});
		// console.log(group_from_list);
		if (group_from_list.ok) {
			this.props.getSelectableCalendars();
			this.props.getSelectableEmployees();
			this.props.getGroupStaffingLevels(groupId);
			this.props.getSelectableStaffingLevels();

			this.setState({
				group: group_from_list.data[0],
				group_employees,
				loading: false,
			});
		} else {
			this.setState({
				loading: false,
			});
		}
	}

	handleBackClick = () => {
		this.props.history.goBack();
	};

	getContent = () => {
		if (this.state.loading) {
			return <Loading />;
		}

		if (!this.state.group.id) {
			return "Error cargando el grupo";
		}

		const { group, group_employees } = this.state;

		// console.log(group);

		return (
			<article className="card" key={group.id}>
				<div className="card-header">
					<h2 className="card-title">{group.name}</h2>
				</div>

				<Tab.Container defaultActiveKey="info">
					<Nav variant="tabs">
						<Nav.Item>
							<Nav.Link eventKey="info">Información general</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="empleados">Empleados</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="supervisores">Supervisores</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="necesidades">
								Necesidades de personal
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<Tab.Content>
						<Tab.Pane eventKey="info">
							<GroupInfo group={group} />
						</Tab.Pane>
						<Tab.Pane eventKey="empleados">
							<GroupEmployees employees={group_employees} />
						</Tab.Pane>
						<Tab.Pane eventKey="supervisores">
							<GroupSupervisors group={group} />
						</Tab.Pane>
						<Tab.Pane eventKey="necesidades">
							<GroupStaffingLevels group={group} />
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</article>
		);
	};

	render() {
		return (
			<>
				<Layout className="page-group">
					<div className="heading">
						<h1 className="title">
							<FiArrowLeft
								className="button-icon"
								onClick={this.handleBackClick}
							/>{" "}
							Grupos
						</h1>
					</div>

					{this.getContent()}
				</Layout>
				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.calendarsReducer,
		...reducers.groupsReducer,
		loading: reducers.groupsReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getAllGroups: () => dispatch(groupsActions.getAllGroups()),
	getSelectableCalendars: () =>
		dispatch(calendarsActions.getSelectableCalendars()),
	getGroupStaffingLevels: (groupId) =>
		dispatch(groupsActions.getGroupStaffingLevels(groupId)),
	getSelectableStaffingLevels: () =>
		dispatch(groupsActions.getSelectableStaffingLevels()),
	getSelectableEmployees: (options) =>
		dispatch(employeesActions.getSelectableEmployees(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Group);

//<GroupEmployees group={group} />
