import { handleResponse } from "utils/utils";
import { authService } from "services";

export const ubicationsService = {
	getAll,
	getSelectable,
	getById,
	add,
	update,
	remove,
};

// get all ubications
function getAll(options) {

	const { q, enabled } = options;

	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`
		},
	};

	let query = '?sort=+name';

	if (q !== undefined)
		query += "&q=" + q;

	if (enabled)
		query += "&enabled=" + enabled;

	return fetch(`${global.BASE_API}ubications${query}`, requestOptions).then(handleResponse);
}

// gets selectable ubications
function getSelectable(options) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`
		},
	};

	let query = '?sort=+name&variant=select&enabled=true';

	if (options) {
		const { q } = options;

		if (q !== undefined)
			query += "&q=" + q;
	}

	return fetch(`${global.BASE_API}ubications${query}`, requestOptions).then(handleResponse);
}

// get one ubication by id
function getById(id) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`
		},
	};

	return fetch(`${global.BASE_API}ubications/${id}`, requestOptions).then(handleResponse);
}

// add new ubication
function add(func) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(func)
	};

	return fetch(`${global.BASE_API}ubications/`, requestOptions).then(handleResponse);
}

// update ubication
function update(func) {
	const requestOptions = {
		method: 'PUT',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(func)
	};

	return fetch(`${global.BASE_API}ubications/${func.id}`, requestOptions).then(handleResponse);
}

// remove ubication
function remove(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`
		},
	};

	return fetch(`${global.BASE_API}ubications/${id}`, requestOptions).then(handleResponse);
}