import { handleResponse } from "utils/utils";
import { authService } from "services";

export const staffingLevelsService = {
    getAll,
    getSelectable,
    getById,
    add,
    update,
    remove,
    getStaffingLevelAssignments,
    getStaffingLevelCurrentAssignments,
    getStaffingLevelHourlyAssignments,
    updateAssignment
};

// gets all staffing-levels
function getAll(q) {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = "?sort=+name";

    if (q !== undefined)
        query += "&q=" + q;

    return fetch(`${global.BASE_API}staffing-levels${query}`, requestOptions).then(handleResponse);
}

function getSelectable() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    let query = '?sort=+name&variant=select&enabled=true';

    return fetch(`${global.BASE_API}staffing-levels${query}`, requestOptions).then(handleResponse);
}

// get one staffing-level by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}staffing-levels/${id}`, requestOptions).then(handleResponse);
}

// add new staffing-level
function add(staffing_level) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(staffing_level)
    };

    return fetch(`${global.BASE_API}staffing-levels/`, requestOptions).then(handleResponse);
}

// update staffing-level
function update(staffing_level) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(staffing_level)
    };

    return fetch(`${global.BASE_API}staffing-levels/${staffing_level.id}`, requestOptions).then(handleResponse);
}

// remove staffing-level
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}staffing-levels/${id}`, requestOptions).then(handleResponse);
}


// gets staffing level assignments
function getStaffingLevelAssignments(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    const { group_id, employee_id, start, end, function_id, ubication_id } = options;


    let query = "?start=" + start + "&end=" + end;

    if (group_id !== undefined && group_id > 0)
        query += "&group_id=" + group_id;

    if (employee_id !== undefined && employee_id > 0)
        query += "&employee_id=" + employee_id;

    //console.log(plan_view_id);

    if (function_id !== undefined && function_id > 0)
        query += "&function_id=" + function_id;

    if (ubication_id !== undefined && ubication_id > 0)
        query += "&ubication_id=" + ubication_id;

    return fetch(`${global.BASE_API}staffing-levels/assignments${query}`, requestOptions).then(handleResponse);
}

// gets staffing level CURRENT assignments (cuando staffing level assignments no devuelve nada)
function getStaffingLevelCurrentAssignments(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    const { group_id, employee_id, start, end, function_id, ubication_id } = options;


    let query = "?start=" + start + "&end=" + end;

    if (group_id !== undefined && group_id > 0)
        query += "&group_id=" + group_id;

    if (employee_id !== undefined && employee_id > 0)
        query += "&employee_id=" + employee_id;

    //console.log(plan_view_id);

    if (function_id !== undefined && function_id > 0)
        query += "&function_id=" + function_id;

    if (ubication_id !== undefined && ubication_id > 0)
        query += "&ubication_id=" + ubication_id;

    return fetch(`${global.BASE_API}staffing-levels/current-assignments${query}`, requestOptions).then(handleResponse);
}


// gets staffing level assignments HOURLY
function getStaffingLevelHourlyAssignments(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    const { group_id, start, end, start_hour, end_hour } = options;

    let query = "?start=" + start + "&end=" + end;

    if (group_id !== undefined && group_id > 0)
        query += "&group_id=" + group_id;

    if (start_hour !== undefined && start_hour !== "")
        query += "&start_hour=" + start_hour;

    if (end_hour !== undefined && end_hour !== "")
        query += "&end_hour=" + end_hour;


    return fetch(`${global.BASE_API}staffing-levels/assignments/fractioned${query}`, requestOptions).then(handleResponse);
}


// PUT update staffing level assignments
function updateAssignment(overwrite) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(overwrite),
    };

    return fetch(`${global.BASE_API}staffing-levels/overwrites`, requestOptions).then(
        handleResponse
    );
}