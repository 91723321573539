import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ubicationsService } from "services";
import { FiPlus, FiMoreHorizontal, FiSearch } from "react-icons/fi";
import { FormGroup, FormControl, FormLabel, Dropdown } from "react-bootstrap";
import Layout from 'components/Layout';
import Loading from "components/Loading";
import Switch from "components/Switch";
import UbicationEdit from "components/Ubications/UbicationEditModal";
import Modal from 'components/Modal';
import { showModal, hideModal } from "actions/modalActions";
import * as ubicationsActions from 'actions/ubicationsActions';
import { errorFormating } from "utils/utils";

class Ubications extends Component {
	constructor(props) {
		super(props);
		//console.log(props);

		this.closeModal = this.closeModal.bind(this);
		this.openNewUbicationModal = this.openNewUbicationModal.bind(this);
		this.openEditUbicationModal = this.openEditUbicationModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.getUbications = this.getUbications.bind(this);

		this.state = {
			active_only: true,
			search_ubication: "",
		};
	}

	componentDidMount() {
		this.getUbications();
	}

	async getUbications() {
		await this.props.getAllUbications({ q: this.state.search_ubication, enabled: this.state.active_only });
		// console.log(this.props);
	}

	closeModal() {
		this.props.hideModal()
	}

	openNewUbicationModal() {
		this.props.showModal({
			open: true,
			title: 'Nueva ubicación',
			style: { width: '400px' },
			content: <UbicationEdit getUbications={this.getUbications} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openEditUbicationModal(ubication) {
		this.props.showModal({
			open: true,
			title: 'Editar ubicación',
			style: { width: '400px' },
			content: <UbicationEdit getUbications={this.getUbications} ubication={ubication} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteUbicationModal(idUbication) {
		this.props.showModal({
			open: true,
			title: 'Eliminar ubicación',
			style: { width: '400px' },
			message: '¿Desea eliminar la ubicación? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idUbication),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
			open: true,
			title: "Error al eliminar",
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (idUbication) => {
		const response = await ubicationsService.remove(idUbication);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else {
			this.getUbications();
			this.closeModal();
		}
	}

	handleChangeSearch = event => {
		const targetId = event.target.id;

		this.setState({
			[targetId]: targetId === "active_only" ? event.target.checked : event.target.value
		}, () => {
			this.getUbications()
		});
	}


	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="4" className="no-result">
					Todavía no hay ninguna ubicación creada.
				</td>
			</tr>
		);
	}

	getContent = () => {
		// console.log(this.state);
		if (this.props.loading) {
			return <Loading />;
		}

		if (this.props.error) {
			return "No se han podido cargar las ubicaciones";
		}
		return (
			<table className="table table-ubications table-zebra">
				<thead>
					<tr>
						<th>Nombre</th>
						<th>Descripción</th>
						<th>Estado</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{this.props.ubications.length === 0 ?
						this.getEmptyRow()
						:
						this.getRows()
					}
				</tbody>
			</table>
		);
	}

	getRows = () => this.props.ubications.map((ubication) => {

		return (
			<tr key={ubication.id}>
				<td>
					<button className="btn-transparent btn-editable" onClick={() => this.openEditUbicationModal(ubication)}>{ubication.name}</button>
				</td>
				<td>
					{ubication.description}
				</td>
				<td>
					{ubication.enabled
						? <span title='Activo' className='estado estado-si'>Activo</span>
						: <span title='Inactivo' className='estado estado-no'>Inactivo</span>
					}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openEditUbicationModal(ubication)}>Editar</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openDeleteUbicationModal(ubication.id)}>Eliminar</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	render() {
		const { active_only } = this.state;

		return (

			<>
				<Layout className="page-ubications" context="employees">
					<div className="heading">
						<h1 className="title">Ubicaciones de empleado</h1>


						<div className="heading-filters">
							<FormGroup
								className="form-group-flex form-switch"
								controlId="active_only"
							>
								<Switch
									controlId="active_only"
									isOn={active_only}
									handleToggle={this.handleChangeSearch}
								/>
								<FormLabel>Mostrar activos</FormLabel>
							</FormGroup>


							<FormGroup className="form-search" controlId="search_ubication">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.search_ubication}
									onChange={this.handleChangeSearch}
								/>
								<FiSearch />
							</FormGroup>


						</div>

						<div className="heading-actions">
							<button type="button" title="Crear ubicación" onClick={this.openNewUbicationModal} className="btn btn-new"><FiPlus /></button>
						</div>
					</div>

					{this.getContent()}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		)
	}
}

const mapStateToProps = (reducers) => {
	return reducers.ubicationsReducer;
};

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getAllUbications: (options) => dispatch(ubicationsActions.getAllUbications(options))
})


export default connect(mapStateToProps, mapDispatchToProps)(Ubications)
// export default connect(null, mapDispatchToProps)(Ubications)