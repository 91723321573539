import { authService } from "services";


export const login = (username, password) => async (dispatch) => {
	dispatch({
		type: 'LOADING'
	});

	let response = await authService.login(username, password);
	// console.log(response);

	if (response.ok){
		dispatch({ 
			type: 'LOGIN_SUCCESS', 
			token: response.token,
			user: response.user
		});
	}
	else{
		dispatch({ 
			type: 'ERROR', 
			errors: response.errors
		}); 
	}    
}

export const register = (user) => async dispatch => {
	dispatch({
		type: 'LOADING'
	});

	let response = await authService.register(user);
	// console.log(response);

	if (response.ok){
		dispatch({ 
			type: 'REGISTER', 
			token: response.token,
			user: response.user
		});
	}
	else{
		dispatch({ 
			type: 'ERROR', 
			errors: response.errors
		}); 
	} 
}

export const update = () => async (dispatch) => {
	try{
		let user = await authService.getCurrent();
		 // console.log(user);
		authService.setUser(user);

		dispatch({ 
			type: 'UPDATE_USER', 
			user 
		});
	}
	catch (error){
		console.log('Error: ' + error.message);
	}
}