import React from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import {
	FormGroup,
	FormControl,
	FormLabel,
	FormCheck,
	InputGroup,
	Row,
	Col,
	Nav,
	Tab,
} from "react-bootstrap";
import { contractsService, authService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import ContractEmployees from 'components/Contracts/ContractEmployees';
import { FiX } from "react-icons/fi";
import Switch from "components/Switch";
import * as rulesActions from "actions/rulesActions";
import * as userActions from 'actions/userActions';
import "./Contracts.scss";

class ContractEdit extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		let contract = {
			name: "",
			description: "",
			vacation_per_year: 0,
			target_hours_per_week: 0,
			work_days_per_week: 0,
			vacation_type: "Días laborables",
			time_registration_override: false,
			time_registration_tolerance_correction: false,
			time_registration_tolerance_arriving_earlier: 0,
			time_registration_tolerance_arriving_later: 0,
			time_registration_tolerance_leaving_earlier: 0,
			time_registration_tolerance_leaving_later: 0,
			time_registration_global_worked_hours_correction: false, // jornada flexible si/no
			time_registration_global_worked_hours_leaving_earlier: 0,
			time_registration_global_worked_hours_leaving_later: 0,
			not_sum_target_hours_on_holiday: false,
			not_sum_target_hours_on_shifts_ids: [],
			temp_shift: {},
			enabledTolerance: false, // redondeo
			time_registration_not_done_last_ten_days_email_reminder: false,
			time_registration_allowed_without_shift_planned: false,
			target_hours_distribution_per_week: true,
			isWeekly: true,
			vacation_day_hour_equivalence: 0,
			vacation_minimum_amount_required_hour: 0,
			vacation_maximum_amount_required_hour: 0,
			vacation_fraction_requestable_hour: 0,
			allow_manual_time_registrations: true,
			specific_time_types: false,
			time_type_ids: [],
			temp_time_type: {},
			keep_worked_hours_on_overriding_shifts_ids: [],
			temp_absence: {},
			alert_time_registration_started_too_early: 0,
			alert_time_registration_started_too_late: 0,
			alert_time_registration_ended_too_early: 0,
			alert_time_registration_ended_too_late: 0,
			alert_time_registration_started_not_done: 0,
			alert_planning_min_rest_hours: 0,
			alert_planning_max_consecutive_working_days: 0,
			alert_break_too_short: 0,
			alert_break_too_long: 0,
			alert_break_not_planned: false,
			allowed_absence_shifts_ids: [],
			temp_absence_shift: {},
			workflow_status_change_email: false, // enviar email cambio estado ausencia
			workflow_request_email: false, // enviar email cuando se solicita una ausencia
			allow_group_planning_visualization: false, // permitir visualizar planificación de grupo
			alert_group_changed: false,
			alert_plan_changed: false,
		};

		let isEdit = false;

		var selected_shifts = [];
		var selected_time_types = [];
		var selected_absences = [];
		var selected_absence_shifts = [];

		if (props.contract) {
			contract = props.contract;

			isEdit = true;
			contract.enabledTolerance =
				contract.time_registration_global_worked_hours_correction ||
				contract.time_registration_tolerance_correction;

			contract.isWeekly = contract.target_hours_distribution_per_week;

			if (contract.not_sum_target_hours_on_shifts) {
				selected_shifts = contract.not_sum_target_hours_on_shifts
			}
			if (contract.specific_time_types) {
				selected_time_types = contract.time_types
			}
			if (contract.keep_worked_hours_on_overriding_shifts) {
				selected_absences = contract.keep_worked_hours_on_overriding_shifts
			}

			if (contract.allowed_absence_shifts) {
				selected_absence_shifts = contract.allowed_absence_shifts
			}

			contract.alert_time_registration_started_too_early = contract.alert_time_registration_started_too_early || 0;
			contract.alert_time_registration_started_too_late = contract.alert_time_registration_started_too_late || 0;
			contract.alert_time_registration_ended_too_early = contract.alert_time_registration_ended_too_early || 0;
			contract.alert_time_registration_ended_too_late = contract.alert_time_registration_ended_too_late || 0;
			contract.alert_time_registration_started_not_done = contract.alert_time_registration_started_not_done || 0;
			contract.alert_planning_min_rest_hours = contract.alert_planning_min_rest_hours || 0;
			contract.alert_planning_max_consecutive_working_days = contract.alert_planning_max_consecutive_working_days || 0;
			contract.alert_break_too_short = contract.alert_break_too_short || 0;
			contract.alert_break_too_long = contract.alert_break_too_long || 0;

		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			contract,
			selected_shifts,
			selected_time_types,
			selected_absences,
			selected_absence_shifts,
			new_item_errors: {},
		};
	}

	handleChange = (event) => {
		const { contract } = this.state;

		let key = event.target.id;
		let value = event.target.value;

		if (key === "is_work" || key === "is_natural") {
			key = "vacation_type";
		}

		if (key === "dayly" || key === "weekly") {
			value = (key === "weekly");
			key = "isWeekly";
		}

		this.setState({
			contract: {
				...contract,
				[key]: value,
			},
		});
	};

	handleSwitch = (event) => {
		const { contract } = this.state;

		this.setState({
			contract: {
				...contract,
				[event.target.id]: event.target.checked,
			},
		});
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });
		let response = "";
		const props = this.props;
		const contract = this.state.contract;
		contract.target_hours_distribution_per_week = contract.isWeekly;

		contract.target_hours_per_week = contract.target_hours_per_week
			.toString()
			.replace(",", ".") * 1;

		if (contract.vacation_minimum_amount_required_hour)
			contract.vacation_minimum_amount_required_hour = contract.vacation_minimum_amount_required_hour
				.toString()
				.replace(",", ".") * 1;

		if (contract.vacation_day_hour_equivalence)
			contract.vacation_day_hour_equivalence = contract.vacation_day_hour_equivalence
				.toString()
				.replace(",", ".") * 1;

		if (contract.vacation_maximum_amount_required_hour)
			contract.vacation_maximum_amount_required_hour = contract.vacation_maximum_amount_required_hour
				.toString()
				.replace(",", ".") * 1;

		if (contract.target_hours_on_monday)
			contract.target_hours_on_monday = contract.target_hours_on_monday
				.toString()
				.replace(",", ".") * 1;
		if (contract.target_hours_on_tuesday)
			contract.target_hours_on_tuesday = contract.target_hours_on_tuesday
				.toString()
				.replace(",", ".") * 1;

		if (contract.target_hours_on_wednesday)
			contract.target_hours_on_wednesday = contract.target_hours_on_wednesday
				.toString()
				.replace(",", ".") * 1;

		if (contract.target_hours_on_thursday)
			contract.target_hours_on_thursday = contract.target_hours_on_thursday
				.toString()
				.replace(",", ".") * 1;

		if (contract.target_hours_on_friday)
			contract.target_hours_on_friday = contract.target_hours_on_friday
				.toString()
				.replace(",", ".") * 1;

		if (contract.target_hours_on_saturday)
			contract.target_hours_on_saturday = contract.target_hours_on_saturday
				.toString()
				.replace(",", ".") * 1;

		if (contract.target_hours_on_sunday)
			contract.target_hours_on_sunday = contract.target_hours_on_sunday
				.toString()
				.replace(",", ".") * 1;

		contract.time_registration_tolerance_correction =
			contract.enabledTolerance &&
			!contract.time_registration_global_worked_hours_correction;

		if (
			contract.time_registration_global_worked_hours_correction ||
			!contract.enabledTolerance
		) {
			contract.time_registration_tolerance_arriving_earlier = 0;
			contract.time_registration_tolerance_arriving_later = 0;
			contract.time_registration_tolerance_leaving_earlier = 0;
			contract.time_registration_tolerance_leaving_later = 0;
		}

		if (
			contract.time_registration_tolerance_correction ||
			!contract.enabledTolerance
		) {
			contract.time_registration_global_worked_hours_leaving_earlier = 0;
			contract.time_registration_global_worked_hours_leaving_later = 0;
		}

		if (this.state.selected_shifts.length > 0) {
			const selected_shifts_ids = [];

			this.state.selected_shifts.map(shift => {
				selected_shifts_ids.push(shift.id);
				return true;
			});

			contract.not_sum_target_hours_on_shifts_ids = selected_shifts_ids;
		}

		// tipos de tiempo por contrato
		if (this.state.selected_time_types.length > 0) {
			const selected_time_types_ids = [];

			this.state.selected_time_types.map(tt => {
				selected_time_types_ids.push(tt.id);
				return true;
			});

			contract.time_type_ids = selected_time_types_ids;
		}


		// no eliminar transacciones de horas al reemplazar por las siguientes ausencias
		if (this.state.selected_absences.length > 0) {
			const selected_absences_ids = [];

			this.state.selected_absences.map(a => {
				selected_absences_ids.push(a.id);
				return true;
			});

			contract.keep_worked_hours_on_overriding_shifts_ids = selected_absences_ids;
		}

		// solo mostrar estos turnos de ausencia en la solicitud
		if (this.state.selected_absence_shifts.length > 0) {
			const selected_absence_shifts_ids = [];

			this.state.selected_absence_shifts.map(shift => {
				selected_absence_shifts_ids.push(shift.id);
				return true;
			});

			contract.allowed_absence_shifts_ids = selected_absence_shifts_ids;
		}



		// if edit => update; else => new
		if (this.state.isEdit) {
			response = await contractsService.update(contract);
		} else {
			response = await contractsService.add(contract);
		}

		if (response.ok) {
			this.setState(
				{
					isLoading: false,
					errors: {},
				},
				() => {
					toastr.success("¡Bien!", "Cambios guardados correctamente");

					if (this.state.selected_time_types.length > 0) {
						props.user.employee_with_time_types = this.state.selected_time_types;
						authService.setUser(props.user);
					}

					props.getContracts(); // callback enviado desde el componente
					this.props.update();
					props.handleClose();
				}
			);
		} else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response),
			});
		}
	};


	getHoursPerDay = () => {
		const { contract, errors } = this.state; // no borrar
		const days_en = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
		const days_es = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

		var error = '';
		var today_en = '';
		var today_es = '';
		var value = 0;

		const input_days = []

		for (var i = 0; i < 7; i++) {
			today_en = days_en[i];
			today_es = days_es[i];

			value = eval(`contract.target_hours_on_${today_en}`) || 0;
			error = eval(`errors.target_hours_on_${today_en}`) || 0;

			input_days.push(
				<FormGroup key={i}>
					<InputGroup className={this.checkValidField(`target_hours_on_${today_en}`) ? 'is-invalid' : ''}>
						<FormControl
							id={`target_hours_on_${today_en}`}
							type="text"
							value={value
								.toString()
								.replace(".", ",")}
							onChange={this.handleChange}
							className='form-objetivo'
							placeholder="0"
							isInvalid={this.checkValidField(`target_hours_on_${today_en}`)}
						/>
						<InputGroup.Append>
							<InputGroup.Text>{today_es}</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>

					<FormControl.Feedback type="invalid">
						{error}
					</FormControl.Feedback>
				</FormGroup>
			);
		}
		return input_days;
	};

	handleAddChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}


	// shifts
	getSelectedShifts = () => this.state.selected_shifts.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
				<button type="button" onClick={() => this.removeShift(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	addShift = () => {
		let { selected_shifts, temp_shift } = this.state;
		let isValid = true;
		let shift;
		const new_item_errors = {};

		if (temp_shift === undefined) {
			isValid = false;
		}
		else {
			shift = this.props.select_shifts.find(item => item.id === parseInt(temp_shift));
			if (shift === undefined) {
				isValid = false;
				new_item_errors.shift = false;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_shifts.find(item => item.id === parseInt(temp_shift));

			if (!exists) {
				selected_shifts = selected_shifts.concat(shift);
			}

			this.setState({
				selected_shifts,
				new_item_errors: {},
				temp_shift: {},
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}
	removeShift = (idShift) => {
		this.setState((prevState, props) => {
			return {
				selected_shifts: prevState.selected_shifts.filter(item => item.id !== parseInt(idShift))
			};
		});
	}

	// time types

	getSelectedTimeTypes = () => this.state.selected_time_types.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete">
				{item.name}
				<button type="button" onClick={() => this.removeTimeType(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	addTimeType = () => {
		let { selected_time_types, temp_time_type } = this.state;
		let isValid = true;
		let time_type;
		const new_item_errors = {};

		if (temp_time_type === undefined) {
			isValid = false;
		}
		else {
			time_type = this.props.select_time_types.find(item => item.id === parseInt(temp_time_type));
			if (time_type === undefined) {
				isValid = false;
				new_item_errors.time_type = false;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_time_types.find(item => item.id === parseInt(temp_time_type));

			if (!exists) {
				selected_time_types = selected_time_types.concat(time_type);
			}

			this.setState({
				selected_time_types,
				new_item_errors: {},
				temp_time_type: {},
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}
	removeTimeType = (idTimeType) => {
		this.setState((prevState, props) => {
			return {
				selected_time_types: prevState.selected_time_types.filter(item => item.id !== parseInt(idTimeType))
			};
		});
	}

	// absences
	getSelectedAbsences = () => this.state.selected_absences.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
				<button type="button" onClick={() => this.removeAbsence(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	addAbsence = () => {
		let { selected_absences, temp_absence } = this.state;
		let isValid = true;
		let absence;
		const new_item_errors = {};

		if (temp_absence === undefined) {
			isValid = false;
		}
		else {
			absence = this.props.non_attendance_shifts.find(item => item.id === parseInt(temp_absence));
			if (absence === undefined) {
				isValid = false;
				new_item_errors.absence = false;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_absences.find(item => item.id === parseInt(temp_absence));

			if (!exists) {
				selected_absences = selected_absences.concat(absence);
			}

			this.setState({
				selected_absences,
				new_item_errors: {},
				temp_absence: {},
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}
	removeAbsence = (idAbsence) => {
		this.setState((prevState, props) => {
			return {
				selected_absences: prevState.selected_absences.filter(item => item.id !== parseInt(idAbsence))
			};
		});
	}

	// allowed absence shifts

	getSelectedAbsenceShifts = () => this.state.selected_absence_shifts.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
				<button type="button" onClick={() => this.removeAbsenceShift(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	addAbsenceShift = () => {
		let { selected_absence_shifts, temp_absence_shift } = this.state;
		let isValid = true;
		let absence;
		const new_item_errors = {};

		if (temp_absence_shift === undefined) {
			isValid = false;
		}
		else {
			absence = this.props.non_attendance_shifts.find(item => item.id === parseInt(temp_absence_shift));
			if (absence === undefined) {
				isValid = false;
				new_item_errors.absence_shift = false;
			}
		}

		// console.log(absence);

		if (isValid) {
			// check if day already exists
			const exists = selected_absence_shifts.find(item => item.id === parseInt(temp_absence_shift));

			if (!exists) {
				selected_absence_shifts = selected_absence_shifts.concat(absence);
			}

			this.setState({
				selected_absence_shifts,
				new_item_errors: {},
				temp_absence_shift: {},
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}
	removeAbsenceShift = (idAbsence) => {
		this.setState((prevState, props) => {
			return {
				selected_absence_shifts: prevState.selected_absence_shifts.filter(item => item.id !== parseInt(idAbsence))
			};
		});
	}

	render() {
		const { isEdit, contract, errors, temp_shift, temp_time_type, temp_absence, temp_absence_shift, new_item_errors } = this.state;
		const { select_shifts, attendance_shifts, non_attendance_shifts, select_time_types } = this.props;

		const optionShiftList =
			select_shifts.length > 0 &&
			select_shifts.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		const optionAttendanceShiftList =
			attendance_shifts.length > 0 &&
			attendance_shifts.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		const optionTimeTypeList =
			select_time_types.length > 0 &&
			select_time_types.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		const optionAbsenceShiftList =
			non_attendance_shifts.length > 0 &&
			non_attendance_shifts.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		const is_work =
			contract.vacation_type === "Días laborables" ||
			contract.vacation_type === "Natural days";

		const enabledOverride = contract.time_registration_override;
		const enabledTolerance = contract.enabledTolerance;
		const enabledGlobal = contract.time_registration_global_worked_hours_correction;
		const notSumHoliday = contract.not_sum_target_hours_on_holiday;
		const enabledTimeRegistrationNotDoneReminder = contract.time_registration_not_done_last_ten_days_email_reminder;

		const allowFichajeSinTurno = contract.time_registration_allowed_without_shift_planned;
		const isWeekly = contract.isWeekly;

		const allowManualTimeRegistrations = contract.allow_manual_time_registrations;

		const specificTimeTypes = contract.specific_time_types;
		const breakNotPlanned = contract.alert_break_not_planned;

		const enabledRequestEmail = contract.workflow_request_email;
		const enabledWorkflowChangeEmail = contract.workflow_status_change_email;
		const enabledGroupChangeEmail = contract.alert_group_changed;
		const enabledPlanChangeEmail = contract.alert_plan_changed;

		const allowGroupVisualization = contract.allow_group_planning_visualization;



		// console.log(errors);
		return (
			<form onSubmit={this.handleSubmit}>
				<div className="modal-body contract-edit">
					<Tab.Container defaultActiveKey="main">
						<Nav variant="tabs">
							<Nav.Item>
								<Nav.Link eventKey="main">General</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="reglas_horas">Horas trabajadas</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="reglas_fichajes">Fichajes</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="reglas_ausencias">Ausencias</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="alertas">Alertas</Nav.Link>
							</Nav.Item>
							{isEdit &&
								<Nav.Item>
									<Nav.Link eventKey="empleados">Ver empleados</Nav.Link>
								</Nav.Item>
							}
						</Nav>
						<Tab.Content>
							<Tab.Pane eventKey="main">
								<FormGroup controlId="name">
									<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
									<FormControl
										type="text"
										value={contract.name}
										onChange={this.handleChange}
										placeholder="Nombre del contrato"
										isInvalid={this.checkValidField("name")}
									/>

									<FormControl.Feedback type="invalid">
										{errors.name}
									</FormControl.Feedback>
								</FormGroup>

								<FormGroup controlId="description">
									<FormLabel>Descripción</FormLabel>
									<FormControl
										type="text"
										value={contract.description}
										onChange={this.handleChange}
										placeholder="Descripción del contrato"
										isInvalid={this.checkValidField("description")}
									/>

									<FormControl.Feedback type="invalid">
										{errors.name}
									</FormControl.Feedback>
								</FormGroup>

								<div className="objetivo-horas">

									<FormGroup controlId="isWeekly" className="group-hours objetivo-semanal">
										<FormLabel className="hidden">Objetivo de horas</FormLabel>
										<div className="btn-choice-group">
											<FormCheck
												type="radio"
												id="weekly"
												label="Objetivo horas semanal"
												// value="true"
												value={true}
												checked={isWeekly}
												bsPrefix={
													isWeekly ? "btn-choice active" : "btn-choice"
												}
												onChange={this.handleChange}
											/>
											<FormCheck
												type="radio"
												id="dayly"
												label="Objetivo horas por días"
												value={false}
												// value="false"
												checked={!isWeekly}
												bsPrefix={
													!isWeekly ? "btn-choice active" : "btn-choice"
												}
												onChange={this.handleChange}
											/>
										</div>
									</FormGroup>

									{contract.isWeekly && (<div className="group-hours objetivo-semanal">
										<p className="form-label">Objetivo semanal <span className="label-required">*</span></p>

										<div className="simple-row">
											<FormGroup controlId="target_hours_per_week">
												<InputGroup>
													<FormControl
														type="text"
														value={contract.target_hours_per_week
															.toString()
															.replace(".", ",")}
														onChange={this.handleChange}
														placeholder="0"
														isInvalid={this.checkValidField(
															"target_hours_per_week"
														)}
													/>

													<InputGroup.Append>
														<InputGroup.Text>horas</InputGroup.Text>
													</InputGroup.Append>
												</InputGroup>
											</FormGroup>
											<FormGroup controlId="work_days_per_week">
												<InputGroup>
													<FormControl
														type="text"
														value={contract.work_days_per_week}
														onChange={this.handleChange}
														placeholder="0"
														isInvalid={this.checkValidField("work_days_per_week")}
													/>

													<InputGroup.Append>
														<InputGroup.Text>días</InputGroup.Text>
													</InputGroup.Append>
												</InputGroup>
											</FormGroup>
										</div>
									</div>)}


									{!contract.isWeekly && (<div className="group-hours objetivo-diario">
										<p className="form-label">Objetivo horas por días <span className="label-required">*</span></p>
										<div className="grid-hours">
											{this.getHoursPerDay()}

										</div>

										{errors.target_hours_on_every_day && (<div className="invalid-feedback d-block">{errors.target_hours_on_every_day}</div>)}
										{errors.target_hours_distribution_per_week && (<div className="invalid-feedback d-block">{errors.target_hours_distribution_per_week}</div>)}
									</div>)}
								</div>

								<div className="vacaciones">
									<p className="form-label">Vacaciones anuales</p>
									<div className="simple-row">
										<FormGroup controlId="vacation_per_year">
											<FormControl
												type="text"
												value={contract.vacation_per_year}
												onChange={this.handleChange}
												placeholder="0"
												isInvalid={this.checkValidField("vacation_per_year")}
											/>
										</FormGroup>

										<FormGroup className="vacaciones-tipo" controlId="type">
											<div className={`btn-choice-group`}>
												<FormCheck
													type="radio"
													id="is_work"
													label="Días laborables"
													value="Días laborables"
													checked={is_work}
													bsPrefix={
														is_work ? "btn-choice active" : "btn-choice"
													}
													onChange={this.handleChange}
												/>
												<FormCheck
													type="radio"
													id="is_natural"
													label="Días naturales"
													value="Días naturales"
													checked={!is_work}
													bsPrefix={
														!is_work ? "btn-choice active" : "btn-choice"
													}
													onChange={this.handleChange}
												/>
											</div>
										</FormGroup>
									</div>
								</div>

								<Row>
									<Col sm={7}>
										<FormGroup
											className="form-group-flex form-row-switch form-group-main"
											controlId="allow_group_planning_visualization"
										>
											<FormLabel>
												Los empleados pueden ver la planificación de todo el grupo
											</FormLabel>
											<Switch
												controlId="allow_group_planning_visualization"
												isOn={allowGroupVisualization}
												handleToggle={this.handleSwitch}
											/>
										</FormGroup>
									</Col>
								</Row>
							</Tab.Pane>
							<Tab.Pane eventKey="reglas_horas" className="contract-horas">
								<Row>
									<Col sm={8} className="col1">
										{/* <h3 className="subtitle">Reglas de horas</h3> */}

										<FormGroup
											className="form-group-flex form-row-switch form-group-main"
											controlId="not_sum_target_hours_on_holiday"
										>
											<FormLabel>
												No sumar horas objetivo en días festivos
											</FormLabel>
											<Switch
												controlId="not_sum_target_hours_on_holiday"
												isOn={notSumHoliday}
												handleToggle={this.handleSwitch}
											/>
										</FormGroup>

										<div
											className="form-group-flex form-group-main mb20"
										>
											<p className="mb5">No sumar horas objetivo en días con los siguientes turnos planificados:</p>

											<div className="flex-group mb10">
												<FormGroup controlId="temp_shift">
													<FormControl
														type="text"
														value={temp_shift}
														placeholder="Selecciona el turno"
														onChange={this.handleAddChange}
														isInvalid={new_item_errors.shift !== undefined}
														as="select"
													>
														<option value="">Selecciona el turno</option>
														{optionShiftList}
													</FormControl>
												</FormGroup>
												<button type="button" onClick={this.addShift} className="btn btn-primary">Añadir</button>
											</div>
											<ul className="list-tags">
												{this.getSelectedShifts()}
											</ul>
										</div>

										<div
											className="form-group-flex form-group-main"
										>
											<p className="mb5">No eliminar transacciones de horas trabajadas al remplazar turnos de trabajo con las siguientes ausencias:</p>

											<div className="flex-group mb10">
												<FormGroup controlId="temp_absence">
													<FormControl
														type="text"
														value={temp_absence}
														placeholder="Selecciona ausencia"
														onChange={this.handleAddChange}
														isInvalid={new_item_errors.absence !== undefined}
														as="select"
													>
														<option value="">Selecciona ausencia</option>
														{optionAbsenceShiftList}
													</FormControl>
												</FormGroup>
												<button type="button" onClick={this.addAbsence} className="btn btn-primary">Añadir</button>
											</div>
											<ul className="list-tags">
												{this.getSelectedAbsences()}
											</ul>
										</div>
									</Col>
								</Row>
							</Tab.Pane>
							<Tab.Pane eventKey="reglas_fichajes" className="contract-fichajes">
								<Row>
									<Col sm={8} className="col2">
										{/* <h3 className="subtitle">Reglas de fichajes</h3> */}

										<FormGroup
											className="form-group-flex form-row-switch form-group-main"
											controlId="allow_manual_time_registrations"
										>
											<FormLabel>
												Permir introducir fichajes manualmente
											</FormLabel>
											<Switch
												controlId="allow_manual_time_registrations"
												isOn={allowManualTimeRegistrations}
												handleToggle={this.handleSwitch}
											/>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch form-group-main"
											controlId="time_registration_override"
										>
											<FormLabel>
												Sobreescribir turnos planificados con fichajes
											</FormLabel>
											<Switch
												controlId="time_registration_override"
												isOn={enabledOverride}
												handleToggle={this.handleSwitch}
											/>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch form-group-main"
											controlId="enabledTolerance"
										>
											<FormLabel>
												Redondear fichajes a las horas planificadas
											</FormLabel>
											<Switch
												controlId="enabledTolerance"
												isOn={enabledTolerance}
												handleToggle={this.handleSwitch}
											/>
										</FormGroup>

										{enabledTolerance && (
											<FormGroup
												className="form-group-flex form-row-switch form-group-main"
												controlId="time_registration_global_worked_hours_correction"
											>
												<FormLabel>Jornada Flexible</FormLabel>
												<Switch
													controlId="time_registration_global_worked_hours_correction"
													isOn={enabledGlobal}
													handleToggle={this.handleSwitch}
												/>
											</FormGroup>
										)}

										{enabledTolerance && enabledGlobal && (
											<section className="contract-tolerancia">
												<FormGroup
													className="form-group-flex form-row-switch mb0"
													controlId="time_registration_global_worked_hours_leaving_earlier"
												>
													<FormLabel>Redondeo al salir antes</FormLabel>
													<InputGroup>
														<FormControl
															type="text"
															value={contract.time_registration_global_worked_hours_leaving_earlier
																.toString()
																.replace(".", ",")}
															onChange={this.handleChange}
															placeholder="0"
															isInvalid={this.checkValidField(
																"time_registration_global_worked_hours_leaving_earlier"
															)}
														/>

														<InputGroup.Append>
															<InputGroup.Text>min</InputGroup.Text>
														</InputGroup.Append>
													</InputGroup>

													<FormControl.Feedback type="invalid">
														{
															errors
																.time_registration_global_worked_hours_leaving_earlier
														}
													</FormControl.Feedback>
												</FormGroup>

												<FormGroup
													className="form-group-flex form-row-switch"
													controlId="time_registration_global_worked_hours_leaving_later"
												>
													<FormLabel>Redondeo al salir después</FormLabel>
													<InputGroup>
														<FormControl
															type="text"
															value={contract.time_registration_global_worked_hours_leaving_later
																.toString()
																.replace(".", ",")}
															onChange={this.handleChange}
															placeholder="0"
															isInvalid={this.checkValidField(
																"time_registration_global_worked_hours_leaving_later"
															)}
														/>

														<InputGroup.Append>
															<InputGroup.Text>min</InputGroup.Text>
														</InputGroup.Append>
													</InputGroup>

													<FormControl.Feedback type="invalid">
														{
															errors
																.time_registration_global_worked_hours_leaving_later
														}
													</FormControl.Feedback>
												</FormGroup>

												<p className="text-info mt30">
													El redondeo se hará al final del día comparando las
													horas trabajadas de los fichajes con las horas
													planificadas.
												</p>
											</section>
										)}

										{enabledTolerance && !enabledGlobal && (
											<section className="contract-tolerancia mb15">
												<FormGroup
													className="form-group-flex form-row-switch mb0"
													controlId="time_registration_tolerance_arriving_earlier"
												>
													<FormLabel>Redondeo al llegar antes</FormLabel>
													<InputGroup>
														<FormControl
															type="text"
															value={contract.time_registration_tolerance_arriving_earlier
																.toString()
																.replace(".", ",")}
															onChange={this.handleChange}
															placeholder="0"
															isInvalid={this.checkValidField(
																"time_registration_tolerance_arriving_earlier"
															)}
														/>

														<InputGroup.Append>
															<InputGroup.Text>min</InputGroup.Text>
														</InputGroup.Append>
													</InputGroup>

													<FormControl.Feedback type="invalid">
														{
															errors
																.time_registration_tolerance_arriving_earlier
														}
													</FormControl.Feedback>
												</FormGroup>

												<FormGroup
													className="form-group-flex form-row-switch mb0"
													controlId="time_registration_tolerance_arriving_later"
												>
													<FormLabel>Redondeo al llegar después</FormLabel>
													<InputGroup>
														<FormControl
															type="text"
															value={contract.time_registration_tolerance_arriving_later
																.toString()
																.replace(".", ",")}
															onChange={this.handleChange}
															placeholder="0"
															isInvalid={this.checkValidField(
																"time_registration_tolerance_arriving_later"
															)}
														/>

														<InputGroup.Append>
															<InputGroup.Text>min</InputGroup.Text>
														</InputGroup.Append>
													</InputGroup>

													<FormControl.Feedback type="invalid">
														{
															errors
																.time_registration_tolerance_arriving_later
														}
													</FormControl.Feedback>
												</FormGroup>

												<FormGroup
													className="form-group-flex form-row-switch mb0"
													controlId="time_registration_tolerance_leaving_earlier"
												>
													<FormLabel>Redondeo al salir antes</FormLabel>
													<InputGroup>
														<FormControl
															type="text"
															value={contract.time_registration_tolerance_leaving_earlier
																.toString()
																.replace(".", ",")}
															onChange={this.handleChange}
															placeholder="0"
															isInvalid={this.checkValidField(
																"time_registration_tolerance_leaving_earlier"
															)}
														/>

														<InputGroup.Append>
															<InputGroup.Text>min</InputGroup.Text>
														</InputGroup.Append>
													</InputGroup>

													<FormControl.Feedback type="invalid">
														{
															errors
																.time_registration_tolerance_leaving_earlier
														}
													</FormControl.Feedback>
												</FormGroup>

												<FormGroup
													className="form-group-flex form-row-switch mb0"
													controlId="time_registration_tolerance_leaving_later"
												>
													<FormLabel>Redondeo al salir después</FormLabel>
													<InputGroup>
														<FormControl
															type="text"
															value={contract.time_registration_tolerance_leaving_later
																.toString()
																.replace(".", ",")}
															onChange={this.handleChange}
															placeholder="0"
															isInvalid={this.checkValidField(
																"time_registration_tolerance_leaving_later"
															)}
														/>

														<InputGroup.Append>
															<InputGroup.Text>min</InputGroup.Text>
														</InputGroup.Append>
													</InputGroup>

													<FormControl.Feedback type="invalid">
														{
															errors
																.time_registration_tolerance_leaving_later
														}
													</FormControl.Feedback>
												</FormGroup>
											</section>
										)}

										<FormGroup
											className="form-group-flex form-row-switch form-group-main"
											controlId="time_registration_allowed_without_shift_planned"
										>
											<FormLabel>
												Permitir fichajes sin turno planificado
											</FormLabel>
											<Switch
												controlId="time_registration_allowed_without_shift_planned"
												isOn={allowFichajeSinTurno}
												handleToggle={this.handleSwitch}
											/>
										</FormGroup>

										<FormGroup controlId="shift_id" className="mt20">
											<FormLabel>Turno por defecto
												{allowFichajeSinTurno && enabledOverride && (<span className="label-required">*</span>)}
											</FormLabel>
											<FormControl
												value={contract.shift_id}
												onChange={this.handleChange}
												placeholder="Turno"
												isInvalid={this.checkValidField('shift_id')}
												as="select"
											>
												<option value="">Selecciona turno por defecto</option>
												{optionAttendanceShiftList}
											</FormControl>
											<FormControl.Feedback type="invalid">
												{errors.shift_id}
											</FormControl.Feedback>
										</FormGroup>


										<FormGroup
											className="form-group-flex form-row-switch form-group-main"
											controlId="specific_time_types"
										>
											<FormLabel>
												Usar únicamente los tipos de tiempo definidos en el contrato
											</FormLabel>
											<Switch
												controlId="specific_time_types"
												isOn={specificTimeTypes}
												handleToggle={this.handleSwitch}
											/>
										</FormGroup>

										{specificTimeTypes && (
											<div
												className="form-group-flex form-group-main form-group-time-type"
											>
												{/* <p className="mb5">Indicar tipos de tiempo para este contrato:</p> */}

												<div className="flex-group mb10">
													<FormGroup controlId="temp_time_type">
														<FormControl
															type="text"
															value={temp_time_type}
															placeholder="Selecciona el turno"
															onChange={this.handleAddChange}
															//isInvalid={new_item_errors.time_type !== undefined}
															isInvalid={this.checkValidField(
																"time_types"
															)}
															as="select"
														>
															<option value="">Selecciona el tipo de tiempo</option>
															{optionTimeTypeList}
														</FormControl>
														<FormControl.Feedback type="invalid">
															{errors.time_types}
															{errors.time_types_value && <strong> ({errors.time_types_value})</strong>}
														</FormControl.Feedback>
													</FormGroup>
													<button type="button" onClick={this.addTimeType} className="btn btn-primary">Añadir</button>
												</div>
												<ul className="list-tags">
													{this.getSelectedTimeTypes()}
												</ul>
											</div>
										)}
									</Col>
								</Row>
							</Tab.Pane>
							<Tab.Pane eventKey="reglas_ausencias" className="contract-ausencias">

								<Row>
									<Col sm={8} className="col1">

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="vacation_day_hour_equivalence"
										>
											<FormLabel>Equivalencia de un día de vacaciones en horas</FormLabel>
											<InputGroup className="input-group-hours">
												<FormControl
													type="text"
													value={contract.vacation_day_hour_equivalence}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"vacation_day_hour_equivalence"
													)}
												/>
												<InputGroup.Append>
													<InputGroup.Text>h</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{
													errors
														.vacation_day_hour_equivalence
												}
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="vacation_minimum_amount_required_hour"
										>
											<FormLabel>Horas mínimas a solicitar en las ausencias</FormLabel>
											<InputGroup className="input-group-hours">
												<FormControl
													type="text"
													value={contract.vacation_minimum_amount_required_hour ? contract.vacation_minimum_amount_required_hour
														.toString()
														.replace(".", ",") : 0}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"vacation_minimum_amount_required_hour"
													)}
												/>

												<InputGroup.Append>
													<InputGroup.Text>h</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>
											<FormControl.Feedback type="invalid">
												{errors.vacation_minimum_amount_required_hour}
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="vacation_maximum_amount_required_hour"
										>
											<FormLabel>Horas máximas a solicitar en las ausencias</FormLabel>
											<InputGroup className="input-group-hours">
												<FormControl
													type="text"
													value={contract.vacation_maximum_amount_required_hour ? contract.vacation_maximum_amount_required_hour
														.toString()
														.replace(".", ",") : 0}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"vacation_maximum_amount_required_hour"
													)}
												/>

												<InputGroup.Append>
													<InputGroup.Text>h</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{errors.vacation_maximum_amount_required_hour}
											</FormControl.Feedback>
										</FormGroup>


										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="vacation_fraction_requestable_hour"
										>
											<FormLabel>Fracción mínima a solicitar en las ausencias</FormLabel>
											<InputGroup className="input-group-select">
												<FormControl
													value={contract.vacation_fraction_requestable_hour}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField('vacation_fraction_requestable_hour')}
													as="select"
												>
													<option value="">0</option>
													<option value="0.25">15</option>
													<option value="0.5">30</option>
													<option value="0.75">45</option>
												</FormControl>

												<InputGroup.Append>
													<InputGroup.Text>min</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{errors.vacation_fraction_requestable_hour}
											</FormControl.Feedback>
										</FormGroup>

										<hr className="mt25 mb25"></hr>

										<div
											className="form-group-flex form-group-main"
										>
											<p className="mb5">Al solicitar ausencias, solo mostrar los siguientes turnos:</p>

											<div className="flex-group mb10">
												<FormGroup controlId="temp_absence_shift">
													<FormControl
														type="text"
														value={temp_absence_shift}
														placeholder="Selecciona ausencia"
														onChange={this.handleAddChange}
														isInvalid={new_item_errors.absence_shift !== undefined}
														as="select"
													>
														<option value="">Selecciona ausencias</option>
														{optionAbsenceShiftList}
													</FormControl>
												</FormGroup>
												<button type="button" onClick={this.addAbsenceShift} className="btn btn-primary">Añadir</button>
											</div>
											<ul className="list-tags">
												{this.getSelectedAbsenceShifts()}
											</ul>
										</div>
									</Col>
								</Row>


							</Tab.Pane>

							<Tab.Pane eventKey="alertas" className="contract-email">
								<h3 className="subtitle">Emails</h3>
								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="time_registration_not_done_last_ten_days_email_reminder"
								>
									<FormLabel>
										Enviar cada mañana email con el resumen de fichajes no realizados de los últimos 10 días
									</FormLabel>
									<Switch
										controlId="time_registration_not_done_last_ten_days_email_reminder"
										isOn={enabledTimeRegistrationNotDoneReminder}
										handleToggle={this.handleSwitch}
									/>
								</FormGroup>

								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="workflow_request_email"
								>
									<FormLabel>
										Enviar email a supervisor cuando se solicite una ausencia
									</FormLabel>
									<Switch
										controlId="workflow_request_email"
										isOn={enabledRequestEmail}
										handleToggle={this.handleSwitch}
									/>
								</FormGroup>

								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="workflow_status_change_email"
								>
									<FormLabel>
										Enviar email al empleado cuando se apruebe o deniegue una ausencia solicitada
									</FormLabel>
									<Switch
										controlId="workflow_status_change_email"
										isOn={enabledWorkflowChangeEmail}
										handleToggle={this.handleSwitch}
									/>
								</FormGroup>



								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="alert_group_changed"
								>
									<FormLabel>
										Enviar email al empleado cuando se le asigne a un nuevo grupo
									</FormLabel>
									<Switch
										controlId="alert_group_changed"
										isOn={enabledGroupChangeEmail}
										handleToggle={this.handleSwitch}
									/>
								</FormGroup>

								<FormGroup
									className="form-group-flex form-row-switch form-group-main"
									controlId="alert_plan_changed"
								>
									<FormLabel>
										Enviar email al empleado cuando se le cambie un turno en la planificación
									</FormLabel>
									<Switch
										controlId="alert_plan_changed"
										isOn={enabledPlanChangeEmail}
										handleToggle={this.handleSwitch}
									/>
								</FormGroup>

								<hr className="mt25 mb25"></hr>


								<Row>
									<Col sm={6} className="col1">
										<h3 className="subtitle">Alertas de fichajes</h3>

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="alert_time_registration_started_too_early"
										>
											<FormLabel>Al llegar antes de</FormLabel>
											<InputGroup className="input-group-minutes">
												<FormControl
													type="text"
													value={contract.alert_time_registration_started_too_early
														.toString()
														.replace(".", ",")}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"alert_time_registration_started_too_early"
													)}
												/>

												<InputGroup.Append>
													<InputGroup.Text>min</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{
													errors
														.alert_time_registration_started_too_early
												}
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="alert_time_registration_started_too_late"
										>
											<FormLabel>Al llegar después de</FormLabel>
											<InputGroup className="input-group-minutes">
												<FormControl
													type="text"
													value={contract.alert_time_registration_started_too_late
														.toString()
														.replace(".", ",")}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"alert_time_registration_started_too_late"
													)}
												/>

												<InputGroup.Append>
													<InputGroup.Text>min</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{
													errors
														.alert_time_registration_started_too_late
												}
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="alert_time_registration_ended_too_early"
										>
											<FormLabel>Al salir antes de</FormLabel>
											<InputGroup className="input-group-minutes">
												<FormControl
													type="text"
													value={contract.alert_time_registration_ended_too_early
														.toString()
														.replace(".", ",")}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"alert_time_registration_ended_too_early"
													)}
												/>

												<InputGroup.Append>
													<InputGroup.Text>min</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{
													errors
														.alert_time_registration_ended_too_early
												}
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="alert_time_registration_ended_too_late"
										>
											<FormLabel>Al salir después de</FormLabel>
											<InputGroup className="input-group-minutes">
												<FormControl
													type="text"
													value={contract.alert_time_registration_ended_too_late
														.toString()
														.replace(".", ",")}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"alert_time_registration_ended_too_late"
													)}
												/>

												<InputGroup.Append>
													<InputGroup.Text>min</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{
													errors
														.alert_time_registration_ended_too_late
												}
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="alert_time_registration_started_not_done"
										>
											<FormLabel>Si no registra fichaje antes de</FormLabel>
											<InputGroup className="input-group-minutes">
												<FormControl
													type="text"
													value={contract.alert_time_registration_started_not_done
														.toString()
														.replace(".", ",")}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"alert_time_registration_started_not_done"
													)}
												/>

												<InputGroup.Append>
													<InputGroup.Text>min</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{
													errors
														.alert_time_registration_started_not_done
												}
											</FormControl.Feedback>
										</FormGroup>

									</Col>
									<Col sm={6} className="col2">
										<h3 className="subtitle">Alertas de planificación</h3>


										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="alert_planning_min_rest_hours"
										>
											<FormLabel>Horas descanso mínimas entre turnos</FormLabel>
											<InputGroup className="input-group-minutes">
												<FormControl
													type="text"
													value={contract.alert_planning_min_rest_hours
														.toString()
														.replace(".", ",")}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"alert_planning_min_rest_hours"
													)}
												/>

												<InputGroup.Append>
													<InputGroup.Text>horas</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{
													errors
														.alert_planning_min_rest_hours
												}
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="alert_planning_max_consecutive_working_days"
										>
											<FormLabel>Máximo días de trabajo consecutivos</FormLabel>
											<InputGroup className="input-group-minutes">
												<FormControl
													type="text"
													value={contract.alert_planning_max_consecutive_working_days
														.toString()
														.replace(".", ",")}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"alert_planning_max_consecutive_working_days"
													)}
												/>

												<InputGroup.Append>
													<InputGroup.Text>días</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{
													errors
														.alert_time_registration_started_not_done
												}
											</FormControl.Feedback>
										</FormGroup>

										<h3 className="subtitle mt20">Alertas de pausas</h3>

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="alert_break_too_short"
										>
											<FormLabel>Pausa mínima</FormLabel>
											<InputGroup className="input-group-minutes">
												<FormControl
													type="text"
													value={contract.alert_break_too_short
														.toString()
														.replace(".", ",")}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"alert_break_too_short"
													)}
												/>
												<InputGroup.Append>
													<InputGroup.Text>min</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{errors.alert_break_too_short}
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch"
											controlId="alert_break_too_long"
										>
											<FormLabel>Pausa máxima</FormLabel>
											<InputGroup className="input-group-minutes">
												<FormControl
													type="text"
													value={contract.alert_break_too_long
														.toString()
														.replace(".", ",")}
													onChange={this.handleChange}
													placeholder="0"
													isInvalid={this.checkValidField(
														"alert_break_too_long"
													)}
												/>
												<InputGroup.Append>
													<InputGroup.Text>min</InputGroup.Text>
												</InputGroup.Append>
											</InputGroup>

											<FormControl.Feedback type="invalid">
												{errors.alert_break_too_long}
											</FormControl.Feedback>
										</FormGroup>

										<FormGroup
											className="form-group-flex form-row-switch form-group-main"
											controlId="alert_break_not_planned"
										>
											<FormLabel>
												Pausa no planificada
											</FormLabel>
											<Switch
												controlId="alert_break_not_planned"
												isOn={breakNotPlanned}
												handleToggle={this.handleSwitch}
											/>
										</FormGroup>

									</Col>
								</Row>
							</Tab.Pane>
							{isEdit &&
								<Tab.Pane eventKey="empleados" className="contract-empleados">
									<ContractEmployees employees={contract.employees} />
								</Tab.Pane>
							}
						</Tab.Content>
					</Tab.Container>

					{/* Mensaje de error addicional por si queda escondido con los tabs */}
					{Object.keys(errors).length > 0 && (<div className="alert alert-danger alert-footer" role="alert">
						Hay errores en el formulario
					</div>)}



				</div>

				<div className="modal-footer">
					<button
						type="button"
						onClick={this.props.handleClose}
						className="btn btn-outline-primary"
					>
						Cancelar
					</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form >
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.rulesReducer,
		...reducers.shiftsReducer,
		...reducers.timesReducer,
		...reducers.authReducer,
		user: reducers.authReducer.user,
	}
};

const mapDispatchToProps = (dispatch) => ({
	update: () => dispatch(userActions.update()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractEdit);
