import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, FormControl, FormLabel, Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { TabletOrDesktop, Mobile } from 'utils/responsive';
import { Link } from "react-router-dom";
import { FiPlus, FiSearch, FiMoreHorizontal, FiDownload, FiList, FiGrid, FiUpload } from "react-icons/fi";
import Moment from "react-moment";
import { errorFormating } from "utils/utils";
import Avatar from 'components/Avatar';
import * as employeesActions from "actions/employeesActions";
import * as groupsActions from "actions/groupsActions";
import { showModal, hideModal, updateModalLoading } from "actions/modalActions";
import { employeesService, appService } from "services";
import Loading from "components/Loading";
import Switch from "components/Switch";
import EmployeeListItemHeader from "components/Employees/EmployeeListItemHeader";
import Layout from "components/Layout";
import Modal from 'components/Modal';
import "components/Employees/Employees.scss";

class Employees extends Component {
	constructor(props) {
		super(props);
		//console.log(props);

		this.state = {
			searchEmployee: "",
			filterGroupId: "",
			active_only: true,
			is_list_view: true,
		};
		this.closeModal = this.closeModal.bind(this);
		this.openDeleteEmployeeModal = this.openDeleteEmployeeModal.bind(this);
	}

	handleChangeSearch = event => {
		const targetId = event.target.id;

		this.setState({
			[targetId]: targetId === "active_only" ? event.target.checked : event.target.value
		}, () => {
			this.getEmployees()
		});
	}

	async getEmployees() {
		await this.props.getAllEmployees({
			q: this.state.searchEmployee,
			group_id: this.state.filterGroupId,
			enabled: this.state.active_only,
			variant: "select"
		});
	}

	async getGroups() {
		await this.props.getAllGroups();
	}

	closeModal() {
		this.props.hideModal()
	}

	openDeleteEmployeeModal(idEmployee) {
		this.props.showModal({
			open: true,
			title: 'Eliminar empleado',
			style: { width: '400px' },
			message: '¿Desea eliminar el empleado? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idEmployee),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(title, message) {
		this.props.showModal({
			open: true,
			title: title,
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (idEmployee) => {
		const response = await employeesService.remove(idEmployee);
		this.closeModal();

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal("Error al eliminar", textError.id);
		}
		else {
			this.getEmployees();
			this.closeModal();
		}
	}

	componentDidMount() {
		this.getEmployees();
		this.getGroups();
	}

	// Employee view list / grid
	handleChangeView = (is_list_view) => {
		this.setState({
			is_list_view,
		});
	};

	openExportEmployeesModal(token) {
		this.props.showModal(
			{
				open: true,
				title: "Exportar empleados",
				style: { width: "400px" },
				confirmText: "Descargar",
				loadingText: "Descargando...",
				message:
					"Los empleados se han exportado correctamente. Haz click en Descargar para iniciar la descarga",
				closeModal: this.closeModal,
				confirmAction: () => {
					this.props.updateModalLoading(true);
					this.downloadExport(token, "empleados.xlsx")
				},
			},
			"confirm"
		);
	}

	async handleExportEmployees() {
		const { searchEmployee, filterGroupId, active_only } = this.state;

		const token = await employeesService.exportEmployees({
			enabled: active_only,
			group_id: filterGroupId,
			q: searchEmployee
		});

		if (token.ok) {
			this.openExportEmployeesModal(token.access_token);
		} else {
			this.openAlertModal(
				"Ha ocurrido un error en la exportación",
				"Error al exportar"
			);
		}
	}

	async downloadExport(token, filename) {
		const response = await appService.getExported(token);
		var url = window.URL.createObjectURL(response);
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.href = url;
		a.download = filename;
		a.click();

		this.props.updateModalLoading(false); // Cambiar a no cargando
		this.closeModal(); // cerrar modal de confirmación
	}



	getContent = () => {
		if (this.props.loading) {
			return <Loading />;
		}

		if (this.props.error) {
			return "No se ha podido cargar el listado de empleados";
		}

		if (this.props.employees == undefined || this.props.employees.length === 0)
			return this.getEmptyRow()

		if (this.state.is_list_view)
			return (
				<>
					<Mobile>
						<table className="table table-employees table-mobile">
							<tbody>
								{this.getTableRowsMobile()}
							</tbody>
						</table>
					</Mobile>
					<TabletOrDesktop>
						<table className="table table-employees table-desktop table-zebra">
							<thead>
								<tr>
									<th>Nombre</th>
									<th>Cargo</th>
									<th>Contacto</th>
									<th>Fecha alta</th>
									<th>Activo</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{this.getTableRows()}
							</tbody>
						</table>
					</TabletOrDesktop>
				</>
			)

		return <div className="row grid-employees">{this.getGridRows()}</div>

	};

	getEmptyRow = () => {
		return <p>Sin resultados.</p>;
	};

	openViewEmployeePage(employee) {
		this.props.history.push("/employees/" + employee.id);
	}

	getActions = (employee) => {
		return (
			<Dropdown>
				<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
				<Dropdown.Menu alignRight>
					<Dropdown.Item as="button" onClick={() => this.openViewEmployeePage(employee)}>Ver perfil</Dropdown.Item>
					{!this.state.isMe &&
						<Dropdown.Item as="button" onClick={() => this.openDeleteEmployeeModal(employee.id)}>Eliminar</Dropdown.Item>
					}
				</Dropdown.Menu>
			</Dropdown>
		)
	};

	getTableRows = () =>
		this.props.employees.map((employee) => (
			<tr key={employee.id}>
				<td className="td-name">
					<div className="td-name-content">
						<Link to={`/employees/${employee.id}`}><Avatar userId={employee.id} className="avatar-sm" alt={employee.name} /></Link>
						<div className="employee-info">
							<Link className="employee-name" to={`/employees/${employee.id}`}>{employee.name} {employee.surname}</Link>
							{employee.code && <span className="employee-code">{employee.code}</span>}
						</div>
					</div>
				</td>
				<td>
					{employee.position}
				</td>
				<td>
					<span className="employee-email">{employee.email}</span>
					<span className="employee-phone">{employee.phone}</span>
				</td>
				<td>
					<Moment className="tag-dia" format="DD/MM/YYYY">
						{employee.start_day}
					</Moment>
				</td>
				<td>
					{employee.enabled
						? <span title='Activo' className='estado estado-si'>Activo</span>
						: <span title='Inactivo' className='estado estado-no'>Inactivo</span>
					}
				</td>
				<td className="td-actions">
					{this.getActions(employee)}
				</td>
			</tr>
		));

	getTableRowsMobile = () =>
		this.props.employees.map((employee) => (
			<tr key={employee.id}>
				<td className="td-name">
					<div className="td-name-content">
						<Link to={`/employees/${employee.id}`}><Avatar userId={employee.id} className="avatar-sm" alt={employee.name} /></Link>
						<div className="employee-info">
							<Link className="employee-name" to={`/employees/${employee.id}`}>{employee.name} {employee.surname}</Link>
							{employee.code && <span className="employee-code">{employee.code}</span>}
							{employee.position && <span className="employee-position">{employee.position}</span>}
						</div>
					</div>
				</td>
				<td>
					{employee.enabled
						? <span title='Activo' className='estado estado-si'>Activo</span>
						: <span title='Inactivo' className='estado estado-no'>Inactivo</span>
					}
				</td>
				<td className="td-actions">
					{this.getActions(employee)}
				</td>
			</tr>
		));

	getGridRows = () =>
		this.props.employees.map((employee) => (
			<div className="col-sm-6 col-lg-4 col-xl-3" key={employee.id}>
				<article className="card" key={employee.id}>
					<EmployeeListItemHeader employee={employee} />

					<div className="card-content">
						<a className="email" href="mailto:{ employee.email }">
							{employee.email}
						</a>

						{employee.code && (
							<div className="form-group no-form">
								<label>Código de empleado</label>
								<span>{employee.code}</span>
							</div>
						)}

						{employee.phone && (
							<div className="form-group no-form">
								<label>Teléfono</label>
								<span>{employee.phone}</span>
							</div>
						)}

						<div className="form-group no-form">
							<label>Fecha de alta</label>
							<Moment className="tag-dia" format="D MMMM YYYY">
								{employee.start_day}
							</Moment>
						</div>

						{employee.end_day ? (
							<div className="form-group no-form">
								<label>Fecha de baja</label>
								<Moment className="tag-dia" format="D MMMM YYYY">
									{employee.end_day}
								</Moment>
							</div>
						) : (
							<div className="form-group no-form">
								<label>Antigüedad</label>
								<p className="seniority">
									<span className="seniority-time">
										{employee.seniority.years}
									</span>
									{employee.seniority.years === 1 ? "año" : "años"}
									<span className="seniority-time">
										{employee.seniority.months}
									</span>
									{employee.seniority.months === 1 ? "mes" : "meses"}
									&nbsp;y{" "}
									<span className="seniority-time">
										{employee.seniority.days}
									</span>
									{employee.seniority.days === 1 ? "día" : "días"}
								</p>
							</div>
						)}
					</div>
					<Link
						className="btn btn-primary btn-inline"
						to={`/employees/${employee.id}`}
					>
						Ver perfil
					</Link>
				</article>
			</div>
		));

	render() {
		const { groups } = this.props;
		const { is_list_view, active_only } = this.state;

		const optionList =
			groups.length > 0 &&
			groups.map((item, i) => {
				return (
					<option key={i} value={item.id}>
						{item.name}
					</option>
				);
			}, this);

		return (
			<>
				<Layout className="page-employees" context="employees">
					<div className="heading">
						<div className="heading-left">
							<h1 className="title">Empleados</h1>
						</div>
						<div className="heading-filters">
							<FormGroup
								className="form-group-flex form-switch"
								controlId="active_only"
							>
								<Switch
									controlId="active_only"
									isOn={active_only}
									handleToggle={this.handleChangeSearch}
								/>
								<FormLabel>Mostrar activos</FormLabel>
							</FormGroup>

							<FormGroup className="form-search" controlId="searchEmployee">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.searchEmployee}
									onChange={this.handleChangeSearch}
								/>
								<FiSearch />
							</FormGroup>
							<FormGroup className="form-search" controlId="filterGroupId">
								<FormControl
									type="text"
									placeholder="Buscar..."
									value={this.state.filterGroupId}
									onChange={this.handleChangeSearch}
									as="select"
								>
									<option value="">Filtrar por grupo</option>
									{optionList}
								</FormControl>
							</FormGroup>

							<ButtonGroup aria-label="Employees view">
								<Button
									variant={`${is_list_view ? "primary" : "default"}`}
									onClick={() => this.handleChangeView(true)}
								>
									<FiList className="icon" />
								</Button>
								<Button
									variant={`${!is_list_view ? "primary" : "default"}`}
									onClick={() => this.handleChangeView(false)}
								>
									<FiGrid className="icon" />
								</Button>
							</ButtonGroup>
						</div>

						<div className="heading-actions">
							<Link
								to="/employees/new"
								title="Añadir empleado"
								className="btn btn-new"
							>
								<FiPlus />
							</Link>
							<Dropdown>
								<Dropdown.Toggle variant="action">
									<FiMoreHorizontal />
								</Dropdown.Toggle>
								<Dropdown.Menu alignRight>
									<Dropdown.Item
										as="button"
										onClick={() => this.props.history.push("/employees/import")}
									>
										<FiDownload className="dropdown-icon" /> Importar empleados
									</Dropdown.Item>
									<Dropdown.Item
										as="button"
										onClick={() =>
											this.props.history.push("/employees/shift-patterns/import")
										}
									>
										<FiDownload /> Importar asignaciones de ruedas de turnos
									</Dropdown.Item>
									<Dropdown.Item
										as="button"
										onClick={() => this.handleExportEmployees()}
									>
										<FiUpload className="dropdown-icon" /> Exportar empleados
									</Dropdown.Item>

								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{this.getContent()}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.employeesReducer,
		...reducers.groupsReducer,
		loading: reducers.employeesReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	updateModalLoading: (isLoading) => dispatch(updateModalLoading(isLoading)),
	getAllEmployees: (options) =>
		dispatch(employeesActions.getAllEmployees(options)),
	getAllGroups: () => dispatch(groupsActions.getAllGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
