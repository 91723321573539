import { handleResponse } from "utils/utils";
import { authService } from "services";

export const widgetsService = {
	getAll,
	getById,
	add,
	update,
	remove,
	clone,
	addToEmployee,
	deleteFromEmployee,
	getByEmployeeId,
	getWidgets
};

// get widget templates
function getAll(options) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`
		},
	};

	let query = '?sort=+id';

	if (options) {
		let { company_id, q, employee_id } = options;

		if (q !== undefined)
			query += "&q=" + q;

		if (company_id !== undefined && company_id !== "")
			query += "&company_id=" + company_id;

		if (employee_id !== undefined && employee_id !== "")
			query += "&employee_id=" + employee_id;
	}

	return fetch(`${global.BASE_API}widget-templates${query}`, requestOptions).then(handleResponse);
}

// get widget template by id
function getById(id) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`
		},
	};

	return fetch(`${global.BASE_API}widget-templates/${id}`, requestOptions).then(handleResponse);
}

// add new widget template
function add(widget) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(widget)
	};

	return fetch(`${global.BASE_API}widget-templates/`, requestOptions).then(handleResponse);
}

// update widget template
function update(widget) {
	const requestOptions = {
		method: 'PUT',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(widget)
	};

	return fetch(`${global.BASE_API}widget-templates/${widget.id}`, requestOptions).then(handleResponse);
}

// remove widget template
function remove(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`
		},
	};

	return fetch(`${global.BASE_API}widget-templates/${id}`, requestOptions).then(handleResponse);
}

// clone widget template
function clone(id) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}widget-templates/${id}/clone`, requestOptions).then(
		handleResponse
	);
}

// assign widget template to employee
function addToEmployee(employeeId, widgetId) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}widget-templates/${widgetId}/employee/${employeeId}`, requestOptions).then(
		handleResponse
	);
}

// unassign widget template to employee
function deleteFromEmployee(employeeId, widgetId) {
	const requestOptions = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
		},
	};

	return fetch(`${global.BASE_API}widget-templates/${widgetId}/employee/${employeeId}`, requestOptions).then(
		handleResponse
	);
}

// get widget template by employee id
function getByEmployeeId(employeeId) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`
		},
	};

	return fetch(`${global.BASE_API}widget-templates/employee/${employeeId}`, requestOptions).then(handleResponse);
}

// get all default widgets
function getWidgets() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${authService.token.access_token}`
		},
	};
	return fetch(`${global.BASE_API}widget-templates/default`, requestOptions).then(handleResponse);
}