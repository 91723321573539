import { handleResponse, } from "utils/utils";
import { authService } from "services";

export const iPlanService = {
	updateMagicPlan,
	testIP,
};

function updateMagicPlan(iplan) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(iplan),
	};

	return fetch(
		`${global.BASE_API}intelligent-planning/assignments`,
		// `http://85.208.21.87/saas/api/intelligent-planning/assignments`,
		requestOptions
	).then(handleResponse);
}

function testIP() {
	const test = {
		"start": "2022-12-01",
		"end": "2022-12-31",
		"group_id": "342", //117
		"minimum_daily_hours": {
			"active": true,
			"value": 6
		},
		"maximum_daily_hours": {
			"active": true,
			"value": 12
		},
		"max_working_hours_between_restdays": {
			"active": true,
			"value": 40
		},
		"min_resthours_between_workdays": {
			"active": false
		},
		"min_resthours_between_shifts": {
			"active": false
		},
		"min_weekly_restdays": {
			"active": false
		},
		"min_consecutive_restdays": {
			"active": false
		},
		"rest_day_types": {
			"active": false
		},
		"extra_hours_allowed": true,
		"balance_working_hours": false
	}

	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(test),
	};

	return fetch(
		`${global.BASE_API}intelligent-planning/assignments`,
		requestOptions
	).then(handleResponse);
}
