import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { FiChevronDown, FiMenu, FiHelpCircle } from "react-icons/fi";

import logo from "assets/logo.svg";
import iso from "assets/isotype.svg";
import { isAdmin, isTrial, getTrialDays } from "utils/utils";
import Avatar from "components/Avatar";
import Sidebar from "./Sidebar";
import { authService } from "services";
import "./Header.scss";

const Header = (props) => {
	const { user } = props;
	var days;

	window.Intercom("boot", {
		app_id: "nzk8tbj7",
		user_id: user.id,
		email: user.email,
		name: user.name,
		rol: user.position,
	});

	// console.log(user.subscription);

	if (isTrial()) {
		const totalDays = getTrialDays();
		// console.log(totalDays);

		if (totalDays == 0) {
			days = "Hoy es tu último día de suscripción a Plain";
		} else if (totalDays < 0) {
			days = "Tu suscripción de prueba a Plain ha caducado";
		} else {
			days = `Te quedan ${totalDays} días de prueba en tu suscripción a plain`;
		}
	}

	const handleLogout = () => {
		authService.logout({});
	};

	return (
		<>
			{isTrial() && (
				<div className="header-trial">
					{days}
					<Link to="/contract-plans" className="btn btn-primary">
						Contrata ahora
					</Link>
				</div>
			)}
			<header className="header">
				<div className="header-container">
					<div className="header-main">
						<button
							type="button"
							title="Menu"
							onClick={props.toggleNav}
							className="btn btn-transparent btn-menu"
						>
							<FiMenu />
						</button>

						<Link className="header-logo-link" to="/">
							<img className="header-logo" src={logo} alt="Logo Plain" />
							<img className="header-iso" src={iso} alt="Logo Plain" />
						</Link>
					</div>

					<div className="header-right">
						<a
							className="link-help"
							title="Academia de Plain"
							rel="noopener noreferrer"
							target="_blank"
							href="https://help.plain.ninja/es/"
						>
							<FiHelpCircle className="icon-help" />
						</a>

						<div className="header-menu">
							<div className="header-menu-profile">
								<Avatar
									userId={user.id}
									className="header-avatar avatar-xs"
									alt={user.name}
								/>
								<span className="header-username">
									{user.name} {user.surname}
								</span>{" "}
								<FiChevronDown className="header-dropdown-arrow" />
							</div>
							<ul>
								<li className="option-profile">
									<Link className="header-menu-item" to="/profile">
										Perfil
									</Link>
								</li>
								{isAdmin() && (
									<>
										<li className="option-company">
											<Link className="header-menu-item" to="/my-company">
												Mi empresa
											</Link>
										</li>
										<li className="option-subscription">
											<Link className="header-menu-item" to="/my-subscription">
												Mi suscripción
											</Link>
										</li>
									</>
								)}
								<li className="option-logout">
									<button
										className="header-menu-item button-link"
										onClick={handleLogout}
									>
										Cerrar Sesión
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</header>
			{props.isMobileNavVisible && (
				<>
					<Sidebar className="mobile-sidebar" handleClose={props.closeNav} />
					<div className="mobile-sidebar-overlay" onClick={props.closeNav} />
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	// console.log(state);
	return {
		user: state.authReducer.user,
	};
};

// export default Header;
export default connect(mapStateToProps, null)(withRouter(Header));
// export default connect(mapStateToProps,mapDispatchToProps)(Header);
