import { handleResponse, handleBlobResponse } from "utils/utils";
import { authService } from "services";
import moment from 'moment';

export const timesService = {
    getAll,
    getAssignments,
    getById,
    getLatest,
    getToday,
    // getTimeTypes,
    add,
    update,
    remove,
    approve,
    exportList,
    getAttachment,
    updateAttachments,
    removeAttachment,
    alertsToday,
    alerts,
};

function getAll(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'X-Geo-IP': global.GetIPHeader
        },
    };

    let { employee_id, start_from, start_to, group_id, page, sort, not_ended, include_supervised, include_all, pending_only } = options;

    if (page === undefined)
        page = 0;

    let query = '?page=' + page;

    if (employee_id !== undefined)
        query += "&employee_id=" + employee_id;

    if (start_from !== undefined && start_from !== null && start_from !== '')
        query += "&start_from=" + start_from;

    if (start_to !== undefined && start_to !== null && start_to !== '')
        query += "&start_to=" + start_to;

    if (group_id !== undefined)
        query += "&group_id=" + group_id;

    if (sort !== undefined)
        query += "&sort=" + sort;

    if (not_ended)
        query += "&not_ended=true";

    if (include_all)
        query += "&include_all=true";

    if (pending_only)
        query += "&approved=false";

    // console.log(group_id);
    // console.log(employee_id);

    if ((group_id === undefined || group_id === '') && (employee_id === undefined || employee_id === '') && include_supervised) {
        // console.log("entra");
        query += "&include_supervised=true";
    }

    // if (include_supervised) {
    //     query += "&include_supervised=true";
    // }

    return fetch(`${global.BASE_API}time-registrations${query}`, requestOptions).then(handleResponse);
}

function getAssignments(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'X-Geo-IP': global.GetIPHeader
        },
    };

    let { employee_id, start_from, start_to, group_id, page, sort, not_ended, include_supervised, include_all } = options;

    if (page === undefined)
        page = 0;

    let query = '?page=' + page;

    if (employee_id !== undefined)
        query += "&employee_id=" + employee_id;

    if (start_from !== undefined && start_from !== null && start_from !== '')
        query += "&start_from=" + start_from;

    if (start_to !== undefined && start_to !== null && start_to !== '')
        query += "&start_to=" + start_to;

    if (group_id !== undefined)
        query += "&group_id=" + group_id;

    if (sort !== undefined)
        query += "&sort=" + sort;

    if (not_ended)
        query += "&not_ended=true";

    if (include_all)
        query += "&include_all=true";

    // console.log(group_id);
    // console.log(employee_id);

    if ((group_id === undefined || group_id === '') && (employee_id === undefined || employee_id === '') && include_supervised) {
        // console.log("entra");
        query += "&include_supervised=true";
    }

    // if (include_supervised) {
    //     query += "&include_supervised=true";
    // }

    return fetch(`${global.BASE_API}time-registrations/assignments${query}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'X-Geo-IP': global.GetIPHeader
        },
    };

    return fetch(`${global.BASE_API}time-registrations/${id}`, requestOptions).then(handleResponse);
}

function getLatest(page_size, employee_id) {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'X-Geo-IP': global.GetIPHeader
        },
    };

    let query = '?page=0';

    if (employee_id !== undefined)
        query += "&employee_id=" + employee_id;

    if (page_size !== undefined)
        query += "&page_size=" + page_size;


    return fetch(`${global.BASE_API}time-registrations${query}`, requestOptions).then(handleResponse);
}

function getToday(employee_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'X-Geo-IP': global.GetIPHeader
        },
    };
    // anterior:
    // const today = moment().format('YYYY-MM-DD');
    // let query = '?start_from=' + today;

    // Resta 12 horas de la fecha y hora actual (bueno, más para asegurar que no se pierda nada)
    const twelveHoursAgo = moment().subtract(16, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    // console.log(twelveHoursAgo);

    // Actualiza la consulta para incluir la fecha y hora actuales menos 12 horas
    let query = '?start_from=' + twelveHoursAgo;

    if (employee_id !== undefined)
        query += "&employee_id=" + employee_id;


    return fetch(`${global.BASE_API}time-registrations${query}`, requestOptions).then(handleResponse);
}

function add(time) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json',
            'X-Geo-IP': global.GetIPHeader
        },
        body: JSON.stringify(time)
    };

    return fetch(`${global.BASE_API}time-registrations/`, requestOptions).then(handleResponse);
}

function update(id, time) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json',
            'X-Geo-IP': global.GetIPHeader
        },
        body: JSON.stringify(time)
    };

    return fetch(`${global.BASE_API}time-registrations/${id}`, requestOptions).then(handleResponse);
}

function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'X-Geo-IP': global.GetIPHeader
        },
    };

    return fetch(`${global.BASE_API}time-registrations/${id}`, requestOptions).then(handleResponse);
}

function approve(list) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json',
            'X-Geo-IP': global.GetIPHeader
        },
        body: JSON.stringify(list)
    };

    return fetch(`${global.BASE_API}time-registrations/`, requestOptions).then(handleResponse);
}

function exportList(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
        },
    };

    const { employee_id, start_from, start_to, group_id, include_approved_workflows, include_supervised, include_all_workflows } = options;

    let query = '?page=0';

    if (employee_id !== undefined)
        query += "&employee_id=" + employee_id;

    if (start_from !== undefined && start_from !== null && start_from !== '')
        query += "&start_from=" + start_from;

    if (start_to !== undefined && start_to !== null && start_to !== '')
        query += "&start_to=" + start_to;

    if (group_id !== undefined)
        query += "&group_id=" + group_id;

    if (include_approved_workflows)
        query += "&include_approved_workflows=true";

    if (include_supervised) {
        // console.log("entra");
        query += "&include_supervised=true";
    }

    if (include_all_workflows) {
        // console.log("entra");
        query += "&include_all_workflows=true";
    }

    return fetch(`${global.BASE_API}time-registrations/export${query}`, requestOptions).then(handleResponse);
}


// attachments

// get one attachment by uuid
function getAttachment(timeId, attachmentId) {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
    };

    return fetch(
        `${global.BASE_API}time-registrations/${timeId}/attachments/${attachmentId}`,
        requestOptions
    ).then(handleBlobResponse);
}

// update attachment from time registration
function updateAttachments(id, formData) {
    const requestOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
        body: formData,
    };

    return fetch(
        `${global.BASE_API}time-registrations/${id}/attachments`,
        requestOptions
    ).then(handleResponse);
}

// delete attachment from time registration
function removeAttachment(timeId, attachmentId) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${authService.token.access_token}`,
        },
    };

    return fetch(
        `${global.BASE_API}time-registrations/${timeId}/attachments/${attachmentId}`,
        requestOptions
    ).then(handleResponse);
}

// alertas de time registrations para hoy
function alertsToday() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'X-Geo-IP': global.GetIPHeader
        },
    };

    return fetch(`${global.BASE_API}time-registrations/alerts/today`, requestOptions).then(handleResponse);
}

// alertas de time registrations
// mismas opciones de filtro que getAll
function alerts(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'X-Geo-IP': global.GetIPHeader
        },
    };

    let { employee_id, start_from, start_to, group_id, page, sort, not_ended, include_supervised, include_all, pending_only } = options;

    if (page === undefined)
        page = 0;

    let query = '?page=' + page;

    if (employee_id !== undefined)
        query += "&employee_id=" + employee_id;

    if (start_from !== undefined && start_from !== null && start_from !== '')
        query += "&start_from=" + start_from;

    if (start_to !== undefined && start_to !== null && start_to !== '')
        query += "&start_to=" + start_to;

    if (group_id !== undefined)
        query += "&group_id=" + group_id;

    if (sort !== undefined)
        query += "&sort=" + sort;

    if (not_ended)
        query += "&not_ended=true";

    if (include_all)
        query += "&include_all=true";

    if (pending_only)
        query += "&approved=false";

    // console.log(group_id);
    // console.log(employee_id);

    if ((group_id === undefined || group_id === '') && (employee_id === undefined || employee_id === '') && include_supervised) {
        query += "&include_supervised=true";
    }

    return fetch(`${global.BASE_API}time-registrations/alerts${query}`, requestOptions).then(handleResponse);
}