import React from "react";
import { Redirect, Route } from "react-router-dom";
import { authService } from "services";
// import { isLogin } from "utils/utils";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			const currentUser = authService.currentUser;

			if (!currentUser) {
				// not logged in so redirect to login page with the return url
				return (
					<Redirect
						to={{ pathname: "/login", state: { from: props.location } }}
					/>
				);
			}

			// check if route is restricted by role
			if (roles) {
				const matchRole = roles.some((r) => currentUser.roles.indexOf(r) >= 0);
				// role not authorised so redirect to home page
				if (!matchRole) return <Redirect to={{ pathname: "/" }} />;
			}

			// // check if route is restricted by role
			// if (roles && roles.indexOf(currentUser.role) === -1) {
			// 	// role not authorised so redirect to home page
			// 	return <Redirect to={{ pathname: "/" }} />;
			// }

			// authorised so return component
			return <Component {...props} />;
		}}
	/>
);
