import { handleResponse } from "utils/utils";
import { authService } from "services";

export const transactionsService = {
    getAll,
    getById,
    add,
    addMultiple,
    update,
    remove,
    exportList,
};

// gets all transactions
function getAll(employee_id, account_id, day_from, day_to, type, page, sort) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    if (page === undefined)
        page = 0;

    if (sort === undefined || sort === "")
        sort = "-day";

    let query = '?page=' + page + '&sort=' + sort + ',+employee_id,+account_id&page_size=10000'; // TEMPORAL

    if (employee_id !== undefined && employee_id > 0)
        query += "&employee_id=" + employee_id;

    if (day_from !== undefined && day_from !== null && day_from !== '')
        query += "&day_from=" + day_from;

    if (day_to !== undefined && day_to !== null && day_to !== '')
        query += "&day_to=" + day_to;

    if (account_id !== undefined && account_id > 0)
        query += "&account_id=" + account_id;

    if (type !== undefined && type !== '')
        query += "&type=" + type;

    return fetch(`${global.BASE_API}transactions${query}`, requestOptions).then(handleResponse);
}


// get transaction by id
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}transactions/${id}`, requestOptions).then(handleResponse);
}

// add new transaction
function add(transaction) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(transaction)
    };

    return fetch(`${global.BASE_API}transactions/`, requestOptions).then(handleResponse);
}

// add multple transactions
function addMultiple(transactions) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json',
            "X-Ninja-Multiple": true,
        },
        body: JSON.stringify(transactions)
    };

    return fetch(`${global.BASE_API}transactions/`, requestOptions).then(handleResponse);
}

// update transaction
function update(transaction) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(transaction)
    };

    return fetch(`${global.BASE_API}transactions/${transaction.id}`, requestOptions).then(handleResponse);
}

// remove transaction
function remove(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`
        },
    };

    return fetch(`${global.BASE_API}transactions/${id}`, requestOptions).then(handleResponse);
}

// exportar transacciones
function exportList(options) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authService.token.access_token}`,
        },
    };

    const { day_from, day_to, employee_id, account_id, type } = options;

    let query = '?page=0&sort=-day,+employee_id,+account_id';

    if (employee_id !== undefined)
        query += "&employee_id=" + employee_id;

    if (account_id !== undefined && account_id > 0)
        query += "&account_id=" + account_id;

    if (type !== undefined && type !== '')
        query += "&type=" + type;

    if (day_from !== undefined && day_from !== null && day_from !== '')
        query += "&day_from=" + day_from;

    if (day_to !== undefined && day_to !== null && day_to !== '')
        query += "&day_to=" + day_to;

    return fetch(`${global.BASE_API}transactions/export${query}`, requestOptions).then(handleResponse);
}