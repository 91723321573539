import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'; 
import { compose } from 'redux';
import {toastr} from 'react-redux-toastr';
import { Row, Col, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { IoMdColorFill } from "react-icons/io";
import { FiDelete } from "react-icons/fi";
import { TwitterPicker  } from 'react-color';
import { showModal, hideModal } from "actions/modalActions";
import Avatars  from 'components/Avatar/Avatars';
import { groupsService } from "services";
import { errorFormating } from "utils/utils";
import "./Groups.scss";

class GroupInfo extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		const group = this.props.group;
		if (group.calendar)
			group.calendar_id = group.calendar.id;

		this.state = {
			group,
			prevGroup: group,
			isPickerVisible: false,       
			errors: {},
			error_footer:'',
		};

		this.closeModal = this.closeModal.bind(this);
		this.openDeleteModal = this.openDeleteModal.bind(this);
	}

	closeModal() {
		this.props.hideModal()
	}

	openDeleteModal() {
		this.props.showModal({
		  open: true,
		  title: 'Eliminar grupo',
		  style: {width: '400px'},
		  message:'¿Desea eliminar el grupo? Esta acción no se puede deshacer',
		  deleteAction: this.confirmDelete,
		  closeModal: this.closeModal
		}, 'delete')
	}

	handleChange = event => {
		const { group } = this.state;

		this.setState({
		  prevGroup: group,
		  group:{
		    ...group,
		      [event.target.id]: event.target.value
		    }
		});
	}

	handleColorChange = ({hex}) =>{ 
		const { group } = this.state;

		if (hex=== undefined)
			hex = '';

		this.setState({
			group:{
				...group,
				color: hex
			},
			isPickerVisible: false,
		}, () => {
			this._saveGroup();
		});
	}  

	handleBlur  = async () => {
		const hasChanged = this.state.group !== this.state.prevGroup;

		if (hasChanged)
		  this._saveGroup();
	} 

	confirmDelete= async () =>  {
		const response = await groupsService.remove(this.state.group.id);
		this.closeModal();

		if (response.status === 200)
			this.props.history.goBack();
		else{
			let textError = errorFormating(response);

			this.setState({
				error_footer: textError.id
			});
		}
	} 

	_saveGroup  = async ()  =>  {
		const { group } = this.state;
		const response = await groupsService.update(group);

		if (response.ok){
		  toastr.success('¡Bien!', 'Cambios realizados correctamente');
		}

		this.setState({ 
		  prevGroup: group,
			errors:  errorFormating(response)
		});    
	} 

	checkValidField = (name) => {
		return (this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	getContent = () => {
	const { group, errors } = this.state;
	const { select_calendars } = this.props;

	const optionCalendarList = select_calendars.length > 0 && select_calendars.map((item, i) => {
		return (
			<option key={item.id} value={item.id}>{item.name}</option>
		)
	}, this);    

	 let empleados_count = "Ningún empleado asignado";
	 if (group.total_employees === 1)
	   empleados_count = "1 empleado";
	 else if (group.total_employees > 1)
	   empleados_count = group.total_employees + " empleados";	


	const onToggleColorPicker = () => { this.setState({ isPickerVisible: !this.state.isPickerVisible })		}

	return(
		<div className="tab-info-content">
	        <Row>
	            <Col sm={6} md={4}>
					<FormGroup controlId="name">
						<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={group.name}
							onChange={this.handleChange}
							onBlur={ this.handleBlur }
							placeholder="Nombre grupo"
							isInvalid = {this.checkValidField('name')}
							/>
						<FormControl.Feedback type="invalid">
							{errors.name}
						</FormControl.Feedback>		                          
					</FormGroup>

					<FormGroup controlId="calendar_id">
						<FormLabel>Calendario</FormLabel>
						<FormControl
							type="text"
							value={group.calendar_id}
							onChange={this.handleChange}
							onBlur={ this.handleBlur }
							isInvalid = {this.checkValidField('calendar_id')}
							as="select">
							<option value="">Selecciona calendario</option>
							{optionCalendarList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{errors.calendar_id}
						</FormControl.Feedback>                  
					</FormGroup>   

					<FormGroup controlId="color" className="form-color">
						<div className="flex-between">
							<FormLabel>Color</FormLabel>
							<FiDelete className={`icon-remove-color ${!group.color && 'hidden'} `} onClick={ () => this.handleColorChange("") } />
						</div>
						<FormControl
							type="text"
							value={group.color}
							placeholder= "Color"
							readOnly
							isInvalid = {this.checkValidField('color')}
							style={{ backgroundColor: group.color && group.color}}
							onClick={ onToggleColorPicker }
							/>
						<FormControl.Feedback type="invalid">
							{this.state.errors.color}
						</FormControl.Feedback>       

						<IoMdColorFill onClick={ onToggleColorPicker } />     

						{ this.state.isPickerVisible && (
							<div style={{ position: 'absolute' }}>
							<TwitterPicker
								color={group.color ? group.color : '#ffffff'}
								onChangeComplete={ (hex) => this.handleColorChange(hex) }
							/>
							</div>
						) }
					</FormGroup>  		          

	            </Col>

	            <Col sm={6} md={8} >
	            	<h3 className="subtitle">Empleados del grupo</h3>

	            	<div className="avatar-container">
		               	{ group.total_employees > 0 &&
			              <Avatars employees={group.employees} /> 
			            }
			            {empleados_count}
		            </div>
	            </Col>
	          </Row>

	            { this.state.error_footer && 
	              <div className="alert alert-danger alert-dismissible fade show mt15" role="alert">
	                {this.state.error_footer}
	              </div>
	            }                  

	            <div className="row-buttons">
	            	<button type="button" className="btn btn-danger" onClick={this.openDeleteModal} >Eliminar grupo</button>
	            </div>
	          </div>
		);
	}
  render() {
    return(
	    <>
	        { this.getContent() }
	    </>
    )
  }
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.calendarsReducer,
		...reducers.groupsReducer,
	}
};


const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
})


export default compose(withRouter,connect(mapStateToProps, mapDispatchToProps))(GroupInfo);
