import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { accountsService, timesService, transactionsService } from "services";
import { getWorkedTimeRaw } from "utils/datetime";
import Loading from 'components/Loading';
import "components/Dashboard/Dashboard.scss";

class Dashboard_WeekTimeRegistrations extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		}
	}

	componentDidMount() {
		this.getTimeData();
	}

	async getTimeData() {
		const employee_id = this.props.user.id;
		const account_objetivo = await accountsService.getAll("Horas objetivo"); // guarrofix
		const account_objetivo_id = (account_objetivo.data.length > 0) ? account_objetivo.data[0].id : -1;

		const today = new Date();
		// const today = new Date("2020-08-12"); // test day
		const startOfWeek = moment(today).startOf('week').format('YYYY-MM-DD');
		const endOfWeek = moment(today).endOf('week').format('YYYY-MM-DD');

		const worked_resp = await timesService.getAll({ employee_id, start_from: startOfWeek, start_to: endOfWeek });
		const expected_resp = await transactionsService.getAll(employee_id, account_objetivo_id, startOfWeek, endOfWeek, "", 0, "+day");

		// console.log(worked_resp);
		// console.log(expected_resp);

		const week_days = [];
		const hours_worked = [];
		const hours_expected = [];

		for (var i = 0; i <= 6; i++) {
			// day array
			var d = moment(startOfWeek).add(i, "days");
			week_days.push(d);

			// worked hours array
			const value_worked = getWorkedTimeRaw({ times: worked_resp.data, date: d, unit: "minutes" });
			hours_worked.push(value_worked / 60);

			//expected hours array
			const value_expected = expected_resp.data.find(time => moment(time.day).format("DD/MM/YYYY") === moment(d).format("DD/MM/YYYY")) || 0;
			hours_expected.push(value_expected.value || 0);
		}

		// console.log(week_days);
		// console.log(hours_worked);
		// console.log(hours_expected);


		this.setState({
			loading: false,
			hours_worked,
			hours_expected,
			series: [
				{
					name: 'Horas trabajadas',
					data: hours_worked
				},
				{
					name: 'Horas objetivo',
					data: hours_expected
				},
			],
			options: {
				chart: {
					id: 'week_chart',
					toolbar: {
						show: false
					}
				},
				xaxis: {
					categories: week_days,
					labels: {
						// format:'dddd'
						formatter: function (date) {
							const formatted_day = moment(date).format('ddd');
							const formatted_num = moment(date).format('DD');
							return formatted_day + " " + formatted_num;
						}
					}
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							return parseInt(value) + "h";
						}
					}
				},
				colors: ['#5b49fe', '#a9a0fa'],
				dataLabels: {
					enabled: false
				},
			},
		});
	}


	render() {
		const { loading } = this.state;

		if (loading) {
			return (
				<div className="empty">
					<Loading />
				</div>
			)
		}

		return (
			<Chart id="week_chart" options={this.state.options} series={this.state.series} type="bar" height={300} />
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.timesReducer,
		user: reducers.authReducer.user,
	}
};

export default connect(mapStateToProps, null)(Dashboard_WeekTimeRegistrations);