import React from 'react';
import "./Shifts.scss";

class ShiftPreviewTooltip extends React.Component {

	// constructor(props) {
	// 	super(props);
	// 	console.log(props);
	// }

	getSelectedDayTypes = () => this.props.shift.day_types.map((item) => {
		return (
			<li key={item.id} aria-label={item.description} title={item.description} className="tag-default tag-comma" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
			</li>
		)
	});

	getSelectedGroups = () => this.props.shift.groups.map((item) => {
		return (
			<li key={item.id} aria-label={item.description} title={item.description} className="tag-default tag-comma" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
			</li>
		)
	});

	getTimeIntervalsBody = (time_intervals) => time_intervals.map((interval, i) => {
		return (
			<tr key={i}>
				<td>{interval.time_type.name}</td>
				<td>{interval.start}</td>
				<td>{interval.end}</td>
			</tr>
		)
	});

	render() {
		var { shift, time_intervals } = this.props;
		const hasGroups = shift.has_groups;
		const allowHourly = shift.allow_hourly_absence_request;

		if (time_intervals && time_intervals.length < 1)
			time_intervals = shift.time_intervals;

		return (
			<div className="shift-preview">
				<div className="heading">
					<h2>[{shift.name}] {shift.description}</h2>
					<span className="shift-type">{shift.type}</span>
				</div>

				{(shift.type === "Ausencia" || shift.type === "Vacaciones") &&
					<p><strong>{allowHourly ? "SI" : "NO"}</strong> permite peticiones de <span className="lowercase">{shift.type}</span> por horas</p>
				}

				{this.props.shift.day_types && (
					<>
						<h3 className="subtitle">Tipos de día a los que aplica</h3>

						<ul className="list-tags">
							{this.getSelectedDayTypes()}
						</ul>
					</>)
				}

				{(shift.type === "Trabajo" || allowHourly) && time_intervals && time_intervals.length > 0 && (<>
					<h3 className="subtitle">Periodos de tiempo</h3>

					<table className="table table-condensed table-zebra-reverse table-specialdays">
						<thead>
							<tr>
								<th>Tipo</th>
								<th>Inicio</th>
								<th>Fin</th>
							</tr>
						</thead>
						<tbody>
							{this.getTimeIntervalsBody(time_intervals)}
						</tbody>
					</table></>)
				}

				{
					hasGroups && (
						<>
							<h3 className="subtitle">Grupos a los que aplica</h3>
							<ul className="list-tags">
								{this.getSelectedGroups()}
							</ul>
						</>
					)
				}

			</div >
		)
	}
}


// const mapStateToProps = (reducers) => {
// 	return {
// 		...reducers.calendarsReducer,
// 		...reducers.timesReducer,
// 		...reducers.shiftsReducer,
// 		...reducers.groupsReducer,
// 		loading: reducers.shiftsReducer.loading
// 	}
// };

// export default connect(mapStateToProps, shiftsActions)(ShiftPreviewTooltip)
export default ShiftPreviewTooltip
