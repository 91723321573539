import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import EmployeeSeparatedRestEdit from "./EmployeeSeparatedRestEditModal";
import { showModal, hideModal } from "actions/modalActions";
import * as employeesActions from 'actions/employeesActions';
import { employeesService } from "services";
import { errorFormating } from "utils/utils";

import "./Employees.scss";

class EmployeeMagicPlan extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewEmployeeSeparatedRestModal = this.openNewEmployeeSeparatedRestModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.getSeparatedRestEmployees = this.getSeparatedRestEmployees.bind(this);
	}

	async componentDidMount() {
		await Promise.all([
			this.getSeparatedRestEmployees(),
			this.props.getSelectableEmployees(),
		]);
	}

	async getSeparatedRestEmployees() {
		const employeeId = this.props.employee.id;
		await this.props.getEmployeeSeparatedRestEmployees(employeeId);
	}

	closeModal() {
		this.props.hideModal()
	}

	openNewEmployeeSeparatedRestModal() {
		const { employee } = this.props;

		this.props.showModal({
			open: true,
			title: 'Nueva asignación con empleado',
			style: { width: '350px' },
			content: <EmployeeSeparatedRestEdit handleClose={this.closeModal} getSeparatedRestEmployees={this.getSeparatedRestEmployees} employee={employee} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteEmployeeSeparatedRestModal(employee_id) {
		this.props.showModal({
			open: true,
			title: 'Eliminar asignación',
			style: { width: '350px' },
			message: '¿Desea eliminar la asignación con el empleado? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(employee_id),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
			open: true,
			title: "Error al eliminar",
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (employee_id) => {
		const response = await employeesService.removeSeparatedRestsEmployee(this.props.employee.id, employee_id);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else {
			this.getSeparatedRestEmployees();
			this.closeModal();
		}
	}

	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="2" className="no-result">
					Todavía no hay ningún empleado seleccionado
				</td>
			</tr>
		);
	}

	getRows = () => this.props.employee_separated_rest_employees.map((employee_separated) => {

		return (
			<tr key={employee_separated.id}>
				<td>
					{employee_separated.name} {employee_separated.surname}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openDeleteEmployeeSeparatedRestModal(employee_separated.id)}>Eliminar</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	getRestriccionesContent = () => {
		const { employee_separated_rest_employees } = this.props;

		return (
			<>
				<div className="row-adding">
					<p>Evitar solapamiento de descansos con los siguientes empleados:</p>

					<table className="table table-separated-rests table-zebra-reverse">
						<thead>
							<tr>
								<th>Empleado</th>
								<th className="td-actions"><button type="button" title="Asignar empleado" onClick={this.openNewEmployeeSeparatedRestModal} className="btn btn-new"><FiPlus /></button></th>
							</tr>
						</thead>
						<tbody>
							{employee_separated_rest_employees.length === 0 ?
								this.getEmptyRow()
								:
								this.getRows()
							}
						</tbody>
					</table>
				</div>
			</>
		);
	}
	render() {
		return (
			<>
				<h3 className="title">Restricciones de descansos</h3>
				{this.getRestriccionesContent()}
			</>
		)
	}
}


const mapStateToProps = state => {
	return {
		employee_separated_rest_employees: state.employeesReducer.employee_separated_rest_employees,
		user: state.authReducer.user,
	}
}

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getEmployeeSeparatedRestEmployees: (id) => dispatch(employeesActions.getEmployeeSeparatedRestEmployees(id)),
	getSelectableEmployees: (options) => dispatch(employeesActions.getSelectableEmployees(options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMagicPlan);