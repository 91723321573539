import React, { Component } from "react";
import {  FormGroup,  FormControl,  FormLabel, FormCheck} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SubmitButton from "components/SubmitButton";
import * as userActions from 'actions/userActions';
import { errorFormating, isLogin } from "utils/utils";
import "./styles/Auth.scss";
import logo from '../assets/logo.svg';

class Register extends Component {
  constructor(props) {
    super(props);
	
    this.state = {
      isLoading: false,
      submitted: false,
      errors:{},
      accepted: false,
      user: {
      	  name: "",
	      surname: "",
	      company: "",
	      email: "",
	      password: "",
	      confirmPassword: "",
      }
    };
  }

  componentDidMount(){
    isLogin();
  } 

  validateForm() {
  	const { user,accepted } = this.state;
    return (
      user.password.length > 5 &&
      user.password === user.confirmPassword &&
      accepted
    );
  }

  handleChange = event => {
  	const { user } = this.state;

    this.setState({
    	user:{
    		...user,
      		[event.target.id]: event.target.value
      	}
    });
  }

  checkValidField = (name) => {
	return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }  

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    const { user } = this.state;

	await this.props.register(user);

    if (this.props.loggedIn){

		if ("ga" in window) {
			var tracker = window.ga.getAll()[0];
			if (tracker)
				tracker.send("event", "signup", "submit");
		}


      	 this.props.history.push("/");
      	 isLogin();
    }
    else{
      this.setState({ 
        submitted: true, 
        isLoading: false,
        errors: errorFormating(this.props)
      });
    }

  }  

  render() {
  	const { user, accepted } = this.state;
  	const label = ( <div> <span>Acepto las </span> <a href='https://plain.ninja/condiciones-de-uso/' target='_blank' rel='noopener noreferrer'>condiciones de uso</a> <span> y la </span> <a href='https://plain.ninja/politica-privacidad/' rel='noopener noreferrer' target='_blank'>política de privacidad</a> </div> )

	return (
		<div className="Auth">
			<Link to="/">
		  		<img className="logo" src={logo} alt="Plain" />
		  	</Link>

			<form onSubmit={this.handleSubmit}>
				<FormGroup controlId="name">
					<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
					<FormControl
					autoFocus
					type="text"
					value={user.name}
					placeholder="Indica tu nombre"
					onChange={this.handleChange}
					isInvalid = {this.checkValidField('name')}
					/>
					<FormControl.Feedback type="invalid">
						{this.state.errors.name}
					</FormControl.Feedback>								
				</FormGroup>
				<FormGroup controlId="surname">
					<FormLabel>Apellidos <span className="label-required">*</span></FormLabel>
					<FormControl
					type="text"
					value={user.surname}
					placeholder="Indica tus apellidos"
					onChange={this.handleChange}
					isInvalid = {this.checkValidField('surname')}
					/>
					<FormControl.Feedback type="invalid">
						{this.state.errors.surname}
					</FormControl.Feedback>						
				</FormGroup>
				<FormGroup controlId="company">
					<FormLabel>Empresa <span className="label-required">*</span></FormLabel>
					<FormControl
					type="company"
					value={user.company}
					placeholder="Tu empresa (o tu nombre si eres autónomo)"
					onChange={this.handleChange}
					isInvalid = {this.checkValidField('company')}
					/>
					<FormControl.Feedback type="invalid">
						{this.state.errors.company}
					</FormControl.Feedback>						
				</FormGroup>
				<FormGroup controlId="email">
					<FormLabel>Email <span className="label-required">*</span></FormLabel>
					<FormControl
					type="email"
					placeholder="Indica tu e-mail"
					value={user.email}
					onChange={this.handleChange}
					isInvalid = {this.checkValidField('email')}
					/>
					<FormControl.Feedback type="invalid">
						{this.state.errors.email}
					</FormControl.Feedback>						
				</FormGroup>									
				<FormGroup controlId="password">
					<FormLabel>Contraseña <span className="label-required">*</span></FormLabel>
					<FormControl
					value={user.password}
					onChange={this.handleChange}
					placeholder="Contraseña"
					type="password"
					isInvalid = {this.checkValidField('password')}
					/>
					<FormControl.Feedback type="invalid">
						{this.state.errors.password}
					</FormControl.Feedback>						
				</FormGroup>
				<FormGroup controlId="confirmPassword">
					<FormLabel>Confirmar contraseña <span className="label-required">*</span></FormLabel>
					<FormControl
					value={user.confirmPassword}
					onChange={this.handleChange}
					placeholder="Repetir contraseña"
					type="password"
					isInvalid = {this.checkValidField('confirmPassword')} 
					/>
					<FormControl.Feedback type="invalid">
						{this.state.errors.confirmPassword}
					</FormControl.Feedback>						
				</FormGroup>

				<FormGroup controlId="acceptCheck">
					<FormCheck 
						type="checkbox" 
						label={label} 
						value={accepted}
						onChange={(e)=>{
							 this.setState({
						    	accepted: e.target.checked
						    });
						}}
					/>
				</FormGroup>

				<SubmitButton
					block
					disabled={!this.validateForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="Empezar ahora"
					loadingText="Registrando..."
				/>


			</form>
			<p className="login-register">
				¿Ya tienes cuenta? <Link to="/login">Inicia sesión</Link>
			</p>
		</div>
	);
  }
}

const mapStateToProps = (reducers) => {
	return reducers.authReducer;
};

export default connect(mapStateToProps, userActions)(Register);
// export default Register;