import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiMoreHorizontal } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import StaffingLevelEditModal  from 'components/StaffingLevels/StaffingLevelEditModal';
import { showModal, hideModal } from "actions/modalActions";
import * as groupsActions from 'actions/groupsActions';
import { staffingLevelsService } from "services";
import { errorFormating } from "utils/utils";

class StaffingLevelList extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.closeModal = this.closeModal.bind(this);
    this.openEditStaffingLevelModal = this.openEditStaffingLevelModal.bind(this);
    this.openDeleteStaffingLevelModal = this.openDeleteStaffingLevelModal.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);
  }

  closeModal() {
    this.props.hideModal()
  }

  openEditStaffingLevelModal(staffing_level) {
    this.props.showModal({
      open: true,
      title: 'Editar necesidades de personal',
      style: {width: '800px'},
      content: <StaffingLevelEditModal staffing_level={staffing_level}  handleClose={this.closeModal}  />,
      closeModal: this.closeModal
    }, 'edit')
  }

  openDeleteStaffingLevelModal(idStaffingLevel) {
    this.props.showModal({
      open: true,
      title: 'Eliminar necesidades de personal',
      message:'¿Desea eliminar las necesidades de personal? Esta acción no se puede deshacer',
      deleteAction: () => this.confirmDelete(idStaffingLevel),
      closeModal: this.closeModal
    }, 'delete')
  }

  openAlertModal(message) {
   this.props.showModal({
     open: true,
     title: "Error al eliminar",
     style: {width: '400px'},
     message: message,
     closeModal: this.closeModal
   }, 'alert')
  } 	

	confirmDelete= async(idStaffingLevel) =>  {
		const response = await staffingLevelsService.remove(idStaffingLevel);

		if (!response.ok){
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else{
			this.props.getAllStaffingLevels();
			this.closeModal();
		}
	}


	getEmptyRow = () => {
    return(
      <tr>
        <td colSpan="3" className="no-result">
          Sin resultados
        </td>
      </tr>
    );
	} 	

  getRows = () =>  this.props.staffing_levels.map((staffing_level)  => {

    return (
      <tr key={ staffing_level.id }>
        <td> 
            <button className="btn-transparent btn-editable" onClick={()=> this.openEditStaffingLevelModal(staffing_level)}>{ staffing_level.name }</button>      
        </td>
        <td>{ staffing_level.description }</td>        
        <td className="td-actions">
          <Dropdown>
              <Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
              <Dropdown.Menu alignRight>
                <Dropdown.Item as="button" onClick={()=> this.openEditStaffingLevelModal(staffing_level)}>Editar</Dropdown.Item>
                <Dropdown.Item as="button" onClick={()=> this.openDeleteStaffingLevelModal(staffing_level.id)}>Eliminar</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )
  });  

	render() {
    return(
      <table className="table table-staffing-levels table-zebra">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.staffing_levels.length === 0 ?
            this.getEmptyRow() 
          :
            this.getRows() 
          }
        </tbody>
      </table>
    )
	}
}


const mapStateToProps = (reducers) => {
	return reducers.groupsReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAllStaffingLevels: (q) => dispatch(groupsActions.getAllStaffingLevels(q)),
})


export default connect(mapStateToProps, mapDispatchToProps)(StaffingLevelList)