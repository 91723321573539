import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Chart from 'react-apexcharts';
import { FaMagic } from "react-icons/fa";
import { showModal, hideModal } from "actions/modalActions";
import * as shiftsActions from "actions/shiftsActions";
import Layout from "components/Layout";
import Loading from "components/Loading";
import SubmitButton from "components/SubmitButton";
import PlanMagicModal from 'components/Plans/PlanMagicModal';
import { groupsService, plansService } from "services";
import Modal from "components/Modal";
import "components/Plans/Plans.scss";

class PlanMagic extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
		// console.log(props.location.state.detail);

		this.closeModal = this.closeModal.bind(this);
		this.openNewMagicPlan = this.openNewMagicPlan.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		const group_id = parseInt(this.props.match.params.group);

		this.state = {
			loading: true,
			saving: false,
			group_id,
			schedule_data: [],
			required_data: [],
			// pending_data: [],
			result_plans: [],
			start: this.props.match.params.start,
			end: this.props.match.params.end,
		};
	}

	async componentDidMount() {
		this.getChartData();
		this.getGroupData();

		// work shifts for magic modal
		this.props.getAttendanceShifts();
	}

	saveMagicPlan = async () => {
		const { result_plans, group_id, start, end } = this.state;
		const employee_ids = [];

		this.setState({ saving: true });

		result_plans.map((plan) => {
			if (!employee_ids.includes(plan.employee_id)) {
				employee_ids.push(plan.employee_id);
			}
		});

		const plans_to_save = {
			start,
			end,
			employee_ids,
			plans: result_plans
		}

		const response = await plansService.updateIntelligent(plans_to_save);
		// console.log(response);
		var hasErrors = false;
		var textError = "Se han encontrado los siguientes errores: \r\n";
		response.map((item) => {
			if (item.errors !== undefined) {
				hasErrors = true;
				item.errors.map((error) => {
					// console.log(error);
					textError += "- " + error.description + "\r\n";
				})
			}
		});

		// tornar a la planificació si OK
		// mostrar errors si KO
		this.setState({
			saving: false,
			// submitted: true,
		}, () => {
			if (!hasErrors) {
				this.props.history.push("/plans/" + group_id + "/" + start + "@" + end);
			} else {
				this.openAlertModal(
					textError,
					"Error al guardar"
				);
			}
		});
	}

	openAlertModal(message, title) {
		this.props.showModal(
			{
				open: true,
				title: title || "Ha habido un error",
				style: { width: "400px" },
				message: message,
				closeModal: this.closeModal,
			},
			"alert"
		);
	}

	async getGroupData() {
		const { group_id } = this.state;
		const group = await groupsService.getById(group_id);
		this.setState({
			group,
		});
	}

	// TODO borrar
	// getScheduleDataTest(schedule_raw) {
	// 	const schedule = [];

	// 	schedule_raw.map((shift_function, i) => {
	// 		const values = [];
	// 		shift_function.values.map((item, i) => {
	// 			const value = [moment(item.date).valueOf(), item.employees.length];
	// 			values.push(value);
	// 			return true;
	// 		});

	// 		schedule.push({
	// 			name: shift_function.name,
	// 			data: values
	// 		})
	// 		return true;
	// 	});

	// 	return schedule;
	// }

	getScheduleData(required_data, pending_data) {
		const schedule = [];

		required_data.map((shift_function, i) => {
			const values = [];

			shift_function.data.map((item, j) => {
				const date = item[0];
				const required_value = item[1];
				const pending_value = pending_data[i].data[j][1];

				const value = [date, required_value - pending_value];
				values.push(value);
				return true;
			});

			schedule.push({
				name: shift_function.name,
				data: values
			})
			return true;
		});

		return schedule;
	}
	getListData(list_raw) {
		const list_needs = [];

		list_raw.map((shift_function, i) => {
			const values = [];
			shift_function.values.map((item, i) => {
				const value = [moment(item.date).valueOf(), item.count];
				values.push(value);
				return true;
			});

			list_needs.push({
				name: shift_function.name,
				data: values
			})
			return true;
		});

		return list_needs;
	}

	async getChartData() {
		const magic_data = this.props.location.state.detail;

		const required_data = this.getListData(magic_data.required_needs);
		const pending_data = this.getListData(magic_data.pending_needs);
		// const schedule_data_test = this.getScheduleDataTest(magic_data.schedule);
		const schedule_data = this.getScheduleData(required_data, pending_data);

		const result_plans = magic_data.result_plans;

		// console.log(schedule_data);
		// console.log(required_data);
		// console.log(pending_data);
		// console.log(result_plans);

		this.setState({
			loading: false,
			schedule_data,
			required_data,
			// pending_data,
			result_plans,
			options: {
				chart: {
					type: 'area',
					height: 400,
					// stacked: true,
					// events: {
					// 	selection: function (chart, e) {
					// 		console.log(new Date(e.xaxis.min))
					// 	}
					// },
				},
				colors: ['#CED4DC', '#ff2280'], //#CED4DC //#5b49fe // ff2280
				dataLabels: {
					enabled: true
				},
				stroke: {
					curve: 'straight' // smooth
				},
				fill: {
					type: 'gradient',
					gradient: {
						opacityFrom: 0.5,
						opacityTo: 1,
					}
				},
				legend: {
					position: 'top',
					horizontalAlign: 'left'
				},
				xaxis: {
					// type: 'datetime',
					labels: {
						formatter: function (value, timestamp) {
							return moment(value).format("DD/MM");
						},
					}
				},
			},
		});
	}

	closeModal() {
		this.props.hideModal();
	}

	openNewMagicPlan() {
		this.props.showModal({
			open: true,
			title: 'Planificación automática',
			style: { width: '860px' },
			content: <PlanMagicModal handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	getCharts = () => this.state.schedule_data.map((schedule, i) => {
		const { required_data } = this.state;
		const name_split = schedule.name.split("\x1f");
		const ubicacion = (name_split[2] === undefined || name_split[2] === null) ? "" : " (" + name_split[2] + ")";

		const series = [

			{
				name: "Necesidades de personal",
				data: required_data[i].data
			},
			{
				name: "Planificación propuesta",
				data: schedule.data
			},
		];
		return (
			<div className="chart-container" key={i}>
				<h3 className="title-chart">Turno {name_split[0]}, {name_split[1]}{ubicacion}</h3>
				<Chart id={"schedule_" + i} options={this.state.options} series={series} type="area" height={350} />
			</div>
		)
	});

	getContent = () => {
		const { result_plans } = this.state;
		if (result_plans.length === 0) {
			return <div className="no-results">No se han encontrado resultados</div>
		}
		return (
			<>
				{this.getCharts()}
				<SubmitButton
					type="submit"
					isLoading={this.state.saving}
					text="Guardar planificación"
					onClick={this.saveMagicPlan}
					className="btn-guardar btn-guardar-footer"
					loadingText="Guardando..."
				/>
			</>
		)
	};

	// ************* RENDER *********************

	render() {
		// const { user } = this.props;
		const {
			loading,
			start,
			end,
			group,
			result_plans,
		} = this.state;


		const start_month = moment(start).format("MMMM");
		const end_month = moment(end).format("MMMM");

		var start_format = moment(start).format("D");
		const end_format = moment(end).format("D") + " de " + end_month;

		const start_year = moment(start).format("YYYY");
		const end_year = moment(end).format("YYYY");

		if (start_month !== end_month)
			start_format = start_format + " de " + start_month;

		if (start_year !== end_year)
			start_format = start_format + " de " + start_year;

		return (
			<>
				<Layout className="page-plan page-magic-plan">
					<div className="heading">
						<div className="heading-left">
							<h1 className="title">Planificación automática {group && group.name}</h1>
						</div>
						<div className="heading-filters"></div>
						<div className="heading-actions">
							{result_plans.length > 0 &&
								<SubmitButton
									type="submit"
									isLoading={this.state.saving}
									onClick={this.saveMagicPlan}
									text="Guardar planificación"
									className="btn-guardar"
									loadingText="Guardando..."
								/>
							}
							<button
								type="button"
								title="Planificación automática"
								onClick={this.openNewMagicPlan}
								className="btn btn-outline-primary btn-wand"
							>
								Planificación automática

								<FaMagic className="icon-wand" />
							</button>
						</div>
					</div>
					<div className="heading">
						<div className="heading-left">
							<p className="plan-periodo">
								Periodo del {start_format} al {end_format} de {end_year}
							</p>
						</div>
					</div>
					{loading ?
						<div className="empty"><Loading /></div> :
						this.getContent()
					}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		user: reducers.authReducer.user,
		loading: reducers.plansReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getAttendanceShifts: () => dispatch(shiftsActions.getAttendanceShifts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanMagic);
