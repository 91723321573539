import React from 'react';
import { employeesService } from "services";
import "./Avatar.scss";

class Avatar extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      pic: ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps)
      this.getImage();
  }

  // UNSAFE_componentWillMount() {
  //   this._isMounted = false;
  //   this.setState({
  //     pic: '',
  //   });
  // }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getImage() {
    const localStorageData = localStorage.getItem(this.props.userId);
    var img = "";

    if (localStorageData !== null) {
      img = JSON.parse(localStorageData);
    }
    else {
      img = await employeesService.getAvatar(this.props.userId);
      localStorage.setItem(this.props.userId, JSON.stringify(img));
    }

    if (this._isMounted) {
      this.setState({
        pic: img,
      });
    }
  }

  render() {
    var alt = "Avatar";
    if (this.props.alt !== undefined)
      alt = this.props.alt;

    var title = alt;
    if (this.props.title !== undefined)
      title = this.props.title;

    return (
      <img onClick={this.props.onClick} src={this.state.pic} className={`avatar ${this.props.className}`} alt={alt} title={title} />
    )
  }
}


export default Avatar;