import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import "./Employees.scss";
import * as employeesActions from 'actions/employeesActions';

class EmployeeFunctionEdit extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props);

    let employee_function = {
      function_id: 0,
      is_default: true,
    }

    let isEdit = false;

    if (props.employee_function) {
      employee_function = {
        ...props.employee_function,
        function_id: props.employee_function.function.id
      };
      isEdit = true;
    }

    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      employee_function
    };
  }

  handleSwitch = event => {
    const { employee_function } = this.state;

    this.setState({
      employee_function: {
        ...employee_function,
        [event.target.id]: event.target.checked
      },
    });
  }

  handleChange = event => {
    const { employee_function } = this.state;

    this.setState({
      employee_function: {
        ...employee_function,
        [event.target.id]: event.target.value
      },
    });
  }

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let response = '';

    const props = this.props;
    const { employee_function } = this.state;

    // if edit => update; else => new    
    if (this.state.isEdit) {
      response = await employeesService.updateFunction(props.employee.id, employee_function);
    }
    else {
      response = await employeesService.addFunction(props.employee.id, employee_function);
    }

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        this.props.getEmployeeFunctions(props.employee.id);
        props.handleClose();

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  render() {
    const { functions } = this.props;
    const { employee_function } = this.state;

    var isDefault = employee_function.is_default;

    const optionList = functions.length > 0 && functions.map((item, i) => {
      return (
        <option key={i} value={item.id}>{item.name}</option>
      )
    }, this);

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <FormGroup controlId="function_id">
            <FormLabel>Función</FormLabel>
            <FormControl
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={employee_function.function_id}
              isInvalid={this.checkValidField('function_id')}
              as="select">
              <option value="-1">Seleccionar función</option>
              {optionList}
            </FormControl>

            <FormControl.Feedback type="invalid">
              {this.state.errors.function_id}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup className="form-group-flex mb0" controlId="is_default">
            <FormLabel>Defecto</FormLabel>
            <Switch
              controlId="is_default"
              isOn={isDefault}
              handleToggle={this.handleSwitch}
            />

            <FormControl.Feedback type="invalid">
              {this.state.errors.is_default}
            </FormControl.Feedback>
          </FormGroup>

        </div>

        <div className="modal-footer">
          <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
          <SubmitButton
            type="submit"
            isLoading={this.state.isLoading}
            text="Guardar"
            loadingText="Guardando..."
          />
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state, myProps) => {
  return {
    ...state.employeesReducer,
    functions: state.functionsReducer.functions,
    employee: myProps.employee,
  }
};

export default connect(mapStateToProps, employeesActions)(EmployeeFunctionEdit);