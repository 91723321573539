import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import InputNumber from 'rc-input-number';
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { FiX, FiInfo } from "react-icons/fi";
import { FaMapMarkerAlt } from "react-icons/fa";
import Switch from "components/Switch";
import { staffingLevelsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import "./StaffingLevels.scss";
import * as groupsActions from 'actions/groupsActions';

class StaffingLevelEdit extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		let staffing_level = {
			name: '',
			description: '',
			needs: [],
			has_ubications: false,
		}

		var isEdit = false;
		const selected_functions = [];
		const selected_shifts = [];
		const selected_ubications = [];

		if (props.staffing_level) {
			isEdit = true;
			staffing_level = props.staffing_level;

			staffing_level.needs.map(need => {

				const existFunction = selected_functions.find(item => item.id === need.function.id);

				if (!existFunction)
					selected_functions.push(need.function);

				const existShift = selected_shifts.find(item => item.id === need.shift.id);

				if (!existShift)
					selected_shifts.push(need.shift);

				if (need.ubication) {
					const existUbication = selected_ubications.find(item => item.id === need.ubication.id);
					if (!existUbication)
						selected_ubications.push(need.ubication);
				}

				return true;
			});
		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			selected_functions,
			selected_shifts,
			selected_ubications,
			errors: {},
			new_item_errors: {},
			temp_function: {},
			temp_shift: {},
			temp_ubication: {},
			staffing_level,
			need_list: []
		};
	}

	componentDidMount() {
		this.updateNeedList();
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	prepareNeedListToSubmit = () => {
		const { staffing_level } = this.state;
		const curatedList = [];

		staffing_level.needs.map(function (need) {
			if (need.counter > 0) {
				const fake_need = {
					counter: need.counter,
					day_type_id: need.day_type.id,
					function_id: need.function.id,
					shift_id: need.shift.id,
				}

				if (need.ubication) {
					fake_need.ubication_id = need.ubication.id;
				}

				curatedList.push(fake_need);
			}

			return true;
		});

		return curatedList;
	}

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });

		const { staffing_level, isEdit } = this.state;
		let response = '';
		const props = this.props;

		const staffing_level_temp = {
			name: staffing_level.name,
			description: staffing_level.description,
			needs: this.prepareNeedListToSubmit(),
			has_ubications: staffing_level.has_ubications,
		}

		//if edit => update; else => new
		if (isEdit) {
			staffing_level_temp.id = staffing_level.id;
			response = await staffingLevelsService.update(staffing_level_temp);
		}
		else {
			response = await staffingLevelsService.add(staffing_level_temp);
		}

		if (response.ok) {
			this.setState({
				isLoading: false,
				staffing_level: response,
				errors: {},
			}, () => {
				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.getAllStaffingLevels();
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}
	}

	handleChange = event => {
		const { staffing_level } = this.state;

		this.setState({
			staffing_level: {
				...staffing_level,
				[event.target.id]: event.target.value
			},
		});
	}

	handleSwitch = (event) => {
		const { staffing_level } = this.state;

		this.setState({
			staffing_level: {
				...staffing_level,
				[event.target.id]: event.target.checked,
			},
		});
	};

	handleAddChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	handleChangeCount = (value, need, day_type) => {
		if (!isNaN(value)) {
			const { staffing_level } = this.state;
			const updatedList = this.updateCountNeedList(need, day_type.id, value);

			this.setState({
				staffing_level: {
					...staffing_level,
					needs: updatedList
				},
			});
		}
	}

	updateCountNeedList = (need, dayTypeId, counter) => {
		const { staffing_level } = this.state;

		const updatedList = staffing_level.needs.map(function (item) {
			if (item.function.id === parseInt(need.function.id) && item.shift.id === parseInt(need.shift.id) && item.day_type.id === parseInt(dayTypeId)) {

				if (staffing_level.has_ubications) {
					if (need.ubication && item.ubication && item.ubication.id === parseInt(need.ubication.id)) {
						item.counter = counter;
					}
				} else {
					item.counter = counter;
				}
			}
			return item;
		});
		return updatedList;
	}

	checkCountNeedList = (functionId, shiftId, ubicationId, dayTypeId) => {
		// console.log(functionId);
		// console.log(shiftId);
		// console.log({ ubicationId });
		// console.log(dayTypeId);
		const { staffing_level } = this.state;
		const filtered = staffing_level.needs.filter(function (need) {
			if (staffing_level.has_ubications && ubicationId !== null) {
				return need.ubication && need.ubication.id === parseInt(ubicationId) && need.function.id === parseInt(functionId) && need.shift.id === parseInt(shiftId) && need.day_type.id === parseInt(dayTypeId);
			}
			else {
				return need.function.id === parseInt(functionId) && need.shift.id === parseInt(shiftId) && need.day_type.id === parseInt(dayTypeId);
			}
		});

		let counter = 0;

		if (filtered[0] !== undefined && filtered[0].counter !== undefined)
			counter = filtered[0].counter;

		return counter;
	}

	updateNeedList = () => {
		const { staffing_level, selected_functions, selected_shifts, selected_ubications } = this.state;
		const { select_day_types } = this.props;
		const need_list_full = [];
		const need_list = [];

		//console.log(select_day_types);

		if (selected_functions.length > 0 && selected_shifts.length > 0) {

			if (staffing_level.has_ubications && selected_ubications.length > 0) {
				// functions iteration
				selected_functions.map(fn => {
					// shifts iteration
					selected_shifts.map(shift => {
						// ubications iteration
						selected_ubications.map(ubication => {
							const need_item = {
								function: fn,
								shift,
								ubication,
							}
							need_list.push(need_item);

							// day types iteration
							select_day_types.map(day_type => {
								const counter = this.checkCountNeedList(fn.id, shift.id, ubication.id, day_type.id);
								const list_item = {
									function: fn,
									shift,
									ubication,
									day_type,
									counter
								}
								need_list_full.push(list_item);

								return true;
							});
							return true;
						});
						return true;
					});
					return true;
				});
			}
			else {
				// functions iteration
				selected_functions.map(fn => {
					// shifts iteration
					selected_shifts.map(shift => {
						const need_item = {
							function: fn,
							shift,
						}
						need_list.push(need_item);

						// day types iteration
						select_day_types.map(day_type => {
							const counter = this.checkCountNeedList(fn.id, shift.id, null, day_type.id);
							const list_item = {
								function: fn,
								shift,
								day_type,
								counter
							}
							need_list_full.push(list_item);
							return true;
						});
						return true;
					});
					return true;
				});
			}

			this.setState({
				staffing_level: {
					...staffing_level,
					needs: need_list_full
				},
				need_list,
			});
		}
	}

	addFunction = () => {
		let { selected_functions, temp_function } = this.state;
		let isValid = true;
		let func;
		const new_item_errors = {};

		if (temp_function === undefined) {
			isValid = false;
		}
		else {
			func = this.props.select_functions.find(item => item.id === parseInt(temp_function));
			if (func === undefined) {
				isValid = false;
				new_item_errors.func = false;
			}
		}

		if (isValid) {
			// check if day already exists
			const exists = selected_functions.find(item => item.id === parseInt(temp_function));

			// if not : add new function
			if (!exists) {
				selected_functions = selected_functions.concat(func);
			}

			this.setState({
				selected_functions,
				new_item_errors: {},
				temp_function: {}
			}, () => {
				this.updateNeedList();
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}

	addShift = () => {
		let { selected_shifts, temp_shift } = this.state;
		let isValid = true;
		let shift;
		const new_item_errors = {};

		if (temp_shift === undefined) {
			isValid = false;
		}
		else {
			shift = this.props.attendance_shifts.find(item => item.id === parseInt(temp_shift));
			if (shift === undefined) {
				isValid = false;
				new_item_errors.shift = false;
			}
		}

		if (isValid) {
			// check if shift already exists
			const exists = selected_shifts.find(item => item.id === parseInt(temp_shift));

			if (!exists) {
				selected_shifts = selected_shifts.concat(shift);
			}

			this.setState({
				selected_shifts,
				new_item_errors: {},
				temp_shift: {},
			}, () => {
				this.updateNeedList();
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}

	addUbication = () => {
		let { selected_ubications, temp_ubication } = this.state;
		let isValid = true;
		let ubication;
		const new_item_errors = {};

		if (temp_ubication === undefined) {
			isValid = false;
		}
		else {
			ubication = this.props.ubications.find(item => item.id === parseInt(temp_ubication));
			if (ubication === undefined) {
				isValid = false;
				new_item_errors.ubication = false;
			}
		}

		if (isValid) {
			// check if ubication already exists
			const exists = selected_ubications.find(item => item.id === parseInt(temp_ubication));

			if (!exists) {
				selected_ubications = selected_ubications.concat(ubication);
			}

			this.setState({
				selected_ubications,
				new_item_errors: {},
				temp_ubication: {},
			}, () => {
				this.updateNeedList();
			});
		}
		else {
			this.setState({
				new_item_errors,
			});
		}
	}

	removeFunction = (idFunction) => {
		this.setState((prevState, props) => {
			return {
				selected_functions: prevState.selected_functions.filter(item => item.id !== parseInt(idFunction))
			};
		}, () => {
			this.updateNeedList();
		});
	}

	removeShift = (idShift) => {
		this.setState((prevState, props) => {
			return {
				selected_shifts: prevState.selected_shifts.filter(item => item.id !== parseInt(idShift))
			};
		}, () => {
			this.updateNeedList();
		});
	}

	removeUbication = (idUbication) => {
		this.setState((prevState, props) => {
			return {
				selected_ubications: prevState.selected_ubications.filter(item => item.id !== parseInt(idUbication))
			};
		}, () => {
			this.updateNeedList();
		});
	}

	// layout:
	getSelectedFunctions = () => this.state.selected_functions.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete">
				{item.name}
				<button type="button" onClick={() => this.removeFunction(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	getSelectedShifts = () => this.state.selected_shifts.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
				<button type="button" onClick={() => this.removeShift(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	getSelectedUbications = () => this.state.selected_ubications.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
				<button type="button" onClick={() => this.removeUbication(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	getNeedsHeader = () => this.props.select_day_types.map((day_type) => {
		return (
			<th className="th-daytype" key={day_type.id}>
				<div className="th-wrap">
					{day_type.name}
				</div>
			</th>
		)
	});

	getNeedsTable = () => {
		return (
			<table className="table table-bordered table-condensed table-days table-staffing-needs">
				<thead>
					<tr>
						<th></th>
						{this.getNeedsHeader()}
					</tr>
				</thead>
				<tbody>
					{this.getNeedsRows()}
				</tbody>
			</table>
		)
	};

	getNeedsRows = () => this.state.need_list.map((need, i) => {
		// getNeedsRows = () =>  this.state.staffing_level.needs.map((need, i)  => {

		var text_ubication = "";

		if (need.ubication) {
			const ubication = need.ubication;
			var color;

			if (ubication.border_color)
				color = ubication.border_color;

			if (ubication.background_color)
				color = ubication.background_color;

			text_ubication = <span className='need-ubication'><FaMapMarkerAlt className="icon" style={{
				color: color && color,
			}} /> {need.ubication.name}</span>
		}

		return (
			<tr key={i}>
				<th>{need.function.name} - {need.shift.name}{text_ubication}</th>
				{this.getNeedsRowContent(need)}
			</tr>
		)
	});

	getNeedsRowContent = (need) => this.props.select_day_types.map((day_type, i) => {
		const ubicationId = need.ubication ? need.ubication.id : null;
		const counter = this.checkCountNeedList(need.function.id, need.shift.id, ubicationId, day_type.id);

		return (
			<td key={day_type.id} className={`${counter && counter > 0 ? "filled" : "empty"} `}>
				<InputNumber
					aria-label="Type number"
					min={0}
					value={counter || 0}
					className="need-count"
					defaultValue={0}
					onChange={(e) => this.handleChangeCount(e, need, day_type)}
				/>
			</td>
		)
	});

	render() {
		const { staffing_level, new_item_errors, selected_functions, selected_shifts, selected_ubications, temp_function, temp_shift, temp_ubication } = this.state;
		const { select_functions, attendance_shifts, ubications } = this.props;

		const optionFunctionList = select_functions.length > 0 && select_functions.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		const optionShiftList = attendance_shifts.length > 0 && attendance_shifts.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		const optionUbicationList = ubications.length > 0 && ubications.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		const hasUbications = staffing_level.has_ubications;

		const texto_tabla_selecciona = hasUbications ? "funciones, turnos y ubicaciones" : "funciones y turnos";
		const texto_tabla_titulo = hasUbications ? "función, turno y ubicación" : "función y turno";

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body staffing-level-edit">

					<Row>
						<Col sm={6}>
							<FormGroup controlId="name">
								<FormLabel>Nombre <span className="label-required">*</span></FormLabel>
								<FormControl
									type="text"
									value={staffing_level.name}
									onChange={this.handleChange}
									placeholder="Nombre de la necesidad operativa"
									isInvalid={this.checkValidField('name')}
								/>
								<FormControl.Feedback type="invalid">
									{this.state.errors.name}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
						<Col sm={6}>
							<FormGroup controlId="description">
								<FormLabel>Descripción</FormLabel>
								<FormControl
									type="text"
									value={staffing_level.description}
									onChange={this.handleChange}
									placeholder="Breve descripción"
									isInvalid={this.checkValidField('description')}
								/>
								<FormControl.Feedback type="invalid">
									{this.state.errors.description}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
					</Row>
					<Row className="row-adding">
						<Col sm={6}>
							<div className="flex-group">
								<FormGroup controlId="temp_function">
									<FormLabel>Funciones</FormLabel>
									<FormControl
										type="text"
										onChange={this.handleAddChange}
										value={temp_function}
										placeholder="Selecciona la función"
										isInvalid={new_item_errors.func !== undefined}
										as="select"
									>
										<option value="">Selecciona la función</option>
										{optionFunctionList}
									</FormControl>
								</FormGroup>
								<button type="button" onClick={this.addFunction} className="btn btn-primary">Añadir</button>
							</div>
							<ul className="list-tags">
								{this.getSelectedFunctions()}
							</ul>
						</Col>
						<Col sm={6}>
							<div className="flex-group">
								<FormGroup controlId="temp_shift">
									<FormLabel>Turnos</FormLabel>
									<FormControl
										type="text"
										value={temp_shift}
										placeholder="Selecciona el turno"
										onChange={this.handleAddChange}
										isInvalid={new_item_errors.shift !== undefined}
										as="select"
									>
										<option value="">Selecciona el turno</option>
										{optionShiftList}
									</FormControl>
								</FormGroup>
								<button type="button" onClick={this.addShift} className="btn btn-primary">Añadir</button>
							</div>
							<ul className="list-tags">
								{this.getSelectedShifts()}
							</ul>
						</Col>
					</Row>

					<Row className="row-adding">
						<Col sm={6}>
							<FormGroup
								className="form-group-flex form-row-switch"
								controlId="has_ubications"
							>
								<Switch
									controlId="has_ubications"
									isOn={hasUbications}
									handleToggle={this.handleSwitch}
								/>
								<FormLabel>
									Añadir ubicaciones a necesidades operativas
								</FormLabel>
							</FormGroup>
						</Col>
						{hasUbications && (
							<Col sm={6}>
								<div className="flex-group">
									<FormGroup controlId="temp_ubication">
										<FormLabel>Ubicaciones</FormLabel>
										<FormControl
											type="text"
											value={temp_ubication}
											placeholder="Selecciona la ubicación"
											onChange={this.handleAddChange}
											isInvalid={new_item_errors.ubication !== undefined}
											as="select"
										>
											<option value="">Selecciona la ubicación</option>
											{optionUbicationList}
										</FormControl>
									</FormGroup>
									<button type="button" onClick={this.addUbication} className="btn btn-primary">Añadir</button>
								</div>
								<ul className="list-tags">
									{this.getSelectedUbications()}
								</ul>
							</Col>
						)}
					</Row>


					<h3 className="subtitle">Necesidades de personal por {texto_tabla_titulo}</h3>

					<div className="table-wrapper">

						{selected_functions.length > 0 && selected_shifts.length > 0 ? (
							this.getNeedsTable()
						) :
							(
								<p className="info-text"><FiInfo className="info-icon" /> Selecciona {texto_tabla_selecciona} para poder gestionar las necesidades de personal</p>
							)
						}

					</div>

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.groupsReducer,
		...reducers.calendarsReducer,
		...reducers.functionsReducer,
		...reducers.shiftsReducer,
		...reducers.ubicationsReducer,
		loading: reducers.groupsReducer.loading
	}
};

export default connect(mapStateToProps, groupsActions)(StaffingLevelEdit)