import React, { Component, createRef } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import AvatarEditor from 'react-avatar-editor';
import * as userActions from 'actions/userActions';
import Dropzone from 'react-dropzone';
import { employeesService } from "services";
import './Avatar.scss';

class AvatarEdit extends Component {
	constructor(props) {
		super(props);

		// console.log(props);

		this.state = {
			image: '',
			hasImage: false,
			allowZoomOut: false,
			position: { x: 0, y: 0 },
			scale: 1,
			rotate: 0,
			width: 220,
			height: 220,
			user: {},
		}
	}

	async componentDidMount() {
		const user = await employeesService.getById(this.props.userId);
		this.setState({
			user
		}, () => {
			this.getAvatar();
		});
	}

	async getAvatar() {
		const localStorageData = localStorage.getItem(this.props.userId);
		var img = "";

		if (localStorageData !== null) {
			img = JSON.parse(localStorageData);
		}
		else {
			img = await employeesService.getAvatar(this.props.userId);
			localStorage.setItem(this.props.userId, JSON.stringify(img));
		}

		this.setState({
			image: img,
			hasImage: (this.state.user.avatar !== undefined && this.state.user.avatar !== null)
		});
	}

	handleDrop = dropped => {
		this.setState({
			image: dropped[0],
			hasImage: true,
			scale: 1
		});
	}

	handlePositionChange = position => {
		this.setState({ position })
	}

	handleScale = e => {
		const scale = parseFloat(e.target.value)
		this.setState({ scale })
	}

	async updateUser() {
		// if its ME
		if (this.state.user.id === this.props.userId)
			await this.props.update();
	}

	onClickSave = async () => {
		if (this.editor) {
			const canvasScaled = this.editor.getImageScaledToCanvas()
			var dataURL = canvasScaled.toDataURL();

			// save avatar
			const response = await employeesService.saveAvatar(this.props.userId, dataURL.replace("data:image/png;base64,", ""));

			// console.log(response);
			if (response.status === 201) {
				localStorage.removeItem(this.props.userId);
				this.updateUser();
				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				this.props.onClose();
			}
		}
	}

	onClickDelete = async () => {
		// delete avatar
		await employeesService.deleteAvatar(this.props.userId);
		const { user } = this.state;
		this.setState({
			hasImage: false,
			scale: 1,
			user: {
				...user,
				avatar: null
			}
		}, () => {
			this.updateUser();
			this.getAvatar();
		});
	}

	setEditorRef = (editor) => this.editor = editor

	render() {
		const dropzoneRef = createRef();
		const openDialog = () => {
			if (dropzoneRef.current) {
				dropzoneRef.current.open()
			}
		};

		return (
			<div className="avatar-edit">
				<Dropzone
					onDrop={this.handleDrop}
					ref={dropzoneRef}
					multiple={false}
					style={{ width: '220px', height: '220px' }}
				>
					{({ getRootProps, getInputProps }) => (
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<AvatarEditor
								image={this.state.image}
								ref={this.setEditorRef}
								width={this.state.width}
								height={this.state.height}
								border={0}
								borderRadius={this.state.width / 2}
								color={[255, 255, 255, 0.5]} // RGBA
								scale={parseFloat(this.state.scale)}
								rotate={parseFloat(this.state.rotate)}
								position={this.state.position}
								onPositionChange={this.handlePositionChange}
							/>
						</div>
					)}
				</Dropzone>

				{this.state.hasImage ? (
					<>
						<div className="avatar-zoom">
							<label>Zoom:</label>
							<input
								name="scale"
								type="range"
								onChange={this.handleScale}
								min={this.state.allowZoomOut ? '0.1' : '1'}
								max="2"
								step="0.01"
								defaultValue="1"
							/>
						</div>
						<button type="button" className="btn btn-primary" onClick={this.onClickSave}>Guardar foto</button>
						<button type="button" className="btn btn-link-danger" onClick={this.onClickDelete}>Eliminar foto</button>
					</>
				) :
					(
						<button type="button" className="btn btn-primary" onClick={openDialog}>Subir foto</button>
					)
				}


			</div>
		)
	}
}

const mapStateToProps = (reducers) => {
	return reducers.authReducer;
};

export default connect(mapStateToProps, userActions)(AvatarEdit);