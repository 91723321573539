import React, { Component } from 'react';
import { connect  } from 'react-redux';
import { compose } from 'redux';
import { FiMoreHorizontal, FiCalendar } from "react-icons/fi";
import { withRouter} from 'react-router-dom';
import {TabletOrDesktop, Mobile} from 'utils/responsive';
import { Dropdown } from 'react-bootstrap';
import GroupEdit from "components/Groups/GroupEditModal";
import Avatars  from 'components/Avatar/Avatars';
import { showModal, hideModal } from "actions/modalActions";
import * as groupsActions from 'actions/groupsActions';
import { groupsService } from "services";
import { errorFormating } from "utils/utils";

class MultiGroupList extends Component {
  constructor(props) {
    super(props);
    // console.log(props);

	this.closeModal = this.closeModal.bind(this);
  }

	closeModal() {
		this.props.hideModal()
	}

	openEditGroupModal(group) {
		this.props.showModal({
			open: true,
			title: 'Editar grupo',
			style: {width: '400px'},
			content: <GroupEdit group={group}  handleClose={this.closeModal}  />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openViewGroupPage(group) {
		this.props.history.push("/groups/"  + group.id);
	}	

	openDeleteGroupModal(idGroup) {
		this.props.showModal({
			open: true,
			title: 'Eliminar grupo',
			message:'¿Desea eliminar el grupo? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idGroup),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
		 open: true,
		 title: "Error al eliminar",
		 style: {width: '400px'},
		 message: message,
		 closeModal: this.closeModal
		}, 'alert')
	} 	


	confirmDelete= async(idGroup) =>  {
		const response = await groupsService.remove(idGroup);

		if (!response.ok){
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else{
			this.getGroups();
			this.closeModal();
		}
	}

	getEmptyRow = () => {
		return(
			<tr>
				<td colSpan="5" className="no-result">
					Todavía no hay ningún grupo creado.
				</td>
			</tr>
		);
	}  

	getRows = () =>  this.props.groups.map((group)  => {
	 let empleados_count = "Ningún empleado asignado";

	 if (group.total_employees === 1)
	   empleados_count = "1 empleado";
	 else if (group.total_employees > 1)
	   empleados_count = group.total_employees + " empleados";

	  return (
	      <tr key={ group.id }>
	        <td> 
				<button className="btn-transparent btn-editable group-name" onClick={()=> this.openViewGroupPage(group)}>{ group.name }</button>
	        </td>
	        <td>
	            grupos
	        </td>	             
	        <td>
	          <div className="avatar-container">
	            { group.total_employees > 0 &&
	              <Avatars employees={group.employees} /> 
	            }
	            {empleados_count}
	          </div>
	        </td>
    
	        <td className="td-actions">
	            {this.getActions(group)}
	        </td>
	      </tr>
	    )
	});   

	getRowsMobile = () =>  this.props.groups.map((group)  => {
		let empleados_count = "Ningún empleado asignado";

		if (group.total_employees === 1)
			empleados_count = "1 empleado";
		else if (group.total_employees > 1)
			empleados_count = group.total_employees + " empleados";

		return (
		  <tr key={ group.id }>
		    <td> 
				<div className="group-head">
					<button className="btn-transparent btn-editable group-name" onClick={()=> this.openViewGroupPage(group)}>{ group.name }</button>
				</div>
				<div className="flex-between">
					<div className="avatar-container">
						{ group.total_employees > 0 &&
						  <Avatars employees={group.employees} /> 
						}
						{empleados_count}
					</div>				
				</div>
		    </td> 		    
		    <td className="td-actions">
		        {this.getActions(group)}
		    </td>
		  </tr>
		)
	}); 

	getActions = (group) =>  {
	  return (
	          <Dropdown>
	            <Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
	            <Dropdown.Menu alignRight>
	              <Dropdown.Item as="button" onClick={()=> this.openViewGroupPage(group)}>Ver</Dropdown.Item>
	              <Dropdown.Item as="button" onClick={()=> this.openEditGroupModal(group)}>Editar</Dropdown.Item>
	              <Dropdown.Item as="button" onClick={()=> this.openDeleteGroupModal(group.id)}>Eliminar</Dropdown.Item>
	            </Dropdown.Menu>
	          </Dropdown>
	    )
	};	

	render() {
		return(
			<>
				<Mobile>
				    <table className="table table-groups table-mobile">
				      <tbody>
				        {this.props.groups.length === 0 ?
				          this.getEmptyRow() 
				          :
				          this.getRowsMobile() 
				         }
				      </tbody>
				    </table>
				</Mobile>
				<TabletOrDesktop>      
				  <table className="table table-groups table-zebra">
				    <thead>
				      <tr>
				        <th>Nombre</th>
				        <th>Grupos</th>
				        <th>Empleados</th>
				        <th></th>
				      </tr>
				    </thead>
				    <tbody>
				        {this.props.groups.length === 0 ?
				         this.getEmptyRow() 
				        :
				         this.getRows() 
				       }
				    </tbody>
				  </table>
				</TabletOrDesktop>
			</>
		)
	}
}


const mapStateToProps = (reducers) => {
	return reducers.groupsReducer;
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getAllGroups: (options) => dispatch(groupsActions.getAllGroups(options))
})

export default compose(withRouter,connect(mapStateToProps, mapDispatchToProps))(MultiGroupList);