import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import moment from 'moment';
import GroupStaffingLevelsEdit from "./GroupStaffingLevelEditModal";
import { showModal, hideModal } from "actions/modalActions";
import * as groupsActions from 'actions/groupsActions';
import { groupsService } from "services";
import { errorFormating } from "utils/utils";
import "./Groups.scss";

class GroupStaffingLevels extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewStaffingLevelModal = this.openNewStaffingLevelModal.bind(this);
		this.openEditStaffingLevelModal = this.openEditStaffingLevelModal.bind(this);
   		this.openAlertModal = this.openAlertModal.bind(this);		
	}

	componentDidMount(){
		this.getStaffingLevels();
	}

   async getStaffingLevels () {
   		const groupId = this.props.group.id;
  		await this.props.getGroupStaffingLevels(groupId);
  	}

	closeModal() {
		this.props.hideModal()
	}

	openNewStaffingLevelModal() {
		const {staffing_levels, group} = this.props;

		this.props.showModal({
			open: true,
			title: 'Nueva asignación con necesidades de personal',
			style: {width: '400px'},
			content: <GroupStaffingLevelsEdit handleClose={this.closeModal} staffing_levels={staffing_levels} group={group} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openEditStaffingLevelModal(group_staffing_level) {
		const {staffing_levels, group} = this.props;

		this.props.showModal({
			open: true,
			title: 'Editar asignación con necesidades de personal',
			style: {width: '400px'},
			content: <GroupStaffingLevelsEdit group_staffing_level={group_staffing_level} group={group}  handleClose={this.closeModal} staffing_levels={staffing_levels} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteStaffingLevelModal(idStaffingLevel) {
		this.props.showModal({
			open: true,
			title: 'Eliminar asignación',
			style: {width: '350px'},
			message:'¿Desea eliminar la asignación con las necesidades de personal? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idStaffingLevel),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
	 this.props.showModal({
	   open: true,
	   title: "Error al eliminar",
	   style: {width: '400px'},
	   message: message,
	   closeModal: this.closeModal
	 }, 'alert')
	}	

	confirmDelete= async(idStaffingLevel) =>  {
		const response = await groupsService.removeStaffingLevel(this.props.group.id, idStaffingLevel);	

		if (!response.ok){
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else{
			this.getStaffingLevels();
			this.closeModal();
		}
	}

	getEmptyRow = () => {
		return(
			<tr>
				<td colSpan="3" className="no-result">
					Todavía no hay ninguna necesidad de personal asignada.
				</td>
			</tr>
		);
	}  

	getRows = () =>  this.props.group_staffing_levels.map((group_needs)  => {

	  return (
	      <tr key={ group_needs.id }>
	        <td> 
	          <button className="btn-transparent" onClick={()=> this.openEditStaffingLevelModal(group_needs)}>{ group_needs.staffing_level.name }</button>
	        </td>
	        <td>
	          {moment(group_needs.start_day).format("DD/MM/YY")}
	        </td>
	        <td className="td-actions">
	          <Dropdown>
	            <Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
	            <Dropdown.Menu alignRight>
	              <Dropdown.Item as="button" onClick={()=> this.openEditStaffingLevelModal(group_needs)}>Editar</Dropdown.Item>
	              <Dropdown.Item as="button" onClick={()=> this.openDeleteStaffingLevelModal(group_needs.id)}>Eliminar</Dropdown.Item>
	            </Dropdown.Menu>
	          </Dropdown>
	        </td>
	      </tr>
	    )
	});

	getContent = () => {
		const { group_staffing_levels } = this.props;

		return(
			<>
				<table className="table table-groups-staffing-levels table-zebra-reverse">
					<thead>
						<tr>
							<th>Necesidad de personal</th>
							<th>Inicio</th>
							<th className="td-actions"><button type="button" title="Asignar necesidades de personal" onClick={this.openNewStaffingLevelModal} className="btn btn-new"><FiPlus /></button></th>
						</tr>
					</thead>
					<tbody>
						{ group_staffing_levels.length === 0 ?
							this.getEmptyRow() 
						:
							this.getRows() 
						}
					</tbody>
				</table>
		    </>
		);
	}
	render() {
		return(
		    <>
		        { this.getContent() }
		    </>
		)
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.groupsReducer,
	}
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  },
  getGroupStaffingLevels: (id) => dispatch(groupsActions.getGroupStaffingLevels(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupStaffingLevels);