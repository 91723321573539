import React from 'react';
import moment from 'moment';
import { FiEdit3 } from "react-icons/fi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import { PLAN_GRID_VIEWS } from "components/Plans";
const MENU_STAFFING_ID = "menu_staffing";

function collect(props) {
	return props;
}

const getStaffingLevelHeader = (plan_days) => plan_days.map((d, i) => {
	const day = moment(d.day).format("dd");
	const dayNum = moment(d.day).format("DD");
	let day_type;
	const details = d.details;

	// TODO adaptar multigrup
	if (details.length > 0) {
		if (details[0].special_day)
			day_type = details[0].special_day.day_type;

		if (details[0].weekday)
			day_type = details[0].weekday.day_type;
	}

	if (d.special_day)
		day_type = d.special_day.day_type;

	if (d.weekday)
		day_type = d.weekday.day_type;

	const color = day_type.color;

	return (
		<th className="th-day" key={d.day} style={{ color: color }}>
			<div className="th-wrap">
				<span className="span-day">{day}</span>
				<span className="span-num">{dayNum}</span>
			</div>
		</th>
	)
});

const getStaffingLevelRows = (props) => props.staffing_level_assignments.map((assignment, i) => {
	const fun = assignment.function;
	const shift = assignment.shift;

	var text_ubication = "";

	if (assignment.ubication) {
		const ubication = assignment.ubication;
		var color;

		if (ubication.border_color)
			color = ubication.border_color;

		if (ubication.background_color)
			color = ubication.background_color;

		text_ubication =
			(<span className='need-ubication'>
				<FaMapMarkerAlt className="icon" style={{
					color: color && color,
				}} /> {assignment.ubication.name}
			</span>)
	}

	return (
		<tr key={i}>
			<th>
				<div className="th-wrap">
					{fun.name} en {shift.name} {text_ubication}
				</div>
			</th>

			{getStaffingLevelRowContent(props, assignment, i)}
		</tr>
	)
});

const getTooltipDetails = (item) => {
	let details = "";
	if (item.employees && item.employees.length > 0) {
		const employee_list = item.employees.map((employee, i) => {
			// console.log(employee);
			const skill = (employee.employee_with_ubication && employee.employee_with_ubication.skill && employee.employee_with_ubication.skill > 0) ? " (Skill: " + employee.employee_with_ubication.skill + ")" : "";
			return (
				<li key={i}>{employee.name} {employee.surname} {skill}</li>
			)
		}, this);

		details = <ul className="tooltip-employees">{employee_list}</ul>;
	}
	return details;
};

const getStaffingLevelRowContent = (props, assignment, row) => assignment.counters.map((item, i) => {
	const checked = item.checked || false;
	const details = getTooltipDetails(item);
	const needed = item.overwrite ? item.overwrite : item.needed;
	const tooltip = <Tooltip className="tooltip-staffing">{item.current}/{needed}{details}</Tooltip>;
	// const needs_selection = props.needs_selection;

	item.function = assignment.function;
	item.shift = assignment.shift;

	if (assignment.ubication)
		item.ubication = assignment.ubication;


	var classBackground = "";
	if (needed < item.current) {
		classBackground = "bg-green";
	}

	if (needed > item.current) {
		classBackground = "bg-red";
	}

	return (
		<OverlayTrigger key={i} placement="top" overlay={tooltip}>
			<td key={i} className={`need-cell btn-checkbox ${checked ? "active" : ""} ${classBackground}`}>
				<ContextMenuTrigger
					id={MENU_STAFFING_ID}
					holdToDisplay={1000}
					collect={collect}
					onItemClick={props.handleStaffingMenuClick}
					targetId={item.id}
					itemStaffing={item}
				>
					<input
						type="checkbox"
						id={`need_${row}_${i}`}
						onChange={(e) => props.handleNeedSelection(row, i, e)
						}
						checked={checked}
					/>
					<label
						htmlFor={`need_${row}_${i}`}
						className="btn-check-label"
						onContextMenu={() => props.handleNeedSelection(row, i)}
						draggable
						onDragEnter={() => props.handleNeedSelection(row, i)}
					></label>

					{needed === 0 && item.current === 0 ? "-" : needed}
					{item.overwrite && (item.overwrite !== item.needed) ? <FiEdit3 className="need-icon icon-edited" /> : ""}
					{needed < item.current && (<FaCaretUp className="color-green-dark" />)}
					{needed > item.current && (<FaCaretDown className="color-red" />)}

				</ContextMenuTrigger>
			</td>
		</OverlayTrigger >
	)
});



// Para cuando no hay necesidades definidas, se devuelve la tabla de current_assignments

const getStaffingLevelCurrentRows = (props) => props.staffing_level_current_assignments.map((assignment, i) => {
	const fun = assignment.function;
	const shift = assignment.shift;

	var text_ubication = "";

	if (assignment.ubication) {
		const ubication = assignment.ubication;
		var color;

		if (ubication.border_color)
			color = ubication.border_color;

		if (ubication.background_color)
			color = ubication.background_color;

		text_ubication =
			(<span className='need-ubication'>
				<FaMapMarkerAlt className="icon" style={{
					color: color && color,
				}} /> {assignment.ubication.name}
			</span>)
	}

	// mirar si los assignment.counters.current estan todos a cero para no pintar la fila
	const all_zero = assignment.counters.every((item) => item.current === 0);

	if (!all_zero) {
		return (
			<tr key={i}>
				<th>
					<div className="th-wrap">
						{fun.name} en {shift.name} {text_ubication}
					</div>
				</th>

				{getStaffingLevelCurrentRowContent(assignment)}
			</tr>
		)
	}
	else return null;

});

const getStaffingLevelCurrentRowContent = (assignment) => assignment.counters.map((item, i) => {
	const details = getTooltipDetails(item);
	const tooltip = <Tooltip className="tooltip-staffing">{item.current}{details}</Tooltip>;

	if (item.current > 0) {
		return (
			<OverlayTrigger key={i} placement="top" overlay={tooltip}>
				<td key={i} className="current-cell">
					{item.current}
				</td>
			</OverlayTrigger >
		)
	}

	return (
		<td key={i} className="current-cell current-none">
			{item.current}
		</td>
	)
});


const PlanStaffingLevels = (props) => {
	// console.log(props);
	const { plan_days, plan_grid_view } = props;

	var tableClass = "";
	if (plan_grid_view === PLAN_GRID_VIEWS.combo)
		tableClass = "table-grid-combo";

	if (props.staffing_level_assignments.length > 0 || props.staffing_level_current_assignments.length > 0) {
		return (
			<table id="tableNeeds" className={`table table-bordered table-plan table-needs ${tableClass}`}>
				<thead>
					<tr>
						<th className="main-col"><div className="th-wrap">Necesidades</div></th>
						{getStaffingLevelHeader(plan_days)}
					</tr>
				</thead>
				<tbody>
					{props.staffing_level_assignments.length > 0 ?
						getStaffingLevelRows(props) :
						getStaffingLevelCurrentRows(props)}
				</tbody>
			</table>
		)
	} else return "";

}

export default PlanStaffingLevels