import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel, Row, Col, FormCheck } from "react-bootstrap";
import { FiDelete, FiCalendar } from "react-icons/fi";
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);
// import { shiftsService } from "services";
// import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import * as shiftsActions from 'actions/shiftsActions';
import "./ShiftRequests.scss";

class ShiftRequestEdit extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		let shift_request = {
			employee: '',
			swap_with_employee: false,
		}

		let isEdit = false;

		if (props.shift) {
			isEdit = true;
			shift_request = props.shift_request;
		}

		this.state = {
			isEdit,
			isLoading: false,
			submitted: false,
			errors: {},
			shift_request,
		};
	}

	handleChange = event => {
		const { shift_request } = this.state;

		this.setState({
			shift_request: {
				...shift_request,
				[event.target.id]: event.target.value
			},
		});
	}

	handleSwitch = (event) => {
		const { shift_request } = this.state;

		this.setState({
			shift_request: {
				...shift_request,
				[event.target.id]: event.target.checked,
			},
		});
	};

	handleDateChange = (id, date) => {
		const { shift_request } = this.state;

		let key = id;
		let value = moment(date).format("YYYY-MM-DD");

		this.setState({
			shift_request: {
				...shift_request,
				[key]: value,
			},
		});
	};

	handlePeriodChange = (event) => {
		const { shift_request } = this.state;

		let key = event.target.id;
		let value = event.target.value;

		// console.log(key);
		// console.log(value);

		if (event.target.id === "single" || event.target.id === "multi") {
			key = "isSingleDay";
			value = event.target.id === "single";


			this.setState({
				shift_request: {
					...shift_request,
					[key]: value,
				},
			});
		}
		else {
			this.setState({
				shift_request: {
					...shift_request,
					isSingleDay: false,
				},
			});
		}
	};



	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}


	handleSubmit = async event => {
		event.preventDefault();
		const props = this.props;

		this.setState({ isLoading: true });

		// añade timeout para simular carga
		setTimeout(() => {

			this.setState({ isLoading: false });
			props.handleClose();
			toastr.success('¡Bien!', 'Cambios de turno solicitado correctamente');

		}, 2000);


		// const { isEdit, shift_request } = this.state;
		// this.setState({ isLoading: true });
		// let response = '';
		// const shift_request_temp = {
		// 	...shift_request,
		// 	// lalala
		// }
		// if edit => update; else => new
		// console.log(response);
		// if (isEdit) {
		// 	response = await shiftsService.update(shift_request_temp);
		// }
		// else {
		// 	response = await shiftsService.add(shift_request_temp);
		// }

		// if (response.ok) {
		// 	this.setState({
		// 		isLoading: false,
		// 		errors: {},
		// 	}, () => {

		// 		toastr.success('¡Bien!', 'Cambios guardados correctamente');
		// 		// this.props.getAllShifts({});

		// 		const { list_state } = props;
		// 		if (list_state)
		// 			props.getAllShifts({ q: list_state.searchShift, shift_type: list_state.filterTypeId });
		// 		else
		// 			props.getAllShifts({});

		// 		props.handleClose();

		// 	});
		// }
		// else {
		// 	this.setState({
		// 		isLoading: false,
		// 		submitted: true,
		// 		errors: this.extendedErrorFormatting(response)
		// 	});
		// }
	}
	getSingleDayContent = () => {
		const { shift_request } = this.state;
		return (

			<FormGroup
				controlId="start"
				className={`form-date flex-col ${shift_request.start === "" && "empty"} ${this.checkValidField('start') && "is-invalid"}`}
			>
				<FormLabel>Fecha solicitada <span className="label-required">*</span></FormLabel>

				<DatePicker
					selected={shift_request.start === "" ? shift_request.start : moment(shift_request.start).toDate()}
					onChange={(date) => this.handleDateChange("start", date)}
					isInvalid={this.checkValidField("start")}
					className="form-control"
					dateFormat="dd/MM/yyyy"
					locale='es'
					placeholderText="dd/mm/aaaa"
				/>
				<FiCalendar />
				<FormControl.Feedback type="invalid">
					{this.state.errors.start}
				</FormControl.Feedback>
			</FormGroup>
		);
	};

	getStartEndContent = () => {
		const { shift_request } = this.state;
		// console.log(shift_request.start);
		// console.log(shift_request.end);
		return (
			<Row>
				<Col sm={6}>
					<FormGroup
						controlId="start"
						className={`form-date ${shift_request.start === "" && "empty"}  ${this.checkValidField('start') && "is-invalid"}`}
					>
						<FormLabel>Empieza <span className="label-required">*</span></FormLabel>
						<DatePicker
							selected={shift_request.start === "" || shift_request.start === null ? shift_request.start : moment(shift_request.start).toDate()}
							onChange={(date) => this.handleDateChange("start", date)}
							isInvalid={this.checkValidField("start")}
							className="form-control"
							dateFormat="dd/MM/yyyy"
							locale='es'
							placeholderText="dd/mm/aaaa"
						/>
						<FiCalendar />
						<FormControl.Feedback type="invalid">
							{this.state.errors.start}
						</FormControl.Feedback>
					</FormGroup>
				</Col>

				<Col sm={6}>
					<FormGroup
						controlId="end"
						className={`form-date ${shift_request.end === "" && "empty"}  ${this.checkValidField('end') && "is-invalid"}`}
					>
						<FormLabel>Termina <span className="label-required">*</span></FormLabel>
						<DatePicker
							selected={shift_request.end === "" || shift_request.end === null ? shift_request.end : moment(shift_request.end).toDate()}
							onChange={(date) => this.handleDateChange("end", date)}
							isInvalid={this.checkValidField("end")}
							className="form-control"
							dateFormat="dd/MM/yyyy"
							locale='es'
							placeholderText="dd/mm/aaaa"
						/>
						<FiCalendar />
						<FormControl.Feedback type="invalid">
							{this.state.errors.end}
						</FormControl.Feedback>
					</FormGroup>
				</Col>
			</Row>
		);
	};

	getEmployeeSwapContent = ({ optionShiftList }) => {
		const { shift_request, errors } = this.state;
		const { select_employees, user } = this.props;
		const select_employees_without_me = select_employees.filter((item) => item.id !== user.id);
		const optionEmployeeList = select_employees_without_me.length > 0 && select_employees_without_me.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);
		return (
			<>
				<FormGroup controlId="employee">
					<FormLabel>Empleado <span className="label-required">*</span></FormLabel>
					<FormControl
						type="text"
						value={shift_request.employee}
						onChange={this.handleChange}
						isInvalid={this.checkValidField('employee')}
						as="select">
						<option value="">Selecciona empleado</option>
						{optionEmployeeList}
					</FormControl>

					<FormControl.Feedback type="invalid">
						{errors.employee}
					</FormControl.Feedback>
				</FormGroup>

				<Row className="mt25">
					<Col className="col-right-separator" sm={6} md={6}>
						<h3 className="subtitle">Turno actual</h3>
						<FormGroup
							controlId="date_origin"
							className={`form-date ${shift_request.date_origin === "" && "empty"}`}
						>
							<FormLabel>Fecha <span className="label-required">*</span></FormLabel>
							<DatePicker
								selected={shift_request.date_origin === "" ? shift_request.date_origin : moment(shift_request.date_origin).toDate()}
								onChange={(date) => this.handleDateChange("date_origin", date)}
								isInvalid={this.checkValidField("date_origin")}
								className="form-control"
								dateFormat="dd/MM/yyyy"
								locale='es'
								placeholderText="dd/mm/aaaa"
							/>
							<FiCalendar />
							<FormControl.Feedback type="invalid">
								{this.state.errors.date_origin}
							</FormControl.Feedback>
						</FormGroup>
						<FormGroup controlId="shift_origin">
							<FormLabel>Turno   <span className="label-required">*</span></FormLabel>
							<FormControl
								type="text"
								value={shift_request.shift_origin}
								onChange={this.handleChange}
								isInvalid={this.checkValidField('shift_origin')}
								as="select">
								<option value="">Turno</option>
								{optionShiftList}
							</FormControl>

							<FormControl.Feedback type="invalid">
								{errors.shift_origin}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={6}>
						<h3 className="subtitle">Turno deseado</h3>
						<FormGroup
							controlId="date_target"
							className={`form-date ${shift_request.date_target === "" && "empty"}`}
						>
							<FormLabel>Fecha <span className="label-required">*</span></FormLabel>
							<DatePicker
								selected={shift_request.date_target === "" ? shift_request.date_target : moment(shift_request.date_target).toDate()}
								onChange={(date) => this.handleDateChange("date_target", date)}
								isInvalid={this.checkValidField("date_target")}
								className="form-control"
								dateFormat="dd/MM/yyyy"
								locale='es'
								placeholderText="dd/mm/aaaa"
							/>
							<FiCalendar />
							<FormControl.Feedback type="invalid">
								{this.state.errors.date_target}
							</FormControl.Feedback>
						</FormGroup>
						<FormGroup controlId="shift_target">
							<FormLabel>Turno  <span className="label-required">*</span></FormLabel>
							<FormControl
								type="text"
								value={shift_request.shift_target}
								onChange={this.handleChange}
								isInvalid={this.checkValidField('shift_target')}
								as="select">
								<option value="">Turno</option>
								{optionShiftList}
							</FormControl>

							<FormControl.Feedback type="invalid">
								{errors.shift_target}
							</FormControl.Feedback>
						</FormGroup>
					</Col>
				</Row>
			</>
		);
	};

	getMyPreferenceContent = ({ optionShiftList }) => {
		const { shift_request, errors } = this.state;
		return (
			<>
				<FormGroup controlId="period" className="mt25">
					<FormLabel className="hidden">Duración</FormLabel>
					<div className="btn-choice-group">
						<FormCheck
							type="radio"
							id="single"
							label="Un día"
							value="false"
							checked={shift_request.isSingleDay}
							bsPrefix={
								shift_request.isSingleDay ? "btn-choice active" : "btn-choice"
							}
							onChange={this.handlePeriodChange}
						/>
						<FormCheck
							type="radio"
							id="multi"
							label="Varios días"
							value="false"
							checked={!shift_request.isSingleDay}
							bsPrefix={
								!shift_request.isSingleDay ? "btn-choice active" : "btn-choice"
							}
							onChange={this.handlePeriodChange}
						/>
					</div>
				</FormGroup>


				{(shift_request.isSingleDay) ? this.getSingleDayContent() : this.getStartEndContent()}

				<FormGroup controlId="shift_target">
					<FormLabel>Turno  deseado <span className="label-required">*</span></FormLabel>
					<FormControl
						type="text"
						value={shift_request.shift_target}
						onChange={this.handleChange}
						isInvalid={this.checkValidField('shift_target')}
						as="select">
						<option value="">Turno</option>
						{optionShiftList}
					</FormControl>

					<FormControl.Feedback type="invalid">
						{errors.shift_target}
					</FormControl.Feedback>
				</FormGroup>
			</>
		)
	};

	render() {
		const { shift_request } = this.state;

		const { attendance_shifts } = this.props;
		const optionShiftList = attendance_shifts.length > 0 && attendance_shifts.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name}</option>
			)
		}, this);

		const enableSwap = shift_request.swap_with_employee;
		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body shift-edit">
					<FormGroup
						className="form-group-flex form-grupos"
						controlId="swap_with_employee"
					>
						<FormLabel>¿Quieres cambiar el turno con otro empleado?</FormLabel>
						<Switch
							controlId="swap_with_employee"
							isOn={enableSwap}
							handleToggle={this.handleSwitch}
						/>
					</FormGroup>

					{enableSwap ? this.getEmployeeSwapContent({ optionShiftList }) : this.getMyPreferenceContent({ optionShiftList })}

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Solicitar"
						loadingText="Enviando..."
					/>
				</div>
			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return {
		...reducers.shiftsReducer,
		...reducers.employeesReducer,
		user: reducers.authReducer.user,
		loading: reducers.shiftsReducer.loading
	}
};

export default connect(mapStateToProps, shiftsActions)(ShiftRequestEdit)
