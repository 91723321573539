import React from "react";
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { FiCalendar } from "react-icons/fi";
import { employeesService } from "services";
import * as userActions from "actions/userActions";
import { errorFormating } from "utils/utils";
import Layout from "components/Layout";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import AvatarEdit from "components/Avatar/AvatarEdit";
import ChangePassword from "components/ChangePassword";
import { showModal, hideModal } from "actions/modalActions";
import SubmitButton from "components/SubmitButton";
import { isSupervisor } from "utils/utils";

import "components/Profile/Profile.scss";

class Profile extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		const user = this.props.user;
		const role = user.roles.filter((item) => item !== "SUPERVISOR");
		user.role = role.toString();

		this.state = {
			isLoading: false,
			errors: {},
			user,
			prevUser: user,
		};

		this.closeModal = this.closeModal.bind(this);
		this.openAvatarModal = this.openAvatarModal.bind(this);
		this.openPasswordModal = this.openPasswordModal.bind(this);
	}

	closeModal() {
		this.props.hideModal();
	}

	openAvatarModal() {
		this.props.showModal(
			{
				open: true,
				title: "Editar avatar",
				style: { width: "300px" },
				content: (
					<AvatarEdit userId={this.state.user.id} onClose={this.closeModal} />
				),
				closeModal: this.closeModal,
			},
			"empty"
		);
	}

	openPasswordModal() {
		this.props.showModal(
			{
				open: true,
				title: "Cambiar contraseña",
				style: { width: "320px" },
				content: <ChangePassword handleClose={this.closeModal} />,
				closeModal: this.closeModal,
			},
			"empty"
		);
	}

	checkValidField = (name) => {
		return (
			this.state.errors[name] !== undefined && this.state.errors[name] !== ""
		);
	};

	handleChange = (event) => {
		const { user } = this.state;

		this.setState({
			prevUser: user,
			user: {
				...user,
				[event.target.id]: event.target.value,
			},
		});
	};

	// handleBlur = async () => {
	// 	const hasChanged = this.state.user !== this.state.prevUser;

	// 	if (hasChanged) {
	// 		await this._saveEmployee();
	// 		await this.props.update();
	// 	}
	// };

	_saveEmployee = async () => {
		const { user } = this.state;
		const response = await employeesService.update(user);

		if (response.ok) {
			toastr.success("¡Bien!", "Cambios realizados correctamente");
		}

		this.setState({
			prevUser: user,
			errors: errorFormating(response),
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		await this._saveEmployee();
		await this.props.update();
	};

	getSupervisedGroups = () =>
		this.props.user.groups_supervised.map((group, i) => {
			return <li key={i}>{group.name}</li>;
		});

	render() {
		const { user } = this.state;

		return (
			<>
				<Layout className="page-profile">
					<div className="page-container container">
						<div className="heading">
							<h1 className="title">Mi perfil</h1>
						</div>

						<div className="page-content">
							<div className="content-heading">
								<Avatar
									onClick={this.openAvatarModal}
									userId={user.id}
									className="avatar-editable avatar-lg"
									alt={user.name}
								/>

								{isSupervisor() && (
									<div className="form-group no-form form-group-supervised">
										<label>Supervisa</label>
										<ul className="list-supervised">
											{this.getSupervisedGroups()}
										</ul>
									</div>
								)}
							</div>

							<form className="form-profile" onSubmit={this.handleSubmit}>
								<Row>
									<Col sm={6} md={4} lg={3}>
										<FormGroup controlId="name">
											<FormLabel>
												Nombre <span className="label-required">*</span>
											</FormLabel>
											<FormControl
												type="text"
												value={user.name}
												onChange={this.handleChange}
												// onBlur={this.handleBlur}
												placeholder="Nombre empleado"
												isInvalid={this.checkValidField("name")}
											/>
											<FormControl.Feedback type="invalid">
												{this.state.errors.name}
											</FormControl.Feedback>
										</FormGroup>
									</Col>
									<Col sm={6} md={4} lg={3}>
										<FormGroup controlId="surname">
											<FormLabel>
												Apellidos <span className="label-required">*</span>
											</FormLabel>
											<FormControl
												type="text"
												value={user.surname}
												onChange={this.handleChange}
												// onBlur={this.handleBlur}
												placeholder="Apellidos empleado"
												isInvalid={this.checkValidField("surname")}
											/>
											<FormControl.Feedback type="invalid">
												{this.state.errors.surname}
											</FormControl.Feedback>
										</FormGroup>
									</Col>
									<Col sm={6} md={4} lg={3}>
										<FormGroup controlId="email">
											<FormLabel>
												Email <span className="label-required">*</span>
											</FormLabel>
											<FormControl
												type="email"
												value={user.email}
												onChange={this.handleChange}
												// onBlur={this.handleBlur}
												placeholder="Email"
												isInvalid={this.checkValidField("email")}
											/>
											<FormControl.Feedback type="invalid">
												{this.state.errors.email}
											</FormControl.Feedback>
										</FormGroup>
									</Col>
									<Col sm={6} md={4} lg={3}>
										<FormGroup controlId="position">
											<FormLabel>Cargo</FormLabel>
											<FormControl
												type="text"
												value={user.position}
												onChange={this.handleChange}
												// onBlur={this.handleBlur}
												placeholder="Cargo empleado"

											/>
										</FormGroup>
									</Col>
									<Col sm={6} md={4} lg={3}>
										<FormGroup
											controlId="birthday"
											className={`form-date ${user.birthday === "" && "empty"}`}
										>
											<FormLabel>Fecha nacimiento</FormLabel>
											<FormControl
												type="date"
												value={user.birthday}
												onChange={this.handleChange}
												// onBlur={this.handleBlur}
												isInvalid={this.checkValidField("birthday")}
											/>
											<FiCalendar />
											<FormControl.Feedback type="invalid">
												{this.state.errors.birthday}
											</FormControl.Feedback>
										</FormGroup>
									</Col>
									<Col sm={6} md={4} lg={3}>
										<FormGroup controlId="phone">
											<FormLabel>Teléfono</FormLabel>
											<FormControl
												type="text"
												value={user.phone}
												onChange={this.handleChange}
												// onBlur={this.handleBlur}
												placeholder="99 123 123 123"
												isInvalid={this.checkValidField("phone")}
											/>
											<FormControl.Feedback type="invalid">
												{this.state.errors.phone}
											</FormControl.Feedback>
										</FormGroup>
									</Col>
									<Col sm={6} md={4} lg={3}>
										<FormGroup controlId="start_day" className="form-date">
											<FormLabel>Fecha contratación</FormLabel>
											<FormControl
												type="text"
												value={moment(user.start_day).format("DD/MM/YYYY")}
												disabled
											/>
											<FiCalendar />
										</FormGroup>
									</Col>
									<Col sm={6} md={4} lg={3}>
										<FormGroup controlId="role">
											<FormLabel>Rol</FormLabel>
											<FormControl value={user.role} disabled as="select">
												<option value="ADMIN">Administrador</option>
												<option value="EMPLOYEE">Empleado</option>
											</FormControl>
										</FormGroup>
									</Col>
									<Col sm={6} md={4} lg={3}>
										<FormGroup controlId="code">
											<FormLabel>Código</FormLabel>
											<FormControl
												type="text"
												value={user.code}
												disabled
												//onChange={this.handleChange}
												//onBlur={ this.handleBlur }
												placeholder="Código empleado"
											//isInvalid = {this.checkValidField('code')}
											/>
											<FormControl.Feedback type="invalid">
												{this.state.errors.code}
											</FormControl.Feedback>
										</FormGroup>
									</Col>
								</Row>

								<div className="row-buttons">
									<SubmitButton
										type="submit"
										isLoading={this.state.isLoading}
										text="Guardar datos"
										loadingText="Guardando..."
									/>

									<button
										type="button"
										className="btn btn-outline-primary button-password"
										onClick={this.openPasswordModal}
									>
										Cambiar contraseña
									</button>
								</div>
							</form>
						</div>
					</div>
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.authReducer.user,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	update: () => dispatch(userActions.update()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
