import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, FormControl, Dropdown } from "react-bootstrap";
import Moment from 'react-moment';
import moment from "moment";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { showModal, hideModal } from "actions/modalActions";
import * as plansActions from "actions/plansActions";
import * as employeesActions from "actions/employeesActions";
import * as shiftsActions from "actions/shiftsActions";
import { plansService } from "services";
import { FiMoreHorizontal, FiUpload, FiTool, FiRefreshCcw } from "react-icons/fi";
import Layout from 'components/Layout';
import CalendarHeader from "components/Calendars/CalendarHeader";
import CalendarViewEdit from "components/Calendars/CalendarViewEditModal";
import ShiftRequestEdit from "components/ShiftRequests/ShiftRequestEditModal";
import Modal from "components/Modal";
import { chunkArray } from "utils/utils";
import Loading from "components/Loading";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "components/Calendars/Calendars.scss";
import { isAdmin, isSupervisor } from "utils/utils";

class Calendar extends Component {
  constructor(props) {
    super(props);

    const today = new Date();
    const year = today.getFullYear();
    this.closeModal = this.closeModal.bind(this);
    this.openEditCalendarViewModal = this.openEditCalendarViewModal.bind(this);
    this.openShiftRequestEditModal = this.openShiftRequestEditModal.bind(this);
    this.updateCalendarHeader = this.updateCalendarHeader.bind(this);


    this.state = {
      loading: false,
      year,
      search_employee: this.props.user.id,
      search_employee_name: this.props.user.name,
      plan_assignments: [],
      updateIndicator: 0,
      worked_holidays: [],
      show_worked_holidays: false,
    };
  }

  async componentDidMount() {
    const { user } = this.props;

    await Promise.all([
      this.getData(),
    ]);

    if (user.id === 6 || user.id === 3 || user.id === 1489) {
      this.props.getAttendanceShifts();
      this.props.getSelectableEmployees();
    }

    if (isAdmin() || isSupervisor()) {
      await Promise.all([
        this.props.getSelectableEmployees(),
        this.props.getPlanViews(),
        this.props.getDefaultShift()
      ]);
    }


  }

  closeModal() {
    this.props.hideModal()
  }

  toggleWorkedHolidays() {
    this.setState(prevState => ({
      show_worked_holidays: !prevState.show_worked_holidays
    }));
  }

  openEditCalendarViewModal() {
    const { plan_views } = this.props;
    // console.log(plan_views);
    this.props.showModal({
      open: true,
      title: 'Configurar vista',
      style: { width: '400px' },
      content: <CalendarViewEdit handleClose={this.closeModal} updateCalendarHeader={this.updateCalendarHeader} plan_views={plan_views} />,
      closeModal: this.closeModal
    }, 'edit')
  }

  openShiftRequestEditModal() {
    this.props.showModal({
      open: true,
      title: 'Solicitar preferencia de turno',
      style: { width: '500px' },
      content: <ShiftRequestEdit handleClose={this.closeModal} />,
      closeModal: this.closeModal
    }, 'edit')
  }

  async getData() {
    this.setState({ loading: true })  // loading

    await Promise.all([
      this.props.getEmployeeAbsenceCalendar(this.state.search_employee, this.state.year), // carga ausencias del empleado
      this.getAssignmentsList(), // carga turnos del empleado
    ]);

    const worked_holidays = this.props.employee_absence_calendar.filter((o) => {
      return o.worked_holiday === true;
    });


    this.setState({
      loading: false,
      worked_holidays
    })
  }

  // retrieve assignments list by employee and month
  async getAssignmentsList() {
    const employee_id = this.state.search_employee;
    // console.log(this.state.year);
    const start_from = moment(this.state.year + "-01-01")
      .startOf("year")
      .format("YYYY-MM-DD");
    const start_to = moment(this.state.year + "-01-01")
      .endOf("year")
      .format("YYYY-MM-DD");

    const response = await plansService.getPlanAssignments({
      employee_id,
      start: start_from,
      end: start_to,
    });

    // console.log(response);
    const plan_assignments = response.data;

    this.setState(
      {
        plan_assignments: !plan_assignments || plan_assignments.length < 1 || plan_assignments[0].plans.length < 1 ? [] : plan_assignments[0].plans,
      }
    );
  }

  handleChangeSearch = event => {
    const target_id = event.target.id;
    var search_employee_name = this.state.search_employee_name;

    if (target_id === 'search_employee') {
      search_employee_name = event.target.options[event.target.selectedIndex].text;
    }
    this.setState({
      [target_id]: event.target.value,
      search_employee_name,
    }, async () => {
      this.getData();
    });
  }

  async handleExportCalendarPDF() {
    const calendario = document.getElementById('miCalendario');

    html2canvas(calendario).then(function (canvas) {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'p', // landscape
        //unit: 'pt', // points, pixels won't work properly
        format: [canvas.width, canvas.height] // set needed dimensions for any element
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('calendario.pdf');
    });
  }

  getMonths = () =>
    Array.from({ length: 12 }, (item, i) => {
      const fullCalendar = this.props.employee_absence_calendar;

      const monthCalendar = fullCalendar.filter((o) => {
        let [year, month, date] = o.day.split("-")

        return month * 1 === i + 1 ? true : false
      });

      // console.log(monthCalendar);

      // si el primer dia del mes no es lunes, se añaden días vacíos de relleno
      const weekDay = monthCalendar[0].weekday;
      if (weekDay !== 1) {
        for (let i = weekDay; i > 1; i--) {
          monthCalendar.unshift({ day: null });
        }
      }

      // separa mes en semanas
      const monthCalendarWithWeeks = chunkArray(monthCalendar, 7)

      return (
        <div className="column col-sm-6 col-lg-4 col-xl-4 col-xxl-3" key={i}>
          <article className="card month">
            <h3>{new Date(0, i).toLocaleString('es-ES', { month: 'long' })}</h3>

            <table className="table-calendar">
              <thead>
                <tr>
                  {this.getWeekDays()}
                </tr>
              </thead>
              <tbody>
                {this.getWeeks(monthCalendarWithWeeks)}
              </tbody>
            </table>
          </article>
        </div>
      )
    });

  // starts with a monday
  getWeekDays = () =>
    Array.from({ length: 7 }, (item, i) => (
      <th key={i}>
        {new Date(2021, 10, i + 1).toLocaleString('es-ES', { weekday: 'narrow' })}
      </th>
    ));

  getWeeks = (monthCalendarWithWeeks) => monthCalendarWithWeeks.map((week, i) => {
    //console.log(week);
    return (
      <tr key={i}>
        {this.getCalendarDays(week)}
      </tr>
    );
  });

  getPlanForDay = (day) => this.state.plan_assignments.find((o) => {
    return o.day === day;
  });

  getShiftListTooltipContent = (day_assignments) => day_assignments.map((item, i) => {
    const shift = item.shift;
    const shiftname = shift.description ? "(" + shift.name + ") " + shift.description : shift.name;

    return (
      <li className="shift-item" key={i}>
        {shiftname}
      </li>
    );
  });

  getShiftListTdContent = (day_assignments) => day_assignments.map((item, i) => {
    const shift = item.shift;
    const color = shift.background_color;
    const className = "shift-item" + " " + "items_" + day_assignments.length;
    return (
      <li className={className} key={i} style={{ backgroundColor: color && color }}>
        {shift.id !== this.props.default_shift.id
          ? shift.name.substring(0, 3)
          : ""}
      </li>
    );
  });

  getCalendarDays = (week) => week.map((o, i) => {

    if (o.day === null) return (<td key={i}></td>)

    let [year, month, date] = o.day.split("-")
    const plan = this.getPlanForDay(o.day);
    // console.log(week);
    // console.log(plan);

    if (o.absence || o.day_type.holiday || (plan && plan.assignments.length > 0)) {

      var color, tooltipContent, shiftList;
      var className = "td-container";



      if (plan && plan.assignments.length > 0) {
        const shift = plan.assignments[0].shift;
        tooltipContent = (<ul className="tooltip-shift-list-items">
          {this.getShiftListTooltipContent(plan.assignments)}
        </ul>)


        if (plan.assignments.length === 1 && plan.assignments[0].shift.id === this.props.default_shift.id) {
          className += " default-shift"
        } else {
          className += " has-shifts"
          shiftList = (<ul className="shift-list-items">
            {this.getShiftListTdContent(plan.assignments)}
          </ul>)
        }
        // color = shift.background_color;
      }
      else if (o.absence) {
        const shift = o.shift;
        tooltipContent = o.comments ? o.comments : shift.name + " " + shift.description;
        // color = shift.background_color;
      }
      else if (o.day_type.holiday) {
        className += " holiday"
        const day_type = o.day_type;
        tooltipContent = o.comments ? o.comments : day_type.name + " " + day_type.description;
        // color = day_type.color;
      }

      color = o.day_type && o.day_type.color;

      if (o.worked_holiday) {
        className += " worked-holiday";
        tooltipContent = (<><strong>[Festivo trabajado]</strong><br /> {tooltipContent}</>);
      }

      const tooltip = <Tooltip className="tooltip-calendar">{tooltipContent}</Tooltip>;

      if (color && color === "#ffffff") color = "#000000";

      return (
        <OverlayTrigger key={i} placement="top" overlay={tooltip}>
          <td key={i}>
            <div className={className}>
              <span className='calendar-date' style={{ color: color && color }}>{date}</span>
              {shiftList}
            </div>
          </td>
        </OverlayTrigger>
      )
    }

    return (
      <td key={i}>{date}</td>
    )
  });

  getContent = () => {
    if (this.state.loading) {
      return <Loading />;
    }
    else if (this.props.employee_absence_calendar.length === 0) {
      return <p className="no-results">Error cargando el calendario.</p>;
    }

    return (<div className="row list-calendar">{this.getMonths()}</div>);
  }


  updateCalendarHeader = () => {
    this.setState(prevState => ({
      updateIndicator: prevState.updateIndicator + 1, // Incrementa el indicador para forzar la actualización
    }));
  };

  render() {
    const { select_employees, user } = this.props;
    const { worked_holidays, show_worked_holidays } = this.state;

    console.log(worked_holidays);
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    const optionYearList = range(currentYear + 1, 2020, -1).map((item, i) => {
      return (
        <option key={i} value={item}>{item}</option>
      )
    }, this);

    var title_mi_calendario = "Mi Calendario";

    if (this.props.user.id !== this.state.search_employee * 1)
      title_mi_calendario = "Calendario de " + this.state.search_employee_name;

    const employee_in_list = select_employees.some((e) => e.id === this.props.user.id);
    if (!employee_in_list)
      select_employees.push(this.props.user)

    let employeeList = select_employees.length > 0 &&
      select_employees.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.name} {item.surname}
          </option>
        );
      }, this);

    return (
      <>
        <Layout className="page-calendar" context="calendar">
          <div id="miCalendario">

            <div className="heading" >
              <h1 className="title">{title_mi_calendario}</h1>

              <div className="heading-filters">

              </div>
              <div className="heading-actions">
                {(user.id === 6 || user.id === 3 || user.id === 1489) &&
                  (<button
                    type="button"
                    className="btn btn-primary mr10"
                    onClick={() => this.openShiftRequestEditModal()}
                  >
                    <FiRefreshCcw className="mr5" />
                    Solicitar preferencia de turno
                  </button>)}
                <FormGroup
                  className="form-select-user mr10"
                  controlId="search_employee"
                >
                  <FormControl
                    type="text"
                    placeholder="Buscar..."
                    value={this.state.search_employee}
                    className={!isAdmin() && !isSupervisor() || select_employees.length < 1 ? 'hidden' : ''}
                    onChange={this.handleChangeSearch}
                    as="select"
                  >
                    {employeeList}
                  </FormControl>
                </FormGroup>

                <FormGroup controlId="year">
                  <FormControl
                    type="text"
                    value={this.state.year}
                    onChange={this.handleChangeSearch}
                    as="select"
                  >
                    {optionYearList}
                  </FormControl>
                </FormGroup>

                <Dropdown>
                  <Dropdown.Toggle variant="action">
                    <FiMoreHorizontal />
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight>
                    <Dropdown.Item
                      as="button"
                      onClick={() => this.handleExportCalendarPDF()}
                    >
                      <FiUpload className="dropdown-icon" /> Exportar
                      calendario a PDF
                    </Dropdown.Item>

                    {(isAdmin() || isSupervisor()) && this.props.plan_views.length > 1 && (
                      <Dropdown.Item
                        as="button"
                        onClick={() => this.openEditCalendarViewModal()}
                      >
                        <FiTool className="dropdown-icon" /> Configurar vista
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <CalendarHeader employee_id={this.state.search_employee} year={this.state.year} plan_views={this.props.plan_views} updateIndicator={this.state.updateIndicator} />

            {this.getContent()}

            <div className="box box-total">
              <div className="box-total-header">
                <h4>Total días festivos trabajados: <strong>{worked_holidays.length}</strong></h4>
                <button type="button" className='button-link' onClick={() => this.toggleWorkedHolidays()}>{show_worked_holidays ? "Ocultar detalle" : "Mostrar detalle"}</button>
              </div>

              {show_worked_holidays && (
                <ul className="list-worked-holidays">
                  {worked_holidays.map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="tag-day-type" style={{ backgroundColor: item.day_type.color && item.day_type.color }}>{item.day_type.name}</span>
                        <Moment format="DD/MM/YY">{item.day}</Moment>
                        <span>{item.comments}</span>
                      </li>
                    )
                  })}
                </ul>
              )}

            </div>
          </div>
        </Layout>

        <Modal hideModal={this.props.hideModal} />
      </>
    )
  }
}



const mapStateToProps = (reducers) => {
  return {
    ...reducers.shiftsReducer,
    ...reducers.absencesReducer,
    ...reducers.employeesReducer,
    ...reducers.shiftsReducer,
    ...reducers.plansReducer,
    user: reducers.authReducer.user,
    loading: reducers.absencesReducer.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
  getEmployeeAbsenceCalendar: (employee_id, year) => dispatch(employeesActions.getEmployeeAbsenceCalendar(employee_id, year)),
  getSelectableDayTypes: () => dispatch(calendarsActions.getSelectableDayTypes()),
  getWeekdaysDefault: () => dispatch(calendarsActions.getWeekdaysDefault()),
  getDefaultShift: () => dispatch(shiftsActions.getDefaultShift()),
  getSelectableEmployees: (options) => dispatch(employeesActions.getSelectableEmployees(options)),
  getPlanViews: () => dispatch(plansActions.getPlanViews()),
  getAttendanceShifts: () => dispatch(shiftsActions.getAttendanceShifts()),
})


export default connect(mapStateToProps, mapDispatchToProps)(Calendar)